import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { getApi, postApi } from "../../helpers/api";
import { MultiSelect } from "primereact/multiselect";
import { useDispatch, useSelector } from "react-redux";
import "./app.css";

function Breadcrumb({ data }) {
  return (
    <>
      <div className="content-header">
        <p className="breadcrumb">
          <Link to={"/dashboard"} className="back-link">
            Dashboard
          </Link>
          /
          <Link to={"/apps"} className="back-link">
            All App
          </Link>
          /<span className="active text-capitalize">{data.apptype}</span>
        </p>
        <div className="mt-3 text-end">
          <button
            className="theme-btn-primary"
            type="submit"
            disabled={data.pending ? true : false}
          >
            {data.pending ? "Loding..." : "Submit"}
          </button>
        </div>
      </div>
    </>
  );
}

function AddApp() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const userPermission = JSON.parse(user.permission);
  let navigate = useNavigate();
  let params = useParams();
  const [PreImage, setPreImage] = useState("");
  const [appType, setAppType] = useState("");
  const [IsPending, setPending] = useState(false);
  const [isLoding, setIsLoding] = useState(false);
  const [maxvidcount, setMaxvidcount] = useState(360);

  const adsPlt = [
    { name: "Google", code: "google" },
    { name: "Facebook", code: "facebook" },
    { name: "Applovin", code: "applovin" },
    { name: "Unity", code: "unity" },
  ];

  useEffect(() => {
    if (
      user.userRole.role_name === "admin" ||
      (userPermission.app_e && params.id) ||
      userPermission.app_c
    ) {
      if (params.id) {
        getAppForm();
      }
    } else {
      dispatch({ type: "LOGOUT", payload: null });
      localStorage.removeItem(process.env.REACT_APP_SECRET);
      navigate("/");
    }
  }, []);

  const getAppForm = async () => {
    setIsLoding(true);
    await getApi(`apps?app=${params.id}`, true)
      .then(({ data }) => {
        let newData = { ...data.apps[0] };
        setPreImage(
          `${process.env.REACT_APP_BASE_URL_UPLOAD}appimages/` +
            newData.app_image
        );
        if (
          newData.app_AdsPriority !== "" &&
          newData.app_AdsPriority !== null
        ) {
          let emptyArray = [];
          let newAppAdsPriority = newData.app_AdsPriority.split(",");
          Array.apply(null, Array(newAppAdsPriority.length)).map((val, key) => {
            adsPlt.map((adsPltval, adsPltkey) => {
              if (newAppAdsPriority[key] === adsPltval.code) {
                emptyArray.push(adsPltval);
              }
            });
          });
          newAppAdsPriority.length = 0;
          newData.app_AdsPriority = emptyArray;
        }
        setAppType(newData.app_type);
        setInitialValues(newData);
        setIsLoding(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [initialValues, setInitialValues] = useState({
    success: true,
    app_type: "",
    user: user.id,

    // Static Data
    country: "",
    countryCode: "",
    region: "",
    regionName: "",
    city: "",
    zip: "",
    lat: "",
    lon: "",
    timezone: "",
    isp: "",
    org: "",
    as: "",

    // Optionl Key
    master_key: "",
    referral_link: "",

    // Optionl JSON Data
    app_extra: {},
    third_party: {},
    gc_glide: {},
    fb_glide: {},

    // App Details
    app_name: "",
    packagename: "",
    accountname: "",
    app_apikey: "",
    app_note: "",
    app_versionCode: "",
    app_updateAppDialogStatus: false,
    app_UpdatePackageName: "",
    app_redirectOtherAppStatus: false,
    app_newPackageName: "",
    Privacy_Policy: "",
    app_OneSingleID: "",
    app_image: "",
    app_DeveloperOption_Check_Mode: false,
    maxvidcount: maxvidcount,
    True_Video_Show: false,
    False_Video_Show: false,
    Both_video_show: false,
    app_failData: "",
    app_DataIOSocketFail: "",

    // Monetize Settings
    Dialog_Show: false,
    app_TestMode: false,
    app_AllAdShowStatus: false, //Ads Status (Monetize Settings)
    NativeBanner_Ads_On: false, // Show Native Banner Ads
    Banner_Ads_On: false, // Show Banner Ads
    Native_Ads_On: false, // Show Native Ads
    app_MainClickCntSwAd: "", //Ad Click Count
    app_InnerClickCntSwAd: "",
    monetize_app_extra: {},
    app_AdsPriority: [],
    app_Banner_AdsPriority: "",
    app_Native_AdsPriority: "",
    app_NativeAdCodeType: "",
    app_BannerPeriority: "",
    Native_Btn_Color: "",
    Native_Btn_Ads_Text: "",
    Native_Btn_Text_Size: "",
    Native_Btn_Text_Color: "",
    Native_Ad_Shape_Square_Corner: false,
    Native_Ad_Shape_Round_Corner: false,
    Native_Ad_Small_Size: false,
    Native_Ad_Shape_Normal_Round_Btn: false,

    AD_MOB_STATUS: false, // AdMod status
    AD_MOB_APP_ID_Banner1: "", // AdMod Banner
    AD_MOB_APP_ID_Banner2: "",
    AD_MOB_APP_ID_Banner3: "",
    AD_MOB_APP_ID_Native1: "", // AdMod Native
    AD_MOB_APP_ID_Native2: "",
    AD_MOB_APP_ID_Native3: "",
    AD_MOB_APP_ID_Native4: "",
    AD_MOB_APP_ID_Inter1: "", // AdMod Interstitial
    AD_MOB_APP_ID_Inter2: "",
    AD_MOB_APP_ID_Inter3: "",
    AD_MOB_APP_ID_OpenAd1: "", // AdMod OpenAd
    AD_MOB_APP_ID_OpenAd2: "",
    AD_MOB_APP_ID_OpenAd3: "",
    AD_MOB_APP_ID_OpenAd4: "",
    AD_MOB_APP_ID_RewardedInterstitial: "",
    AD_MOB_APP_ID_RewardedVideo: "",

    FACEBOOK_AD_STATUS: false, //Ad facebook status
    FB_Banner_ID1: "", // Ad facebook Banner
    FB_Banner_ID2: "",
    FB_Banner_ID3: "",
    FB_Native_ID1: "", // Ad facebook Native
    FB_Native_ID2: "",
    FB_Native_ID3: "",
    FB_Native_ID4: "",
    FB_Inter_ID1: "", // Ad facebook Interstitial
    FB_Inter_ID2: "",
    FB_Inter_ID3: "",
    FB_BannerNative_ID1: "", //Ad facebook Banner Native
    FB_BannerNative_ID2: "",
    FB_BannerNative_ID3: "",
    APPLOVIN_ADS_STATUS: false, // Ad Applovin status
    app_ApplovinInterstitialId1: "", // Ad applovin Interstitial
    app_ApplovinInterstitialId2: "",
    app_ApplovinInterstitialId3: "",
    app_ApplovinNative1: "", // Ad native
    app_ApplovinBanner1: "", // Ad banner
    app_ApplovinBannerSize: "", // Ad banner height

    UNITY_ADS_STATUS: false, // Ad Unity status
    app_UnityBannerId: "", // Ad unity banner
    app_UnityBannerWidth: "", // Ad unity banner width
    app_UnityBannerHeight: "", // Ad unity banner height
    app_UnityInterstitialId1: "", // Ad unity inaterstitial
    app_UnityInterstitialId2: "",
    app_UnityInterstitialId3: "",
    app_UnityAppId: "", // Ad unity app id
    app_UnityTestMode: false, // Ad unity test mode

    // Custome Setting
    app_CustClickCntSAd: "", // (Custome AD Settings)
    app_CustomeAdInterstitialLink: "",
    app_CustomeAdInterFullscreenImg: "",
    app_OnlyInterstitalADShow: false, // Show Interstital Ads
    app_OnlyNativeADShow: false, // Show Native Ads
    app_OnlyBannerADShow: false, // Show Banner Ads
    app_CustomeADText: "",
    app_CustomeAdQurekaLink: "",
    app_CustomeAdNativeLargeImage: "", // Custome Native
    app_CustomeAdNativeIconImage: "",
    app_CustomeAdNativeTitle: "",
    app_CustomeAdNativesubHeader: "",
    app_CustomeAdNativesubHeader2: "",
    app_CustomeAdNativeInstallText: "",
    app_CustomeADNativeLink: "",
    app_CustomeAdBannerIcon: "", // Custome Banner
    app_CustomeAdBannerInstallText: "",
    app_CustomeAdBannerHeaderText: "",
    app_CustomeAdBannerSubHeaderText: "",
    app_CustomeADBannerLink: "",
    app_OnlyQurekaStatusShow: false,
    app_OnlyQurekaLink: "",

    // Payment App
    plan_coin_99_key: "",
    plan_coin_179_key: "",
    plan_coin_349_key: "",
    plan_Amt_A: "",
    plan_Amt_B: "",
    plan_Amt_C: "",
    plan_Coin_A: "",
    plan_Coin_B: "",
    plan_Coin_C: "",
    per_call_coin: "",
    av_0: "",
    av_1: "",
    av_2: "",
    login_num: "",
    payment_option: "",
    payeeVpa: "",
    payeeName: "",
    description: "",
    payment_app_extra: "",
  });

  const validationSchema = yup.object({
    app_name: yup.string().required("App name is required."),
    packagename: yup.string().required("Package name is required."),
    accountname: yup.string().required("Account name is required."),
    maxvidcount: yup
      .string()
      .nullable(true)
      .matches(/^[0-9]*$/, "Allow Only Numbers"),
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      setPending(true);
      let newValue = { ...values };

      if (newValue.app_image === "") {
        delete newValue.app_image;
      }

      let newArray = [];
      if (
        newValue.app_AdsPriority !== null &&
        newValue.app_AdsPriority !== ""
      ) {
        newValue.app_AdsPriority.map((val, key) => {
          newArray.push(val.code);
        });
      }

      if (newArray.includes("google")) {
        newValue.AD_MOB_STATUS = true;
      } else {
        newValue.AD_MOB_STATUS = false;
      }

      if (newArray.includes("facebook")) {
        newValue.FACEBOOK_AD_STATUS = true;
      } else {
        newValue.FACEBOOK_AD_STATUS = false;
      }

      if (newArray.includes("applovin")) {
        newValue.APPLOVIN_ADS_STATUS = true;
      } else {
        newValue.APPLOVIN_ADS_STATUS = false;
      }

      if (newArray.includes("unity")) {
        newValue.UNITY_ADS_STATUS = true;
      } else {
        newValue.UNITY_ADS_STATUS = false;
      }

      newValue.app_type = appType;

      newValue.app_AdsPriority = newArray.toString();
      let appURL = params.id ? `add-app?app=${params.id}` : `add-app`;

      await axios({
        method: "post",
        url: params.id
          ? `${process.env.REACT_APP_BASE_URL}add-app?app=${params.id}`
          : `${process.env.REACT_APP_BASE_URL}add-app`,
        data: newValue,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          setPending(false);
          navigate("/apps");
        })
        .catch((error) => {
          setPending(false);
        });
    },
  });

  const testMode = async (event) => {
    let testStatus = event.target.checked;
    let replesVal = { ...values };
    if (event.target.checked) {
      await getApi(`test-ads`)
        .then((response) => {
          let testData = response.data;
          replesVal.app_TestMode = testStatus;
          Object.keys(replesVal).map((val) => {
            Object.keys(testData).filter((ele) => {
              if (val.startsWith(ele)) {
                replesVal[val] = testData[ele];
              }
            });
          });
          setInitialValues(replesVal);
        })
        .catch((error) => {
          console.log(error.message);
        });
    } else {
      replesVal.app_TestMode = testStatus;
      setInitialValues(replesVal);
    }
  };

  const fileChangeHandler = (event) => {
    setFieldValue("app_image", event.target.files[0]);
    if (event.target.files && event.target.files.length) {
      setPreImage(URL.createObjectURL(event.target.files[0]));
    }
  };
  let breadcrumbProps = { apptype: appType, pending: IsPending };
  return (
    <>
      {!isLoding && (params.id || appType) ? (
        <>
          <form
            onSubmit={handleSubmit}
            className=""
            autoComplete="off"
            encType="multipart/form-data"
          >
            <Breadcrumb data={breadcrumbProps} />
            <Tabs defaultActiveKey="app_application_details" fill>
              <Tab
                eventKey="app_application_details"
                title="Application Details"
              >
                <div className="mt-24">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="image-uploader">
                        <div className="row">
                          <div className="col-md-3">
                            <div
                              className="preview-image"
                              style={
                                PreImage
                                  ? { padding: "0px", height: "168px" }
                                  : { padding: "34px" }
                              }
                            >
                              {values.app_image ? (
                                <img src={PreImage} alt="Default Images" />
                              ) : params.id ? (
                                <div className="app-text-logo">
                                  {values.app_name?.charAt(0)}
                                </div>
                              ) : (
                                <svg
                                  width="100"
                                  height="100"
                                  viewBox="0 0 100 100"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g clipPath="url(#clip0_81_7311)">
                                    <path
                                      d="M62.5 33.3335H62.5417"
                                      stroke="#111111"
                                      strokeOpacity="0.1"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M12.5 25C12.5 21.6848 13.817 18.5054 16.1612 16.1612C18.5054 13.817 21.6848 12.5 25 12.5H75C78.3152 12.5 81.4946 13.817 83.8388 16.1612C86.183 18.5054 87.5 21.6848 87.5 25V75C87.5 78.3152 86.183 81.4946 83.8388 83.8388C81.4946 86.183 78.3152 87.5 75 87.5H25C21.6848 87.5 18.5054 86.183 16.1612 83.8388C13.817 81.4946 12.5 78.3152 12.5 75V25Z"
                                      stroke="#111111"
                                      strokeOpacity="0.1"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M12.5 66.6664L33.3333 45.8331C37.2 42.1123 41.9667 42.1123 45.8333 45.8331L66.6667 66.6664"
                                      stroke="#111111"
                                      strokeOpacity="0.1"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M58.3334 58.3333L62.5 54.1666C66.3667 50.4458 71.1334 50.4458 75 54.1666L87.5 66.6666"
                                      stroke="#111111"
                                      strokeOpacity="0.1"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_81_7311">
                                      <rect
                                        width="100"
                                        height="100"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              )}
                            </div>
                          </div>
                          <div className="col-md-9 position-relative">
                            <label
                              htmlFor="app_default_image"
                              className="upload_app_text"
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clipPath="url(#clip0_80_7131)">
                                  <path
                                    d="M4 19.9998H8L18.5 9.49981C18.7626 9.23717 18.971 8.92537 19.1131 8.58221C19.2553 8.23905 19.3284 7.87125 19.3284 7.49981C19.3284 7.12838 19.2553 6.76058 19.1131 6.41742C18.971 6.07426 18.7626 5.76246 18.5 5.49981C18.2374 5.23717 17.9256 5.02883 17.5824 4.88669C17.2392 4.74455 16.8714 4.67139 16.5 4.67139C16.1286 4.67139 15.7608 4.74455 15.4176 4.88669C15.0744 5.02883 14.7626 5.23717 14.5 5.49981L4 15.9998V19.9998Z"
                                    stroke="#010226"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M13.5 6.5L17.5 10.5"
                                    stroke="#010226"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M16 19H22"
                                    stroke="#010226"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_80_7131">
                                    <rect width="24" height="24" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                              &nbsp;&nbsp; Upload App Icon
                              <input
                                type="file"
                                id="app_default_image"
                                className="d-none"
                                accept="image/png, image/jpeg"
                                name="app_image"
                                onChange={fileChangeHandler}
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group mt-0">
                            <label>Version Update Dialog?</label>
                            <div className="button-app">
                              <div className="button r" id="button-1">
                                <input
                                  type="checkbox"
                                  className="checkbox"
                                  checked={values.app_updateAppDialogStatus}
                                  onChange={(status) =>
                                    setFieldValue(
                                      "app_updateAppDialogStatus",
                                      status.target.checked
                                    )
                                  }
                                />
                                <div className="knobs"></div>
                                <div className="layer"></div>
                              </div>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Version Code"
                              name="app_versionCode"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.app_versionCode}
                            />
                            {errors.app_versionCode &&
                            touched.app_versionCode ? (
                              <span className="required-lable">
                                {errors.app_versionCode}{" "}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        {/* <div className="col-md-6">
                                                        <div className="form-group mt-0">
                                                            <input
                                                                type="text"
                                                                className="form-control mt-2"
                                                                placeholder="Enter Package Name"
                                                                name="app_UpdatePackageName"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.app_UpdatePackageName}
                                                            />
                                                            {errors.app_UpdatePackageName && touched.app_UpdatePackageName ? (
                                                                <span className="required-lable">{errors.app_UpdatePackageName} </span>
                                                            ) : null}
                                                        </div>
                                                    </div> */}
                      </div>
                      <div className="form-group mt-0">
                        <label>Update Package Name</label>
                        <input
                          type="text"
                          placeholder="Enter Package Name"
                          name="app_UpdatePackageName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.app_UpdatePackageName}
                        />
                        {errors.app_UpdatePackageName &&
                        touched.app_UpdatePackageName ? (
                          <span className="required-lable">
                            {errors.app_UpdatePackageName}{" "}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mt-0">
                        <label>
                          App Name<span className="required-lable">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="App Name"
                          name="app_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.app_name}
                        />
                        {errors.app_name && touched.app_name ? (
                          <span className="required-lable">
                            {errors.app_name}{" "}
                          </span>
                        ) : null}
                      </div>
                      <div className="form-group mt-0">
                        <label>
                          Package Name<span className="required-lable">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Package Name"
                          name="packagename"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.packagename}
                        />
                        {errors.packagename && touched.packagename ? (
                          <span className="required-lable">
                            {errors.packagename}{" "}
                          </span>
                        ) : null}
                      </div>
                      <div className="form-group m-0">
                        <label>
                          Account Name<span className="required-lable">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Account Name"
                          name="accountname"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.accountname}
                        />
                        {errors.accountname && touched.accountname ? (
                          <span className="required-lable">
                            {errors.accountname}{" "}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mt-0">
                        <label>Redirect Other App?</label>
                        <div className="button-app">
                          <div className="button r" id="button-1">
                            <input
                              type="checkbox"
                              className="checkbox"
                              checked={values.app_redirectOtherAppStatus}
                              onChange={(status) =>
                                setFieldValue(
                                  "app_redirectOtherAppStatus",
                                  status.target.checked
                                )
                              }
                            />
                            <div className="knobs"></div>
                            <div className="layer"></div>
                          </div>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter New Package If You Want to Redirect Other App"
                          name="app_newPackageName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.app_newPackageName}
                        />
                        {errors.app_newPackageName &&
                        touched.app_newPackageName ? (
                          <span className="required-lable">
                            {errors.app_newPackageName}{" "}
                          </span>
                        ) : null}
                      </div>
                      <div className="form-group mt-0">
                        <label>Privacy Policy Link</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Policy Link"
                          name="Privacy_Policy"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.Privacy_Policy}
                        />
                        {errors.Privacy_Policy && touched.Privacy_Policy ? (
                          <span className="required-lable">
                            {errors.Privacy_Policy}{" "}
                          </span>
                        ) : null}
                      </div>
                      <div className="form-group mb-0">
                        <label>App One Single ID</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="One Single ID"
                          name="app_OneSingleID"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.app_OneSingleID}
                        />
                        {errors.app_OneSingleID && touched.app_OneSingleID ? (
                          <span className="required-lable">
                            {errors.app_OneSingleID}{" "}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-32">
                    <div className="col-md-3 col-12">
                      <div className="appdetails-switch form-group">
                        <p className="text-capitalize">True Video Show?</p>
                        <div className="button r" id="button-1">
                          <input
                            type="checkbox"
                            className="checkbox"
                            checked={values.True_Video_Show}
                            onChange={(status) =>
                              setFieldValue(
                                "True_Video_Show",
                                status.target.checked
                              )
                            }
                          />
                          <div className="knobs"></div>
                          <div className="layer"></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-12">
                      <div className="appdetails-switch form-group">
                        <p className="text-capitalize">False Video Show?</p>
                        <div className="button r" id="button-1">
                          <input
                            type="checkbox"
                            className="checkbox"
                            checked={values.False_Video_Show}
                            onChange={(status) =>
                              setFieldValue(
                                "False_Video_Show",
                                status.target.checked
                              )
                            }
                          />
                          <div className="knobs"></div>
                          <div className="layer"></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-12">
                      <div className="appdetails-switch form-group">
                        <p className="text-capitalize">Both Video Show?</p>
                        <div className="button r" id="button-1">
                          <input
                            type="checkbox"
                            className="checkbox"
                            checked={values.Both_video_show}
                            onChange={(status) =>
                              setFieldValue(
                                "Both_video_show",
                                status.target.checked
                              )
                            }
                          />
                          <div className="knobs"></div>
                          <div className="layer"></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-12">
                      <div className="appdetails-switch form-group">
                        <p className="text-capitalize">
                          Developer Option Mode?
                        </p>
                        <div className="button r" id="button-1">
                          <input
                            type="checkbox"
                            className="checkbox"
                            checked={values.app_DeveloperOption_Check_Mode}
                            onChange={(status) =>
                              setFieldValue(
                                "app_DeveloperOption_Check_Mode",
                                status.target.checked
                              )
                            }
                          />
                          <div className="knobs"></div>
                          <div className="layer"></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 mt-32">
                      <div className="form-group mt-0">
                        <label> Maximum Video Count</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Count"
                          name="maxvidcount"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.maxvidcount}
                        />
                        {errors.maxvidcount && touched.maxvidcount ? (
                          <span className="required-lable">
                            {errors.maxvidcount}{" "}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-4 mt-32">
                      <div className="form-group mt-0">
                        <label>Fail Data</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Data"
                          name="app_failData"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.app_failData}
                        />
                        {errors.app_failData && touched.app_failData ? (
                          <span className="required-lable">
                            {errors.app_failData}{" "}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-4 mt-32">
                      <div className="form-group mt-0">
                        <label>Data IO Socket Fail</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Data"
                          name="app_DataIOSocketFail"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.app_DataIOSocketFail}
                        />
                        {errors.app_DataIOSocketFail &&
                        touched.app_DataIOSocketFail ? (
                          <span className="required-lable">
                            {errors.app_DataIOSocketFail}{" "}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              {(appType === "ads app" || appType === "ads-pay-app") && (
                <Tab eventKey="app_monetize_settings" title="Monetize Settings">
                  <div className="mt-24 mb-24">
                    <div className="row">
                      <div className="col-md-4 col-12">
                        <div className="monetize-switch bg-primary-subtle border-0">
                          <p className="text-theme">
                            How Test Ad in Debug App?
                          </p>
                          <div className="button-app">
                            <div className="button r" id="button-1">
                              <input
                                type="checkbox"
                                className="checkbox"
                                checked={values.app_TestMode}
                                onChange={(status) => testMode(status)}
                              />
                              <div className="knobs"></div>
                              <div className="layer"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="monetize-switch bg-success-subtle border-0">
                          <p className="text-theme">Show Ad in App?</p>
                          <div className="button-app">
                            <div className="button r" id="button-1">
                              <input
                                type="checkbox"
                                className="checkbox"
                                onChange={(status) =>
                                  setFieldValue(
                                    "app_AllAdShowStatus",
                                    status.target.checked
                                  )
                                }
                                checked={values.app_AllAdShowStatus}
                              />
                              <div className="knobs"></div>
                              <div className="layer"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="monetize-switch bg-danger-subtle border-0">
                          <p className="text-theme">Show Dialog?</p>
                          <div className="button-app">
                            <div className="button r" id="button-1">
                              <input
                                type="checkbox"
                                className="checkbox"
                                checked={values.Dialog_Show}
                                onChange={(status) =>
                                  setFieldValue(
                                    "Dialog_Show",
                                    status.target.checked
                                  )
                                }
                              />
                              <div className="knobs"></div>
                              <div className="layer"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="monetize-switch theme-bg">
                          <p className="text-white">Show NativeBanner Ad?</p>
                          <div className="button-app">
                            <div className="button r" id="button-1">
                              <input
                                type="checkbox"
                                className="checkbox"
                                checked={values.NativeBanner_Ads_On}
                                onChange={(status) =>
                                  setFieldValue(
                                    "NativeBanner_Ads_On",
                                    status.target.checked
                                  )
                                }
                              />
                              <div className="knobs"></div>
                              <div className="layer"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="monetize-switch theme-bg">
                          <p className="text-white">Show Banner Ad?</p>
                          <div className="button-app">
                            <div className="button r" id="button-1">
                              <input
                                type="checkbox"
                                className="checkbox"
                                checked={values.Banner_Ads_On}
                                onChange={(status) =>
                                  setFieldValue(
                                    "Banner_Ads_On",
                                    status.target.checked
                                  )
                                }
                              />
                              <div className="knobs"></div>
                              <div className="layer"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="monetize-switch theme-bg">
                          <p className="text-white">Show Native Ad?</p>
                          <div className="button-app">
                            <div className="button r" id="button-1">
                              <input
                                type="checkbox"
                                className="checkbox"
                                checked={values.Native_Ads_On}
                                onChange={(status) =>
                                  setFieldValue(
                                    "Native_Ads_On",
                                    status.target.checked
                                  )
                                }
                              />
                              <div className="knobs"></div>
                              <div className="layer"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ad-setting">
                      <div className="ad-platform p-16">
                        <MultiSelect
                          id="item"
                          name="item"
                          options={adsPlt}
                          value={values.app_AdsPriority}
                          onChange={(e) => {
                            setFieldValue("app_AdsPriority", e.value);
                          }}
                          optionLabel="name"
                          placeholder="Select Ad Platform Sequence"
                        />
                      </div>
                      <hr />
                      <div className="ad-category p-16">
                        <div className="row">
                          <div className="col-md-3 col-12">
                            <div className="form-group mt-0">
                              <label>Select Banner Ad Platform:</label>
                              <select
                                className="ads-platform-type"
                                onChange={(event) =>
                                  setFieldValue(
                                    "app_Banner_AdsPriority",
                                    event.target.value
                                  )
                                }
                              >
                                <option value={""}>Select Ad Banner</option>
                                <option
                                  value={"facebook"}
                                  selected={
                                    values.app_Banner_AdsPriority === "facebook"
                                  }
                                >
                                  Facebook Banner
                                </option>
                                <option
                                  value={"google"}
                                  selected={
                                    values.app_Banner_AdsPriority === "google"
                                  }
                                >
                                  Google Banner
                                </option>
                                <option
                                  value={"applovin"}
                                  selected={
                                    values.app_Banner_AdsPriority === "applovin"
                                  }
                                >
                                  Applovin Banner
                                </option>
                                <option
                                  value={"unity"}
                                  selected={
                                    values.app_Banner_AdsPriority === "unity"
                                  }
                                >
                                  Unity Banner
                                </option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-3 col-12">
                            <div className="form-group mt-0">
                              <label>Select Native Ad Platform:</label>
                              <select
                                className="ads-platform-type"
                                onChange={(event) =>
                                  setFieldValue(
                                    "app_Native_AdsPriority",
                                    event.target.value
                                  )
                                }
                              >
                                <option value={""}>Select Ad Native</option>
                                <option
                                  value={"facebook"}
                                  selected={
                                    values.app_Native_AdsPriority === "facebook"
                                  }
                                >
                                  Facebook Banner
                                </option>
                                <option
                                  value={"google"}
                                  selected={
                                    values.app_Native_AdsPriority === "google"
                                  }
                                >
                                  Google Banner
                                </option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-3 col-12">
                            <div className="form-group mt-0">
                              <label>Select Native Ad Code:</label>
                              <select
                                className="ads-platform-type"
                                onChange={(event) =>
                                  setFieldValue(
                                    "app_NativeAdCodeType",
                                    event.target.value
                                  )
                                }
                              >
                                <option value={""}>
                                  Select Native Ad Code
                                </option>
                                <option
                                  value={"new"}
                                  selected={
                                    values.app_NativeAdCodeType === "new"
                                  }
                                >
                                  New
                                </option>
                                <option
                                  value={"old"}
                                  selected={
                                    values.app_NativeAdCodeType === "old"
                                  }
                                >
                                  Old
                                </option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-3 col-12">
                            <div className="form-group mt-0">
                              <label>Select Banner:</label>
                              <select
                                className="ads-platform-type"
                                onChange={(event) =>
                                  setFieldValue(
                                    "app_BannerPeriority",
                                    event.target.value
                                  )
                                }
                              >
                                <option value={""}>Select Banner</option>
                                <option
                                  value={"banner"}
                                  selected={
                                    values.app_BannerPeriority === "banner"
                                  }
                                >
                                  Banner
                                </option>
                                <option
                                  value={"native"}
                                  selected={
                                    values.app_BannerPeriority === "native"
                                  }
                                >
                                  Native
                                </option>
                              </select>
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="form-group mb-1 mt-0">
                              <label>Native Banner Btn Color</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Color"
                                name="Native_Btn_Color"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.Native_Btn_Color}
                              />
                              {errors.Native_Btn_Color &&
                              touched.Native_Btn_Color ? (
                                <span className="required-lable">
                                  {errors.Native_Btn_Color}{" "}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group mb-1 mt-0">
                              <label>Native Btn Ads Text</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Btn Text"
                                name="Native_Btn_Ads_Text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.Native_Btn_Ads_Text}
                              />
                              {errors.Native_Btn_Ads_Text &&
                              touched.Native_Btn_Ads_Text ? (
                                <span className="required-lable">
                                  {errors.Native_Btn_Ads_Text}{" "}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group mb-1 mt-0">
                              <label>Native Btn Text Size</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Btn Text Size"
                                name="Native_Btn_Text_Size"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.Native_Btn_Text_Size}
                              />
                              {errors.Native_Btn_Text_Size &&
                              touched.Native_Btn_Text_Size ? (
                                <span className="required-lable">
                                  {errors.Native_Btn_Text_Size}{" "}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group mb-1 mt-0">
                              <label>Native Btn Text Color</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Btn Text Color"
                                name="Native_Btn_Text_Color"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.Native_Btn_Text_Color}
                              />
                              {errors.Native_Btn_Text_Color &&
                              touched.Native_Btn_Text_Color ? (
                                <span className="required-lable">
                                  {errors.Native_Btn_Text_Color}{" "}
                                </span>
                              ) : null}
                            </div>
                          </div>

                          <div className="col-md-3 col-12">
                            <div className="monetize-switch mb-0 mt-24">
                              <p>Native Ad Shape Square Corner?</p>
                              <div className="button-app">
                                <div className="button r" id="button-1">
                                  <input
                                    type="checkbox"
                                    className="checkbox"
                                    checked={
                                      values.Native_Ad_Shape_Square_Corner
                                    }
                                    onChange={(status) =>
                                      setFieldValue(
                                        "Native_Ad_Shape_Square_Corner",
                                        status.target.checked
                                      )
                                    }
                                  />
                                  <div className="knobs"></div>
                                  <div className="layer"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 col-12">
                            <div className="monetize-switch mb-0 mt-24">
                              <p>Native Ad Shape Round Corner?</p>
                              <div className="button-app">
                                <div className="button r" id="button-1">
                                  <input
                                    type="checkbox"
                                    className="checkbox"
                                    checked={
                                      values.Native_Ad_Shape_Round_Corner
                                    }
                                    onChange={(status) =>
                                      setFieldValue(
                                        "Native_Ad_Shape_Round_Corner",
                                        status.target.checked
                                      )
                                    }
                                  />
                                  <div className="knobs"></div>
                                  <div className="layer"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 col-12">
                            <div className="monetize-switch mb-0 mt-24">
                              <p>Native Ad Small Size?</p>
                              <div className="button-app">
                                <div className="button r" id="button-1">
                                  <input
                                    type="checkbox"
                                    className="checkbox"
                                    checked={values.Native_Ad_Small_Size}
                                    onChange={(status) =>
                                      setFieldValue(
                                        "Native_Ad_Small_Size",
                                        status.target.checked
                                      )
                                    }
                                  />
                                  <div className="knobs"></div>
                                  <div className="layer"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 col-12">
                            <div className="monetize-switch mb-0 mt-24">
                              <p>Native Ad Shape Normal Round Btn?</p>
                              <div className="button-app">
                                <div className="button r" id="button-1">
                                  <input
                                    type="checkbox"
                                    className="checkbox"
                                    checked={
                                      values.Native_Ad_Shape_Normal_Round_Btn
                                    }
                                    onChange={(status) =>
                                      setFieldValue(
                                        "Native_Ad_Shape_Normal_Round_Btn",
                                        status.target.checked
                                      )
                                    }
                                  />
                                  <div className="knobs"></div>
                                  <div className="layer"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-12">
                        <div className="form-group">
                          <label>Ad Click Count (Main Home Page)</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enetr Ad Click Count"
                            name="app_MainClickCntSwAd"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.app_MainClickCntSwAd}
                          />
                          {errors.app_MainClickCntSwAd &&
                          touched.app_MainClickCntSwAd ? (
                            <span className="required-lable">
                              {errors.app_MainClickCntSwAd}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="form-group">
                          <label>Ad Click Count (Inner Page)</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enetr Ad Click Count"
                            name="app_InnerClickCntSwAd"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.app_InnerClickCntSwAd}
                          />
                          {errors.app_InnerClickCntSwAd &&
                          touched.app_InnerClickCntSwAd ? (
                            <span className="required-lable">
                              {errors.app_InnerClickCntSwAd}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="ads-plateform">
                          <Tabs
                            defaultActiveKey="admob"
                            transition={false}
                            id="noanim-tab-example"
                          >
                            <Tab eventKey="admob" title="Admob Ads">
                              <p className="tab-title">Admob Ads Placement</p>
                              <div className="tab-social-content">
                                <div className="row">
                                  <div className="col-md-6 col-12">
                                    <div className="form-group">
                                      <label>Interstitial</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Interstitial ID"
                                        name="AD_MOB_APP_ID_Inter1"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.AD_MOB_APP_ID_Inter1}
                                      />
                                      {errors.AD_MOB_APP_ID_Inter1 &&
                                      touched.AD_MOB_APP_ID_Inter1 ? (
                                        <span className="required-lable">
                                          {errors.AD_MOB_APP_ID_Inter1}{" "}
                                        </span>
                                      ) : null}
                                    </div>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Interstitial ID"
                                        name="AD_MOB_APP_ID_Inter2"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.AD_MOB_APP_ID_Inter2}
                                      />
                                      {errors.AD_MOB_APP_ID_Inter2 &&
                                      touched.AD_MOB_APP_ID_Inter2 ? (
                                        <span className="required-lable">
                                          {errors.AD_MOB_APP_ID_Inter2}{" "}
                                        </span>
                                      ) : null}
                                    </div>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Interstitial ID"
                                        name="AD_MOB_APP_ID_Inter3"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.AD_MOB_APP_ID_Inter3}
                                      />
                                      {errors.AD_MOB_APP_ID_Inter3 &&
                                      touched.AD_MOB_APP_ID_Inter3 ? (
                                        <span className="required-lable">
                                          {errors.AD_MOB_APP_ID_Inter3}{" "}
                                        </span>
                                      ) : null}
                                    </div>
                                    <div className="form-group">
                                      <label>AppOpen</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter AppOpen ID"
                                        name="AD_MOB_APP_ID_OpenAd1"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.AD_MOB_APP_ID_OpenAd1}
                                      />
                                      {errors.AD_MOB_APP_ID_OpenAd1 &&
                                      touched.AD_MOB_APP_ID_OpenAd1 ? (
                                        <span className="required-lable">
                                          {errors.AD_MOB_APP_ID_OpenAd1}{" "}
                                        </span>
                                      ) : null}
                                    </div>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter AppOpen ID"
                                        name="AD_MOB_APP_ID_OpenAd2"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.AD_MOB_APP_ID_OpenAd2}
                                      />
                                      {errors.AD_MOB_APP_ID_OpenAd2 &&
                                      touched.AD_MOB_APP_ID_OpenAd2 ? (
                                        <span className="required-lable">
                                          {errors.AD_MOB_APP_ID_OpenAd2}{" "}
                                        </span>
                                      ) : null}
                                    </div>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter AppOpen ID"
                                        name="AD_MOB_APP_ID_OpenAd3"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.AD_MOB_APP_ID_OpenAd3}
                                      />
                                      {errors.AD_MOB_APP_ID_OpenAd3 &&
                                      touched.AD_MOB_APP_ID_OpenAd3 ? (
                                        <span className="required-lable">
                                          {errors.AD_MOB_APP_ID_OpenAd3}{" "}
                                        </span>
                                      ) : null}
                                    </div>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter AppOpen ID"
                                        name="AD_MOB_APP_ID_OpenAd4"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.AD_MOB_APP_ID_OpenAd4}
                                      />
                                      {errors.AD_MOB_APP_ID_OpenAd4 &&
                                      touched.AD_MOB_APP_ID_OpenAd4 ? (
                                        <span className="required-lable">
                                          {errors.AD_MOB_APP_ID_OpenAd4}{" "}
                                        </span>
                                      ) : null}
                                    </div>
                                    <div className="form-group">
                                      <label>RewardedVideo</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="RewardedVideo"
                                        name="AD_MOB_APP_ID_RewardedVideo"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={
                                          values.AD_MOB_APP_ID_RewardedVideo
                                        }
                                      />
                                      {errors.AD_MOB_APP_ID_RewardedVideo &&
                                      touched.AD_MOB_APP_ID_RewardedVideo ? (
                                        <span className="required-lable">
                                          {errors.AD_MOB_APP_ID_RewardedVideo}{" "}
                                        </span>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-12">
                                    <div className="form-group">
                                      <label>Banner</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Banner ID"
                                        name="AD_MOB_APP_ID_Banner1"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.AD_MOB_APP_ID_Banner1}
                                      />
                                      {errors.AD_MOB_APP_ID_Banner1 &&
                                      touched.AD_MOB_APP_ID_Banner1 ? (
                                        <span className="required-lable">
                                          {errors.AD_MOB_APP_ID_Banner1}{" "}
                                        </span>
                                      ) : null}
                                    </div>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Banner ID"
                                        name="AD_MOB_APP_ID_Banner2"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.AD_MOB_APP_ID_Banner2}
                                      />
                                      {errors.AD_MOB_APP_ID_Banner2 &&
                                      touched.AD_MOB_APP_ID_Banner2 ? (
                                        <span className="required-lable">
                                          {errors.AD_MOB_APP_ID_Banner2}{" "}
                                        </span>
                                      ) : null}
                                    </div>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Banner ID"
                                        name="AD_MOB_APP_ID_Banner3"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.AD_MOB_APP_ID_Banner3}
                                      />
                                      {errors.AD_MOB_APP_ID_Banner3 &&
                                      touched.AD_MOB_APP_ID_Banner3 ? (
                                        <span className="required-lable">
                                          {errors.AD_MOB_APP_ID_Banner3}{" "}
                                        </span>
                                      ) : null}
                                    </div>
                                    <div className="form-group">
                                      <label>Native</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Native ID"
                                        name="AD_MOB_APP_ID_Native1"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.AD_MOB_APP_ID_Native1}
                                      />
                                      {errors.AD_MOB_APP_ID_Native1 &&
                                      touched.AD_MOB_APP_ID_Native1 ? (
                                        <span className="required-lable">
                                          {errors.AD_MOB_APP_ID_Native1}{" "}
                                        </span>
                                      ) : null}
                                    </div>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Native ID"
                                        name="AD_MOB_APP_ID_Native2"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.AD_MOB_APP_ID_Native2}
                                      />
                                      {errors.AD_MOB_APP_ID_Native2 &&
                                      touched.AD_MOB_APP_ID_Native2 ? (
                                        <span className="required-lable">
                                          {errors.AD_MOB_APP_ID_Native2}{" "}
                                        </span>
                                      ) : null}
                                    </div>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Native ID"
                                        name="AD_MOB_APP_ID_Native3"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.AD_MOB_APP_ID_Native3}
                                      />
                                      {errors.AD_MOB_APP_ID_Native3 &&
                                      touched.AD_MOB_APP_ID_Native3 ? (
                                        <span className="required-lable">
                                          {errors.AD_MOB_APP_ID_Native3}{" "}
                                        </span>
                                      ) : null}
                                    </div>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Native ID"
                                        name="AD_MOB_APP_ID_Native4"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.AD_MOB_APP_ID_Native4}
                                      />
                                      {errors.AD_MOB_APP_ID_Native4 &&
                                      touched.AD_MOB_APP_ID_Native4 ? (
                                        <span className="required-lable">
                                          {errors.AD_MOB_APP_ID_Native4}{" "}
                                        </span>
                                      ) : null}
                                    </div>
                                    <div className="form-group">
                                      <label>RewardedInterstitial</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="RewardedInterstitial"
                                        name="AD_MOB_APP_ID_RewardedInterstitial"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={
                                          values.AD_MOB_APP_ID_RewardedInterstitial
                                        }
                                      />
                                      {errors.AD_MOB_APP_ID_RewardedInterstitial &&
                                      touched.AD_MOB_APP_ID_RewardedInterstitial ? (
                                        <span className="required-lable">
                                          {
                                            errors.AD_MOB_APP_ID_RewardedInterstitial
                                          }{" "}
                                        </span>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tab>
                            <Tab eventKey="facebook" title="Facebook Ads">
                              <p className="tab-title">
                                Facebook Ads Placement
                              </p>
                              <div className="tab-social-content">
                                <div className="row">
                                  <div className="col-md-6 col-12">
                                    <div className="form-group">
                                      <label>Banner</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Banner ID"
                                        name="FB_Banner_ID1"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.FB_Banner_ID1}
                                      />
                                      {errors.FB_Banner_ID1 &&
                                      touched.FB_Banner_ID1 ? (
                                        <span className="required-lable">
                                          {errors.FB_Banner_ID1}{" "}
                                        </span>
                                      ) : null}
                                    </div>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Banner ID"
                                        name="FB_Banner_ID2"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.FB_Banner_ID2}
                                      />
                                      {errors.FB_Banner_ID2 &&
                                      touched.FB_Banner_ID2 ? (
                                        <span className="required-lable">
                                          {errors.FB_Banner_ID2}{" "}
                                        </span>
                                      ) : null}
                                    </div>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Banner ID"
                                        name="FB_Banner_ID3"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.FB_Banner_ID3}
                                      />
                                      {errors.FB_Banner_ID3 &&
                                      touched.FB_Banner_ID3 ? (
                                        <span className="required-lable">
                                          {errors.FB_Banner_ID3}{" "}
                                        </span>
                                      ) : null}
                                    </div>
                                    <div className="form-group">
                                      <label>Native</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Native ID"
                                        name="FB_Native_ID1"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.FB_Native_ID1}
                                      />
                                      {errors.FB_Native_ID1 &&
                                      touched.FB_Native_ID1 ? (
                                        <span className="required-lable">
                                          {errors.FB_Native_ID1}{" "}
                                        </span>
                                      ) : null}
                                    </div>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Native ID"
                                        name="FB_Native_ID2"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.FB_Native_ID2}
                                      />
                                      {errors.FB_Native_ID2 &&
                                      touched.FB_Native_ID2 ? (
                                        <span className="required-lable">
                                          {errors.FB_Native_ID2}{" "}
                                        </span>
                                      ) : null}
                                    </div>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Native ID"
                                        name="FB_Native_ID3"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.FB_Native_ID3}
                                      />
                                      {errors.FB_Native_ID3 &&
                                      touched.FB_Native_ID3 ? (
                                        <span className="required-lable">
                                          {errors.FB_Native_ID3}{" "}
                                        </span>
                                      ) : null}
                                    </div>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Native ID"
                                        name="FB_Native_ID4"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.FB_Native_ID4}
                                      />
                                      {errors.FB_Native_ID4 &&
                                      touched.FB_Native_ID4 ? (
                                        <span className="required-lable">
                                          {errors.FB_Native_ID4}{" "}
                                        </span>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-12">
                                    <div className="form-group">
                                      <label>Interstitial</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Entre Interstitial ID"
                                        name="FB_Inter_ID1"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.FB_Inter_ID1}
                                      />
                                      {errors.FB_Inter_ID1 &&
                                      touched.FB_Inter_ID1 ? (
                                        <span className="required-lable">
                                          {errors.FB_Inter_ID1}{" "}
                                        </span>
                                      ) : null}
                                    </div>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Interstitial ID"
                                        name="FB_Inter_ID2"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.FB_Inter_ID2}
                                      />
                                      {errors.FB_Inter_ID2 &&
                                      touched.FB_Inter_ID2 ? (
                                        <span className="required-lable">
                                          {errors.FB_Inter_ID2}{" "}
                                        </span>
                                      ) : null}
                                    </div>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Interstitial ID"
                                        name="FB_Inter_ID3"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.FB_Inter_ID3}
                                      />
                                      {errors.FB_Inter_ID3 &&
                                      touched.FB_Inter_ID3 ? (
                                        <span className="required-lable">
                                          {errors.FB_Inter_ID3}{" "}
                                        </span>
                                      ) : null}
                                    </div>
                                    <div className="form-group">
                                      <label>Banner Native</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Entre Banner Native ID"
                                        name="FB_BannerNative_ID1"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.FB_BannerNative_ID1}
                                      />
                                      {errors.FB_BannerNative_ID1 &&
                                      touched.FB_BannerNative_ID1 ? (
                                        <span className="required-lable">
                                          {errors.FB_BannerNative_ID1}{" "}
                                        </span>
                                      ) : null}
                                    </div>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Banner Native ID"
                                        name="FB_BannerNative_ID2"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.FB_BannerNative_ID2}
                                      />
                                      {errors.FB_BannerNative_ID2 &&
                                      touched.FB_BannerNative_ID2 ? (
                                        <span className="required-lable">
                                          {errors.FB_BannerNative_ID2}{" "}
                                        </span>
                                      ) : null}
                                    </div>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Banner Native ID"
                                        name="FB_BannerNative_ID3"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.FB_BannerNative_ID3}
                                      />
                                      {errors.FB_BannerNative_ID3 &&
                                      touched.FB_BannerNative_ID3 ? (
                                        <span className="required-lable">
                                          {errors.FB_BannerNative_ID3}{" "}
                                        </span>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tab>
                            <Tab eventKey="applovin" title="Applovin Ads">
                              <p className="tab-title">
                                Applovin Ads Placement
                              </p>
                              <div className="tab-social-content">
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="row">
                                      <div className="col-md-6 col-12">
                                        <div className="form-group">
                                          <label>Banner</label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Banner ID"
                                            name="app_ApplovinBanner1"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.app_ApplovinBanner1}
                                          />
                                          {errors.app_ApplovinBanner1 &&
                                          touched.app_ApplovinBanner1 ? (
                                            <span className="required-lable">
                                              {errors.app_ApplovinBanner1}{" "}
                                            </span>
                                          ) : null}
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>Banner Height</label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Height"
                                            name="app_ApplovinBannerSize"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={
                                              values.app_ApplovinBannerSize
                                            }
                                          />
                                          {errors.app_ApplovinBannerSize &&
                                          touched.app_ApplovinBannerSize ? (
                                            <span className="required-lable">
                                              {errors.app_ApplovinBannerSize}{" "}
                                            </span>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <label>Native</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Native ID"
                                        name="app_ApplovinNative1"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.app_ApplovinNative1}
                                      />
                                      {errors.app_ApplovinNative1 &&
                                      touched.app_ApplovinNative1 ? (
                                        <span className="required-lable">
                                          {errors.app_ApplovinNative1}{" "}
                                        </span>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-12">
                                    <div className="form-group">
                                      <label>Interstitial</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Entre Interstitial ID"
                                        name="app_ApplovinInterstitialId1"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={
                                          values.app_ApplovinInterstitialId1
                                        }
                                      />
                                      {errors.app_ApplovinInterstitialId1 &&
                                      touched.app_ApplovinInterstitialId1 ? (
                                        <span className="required-lable">
                                          {errors.app_ApplovinInterstitialId1}{" "}
                                        </span>
                                      ) : null}
                                    </div>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Interstitial ID"
                                        name="app_ApplovinInterstitialId2"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={
                                          values.app_ApplovinInterstitialId2
                                        }
                                      />
                                      {errors.app_ApplovinInterstitialId2 &&
                                      touched.app_ApplovinInterstitialId2 ? (
                                        <span className="required-lable">
                                          {errors.app_ApplovinInterstitialId2}{" "}
                                        </span>
                                      ) : null}
                                    </div>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Interstitial ID"
                                        name="app_ApplovinInterstitialId3"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={
                                          values.app_ApplovinInterstitialId3
                                        }
                                      />
                                      {errors.app_ApplovinInterstitialId3 &&
                                      touched.app_ApplovinInterstitialId3 ? (
                                        <span className="required-lable">
                                          {errors.app_ApplovinInterstitialId3}{" "}
                                        </span>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tab>
                            <Tab eventKey="unity" title="Unity Ads">
                              <div className="d-flex justify-content-between pt-24">
                                <p className="tab-title p-0">
                                  Unity Ads Placement
                                </p>
                                <div className="d-flex gap-2 align-items-centerd-flex gap-2 align-items-center">
                                  <p>Do you want to on test mode?</p>
                                  <div className="button-app">
                                    <div className="button r" id="button-1">
                                      <input
                                        type="checkbox"
                                        className="checkbox"
                                        checked={values.app_UnityTestMode}
                                        onChange={(status) =>
                                          setFieldValue(
                                            "app_UnityTestMode",
                                            status.target.checked
                                          )
                                        }
                                      />
                                      <div className="knobs"></div>
                                      <div className="layer"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="tab-social-content">
                                <div className="row">
                                  <div className="col-md-6 col-12">
                                    <div className="form-group">
                                      <label>Unity App ID</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Unity App ID"
                                        name="app_UnityAppId"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.app_UnityAppId}
                                      />
                                      {errors.app_UnityAppId &&
                                      touched.app_UnityAppId ? (
                                        <span className="required-lable">
                                          {errors.app_UnityAppId}{" "}
                                        </span>
                                      ) : null}
                                    </div>
                                    <div className="form-group">
                                      <label>Interstitial</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Entre Interstitial ID"
                                        name="app_UnityInterstitialId1"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.app_UnityInterstitialId1}
                                      />
                                      {errors.app_UnityInterstitialId1 &&
                                      touched.app_UnityInterstitialId1 ? (
                                        <span className="required-lable">
                                          {errors.app_UnityInterstitialId1}{" "}
                                        </span>
                                      ) : null}
                                    </div>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Interstitial ID"
                                        name="app_UnityInterstitialId2"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.app_UnityInterstitialId2}
                                      />
                                      {errors.app_UnityInterstitialId2 &&
                                      touched.app_UnityInterstitialId2 ? (
                                        <span className="required-lable">
                                          {errors.app_UnityInterstitialId2}{" "}
                                        </span>
                                      ) : null}
                                    </div>
                                    <div className="form-group mb-0">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Interstitial ID"
                                        name="app_UnityInterstitialId3"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.app_UnityInterstitialId3}
                                      />
                                      {errors.app_UnityInterstitialId3 &&
                                      touched.app_UnityInterstitialId3 ? (
                                        <span className="required-lable">
                                          {errors.app_UnityInterstitialId3}{" "}
                                        </span>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-12">
                                    <div className="form-group">
                                      <label>Banner</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Banner ID"
                                        name="app_UnityBannerId"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.app_UnityBannerId}
                                      />
                                      {errors.app_UnityBannerId &&
                                      touched.app_UnityBannerId ? (
                                        <span className="required-lable">
                                          {errors.app_UnityBannerId}{" "}
                                        </span>
                                      ) : null}
                                    </div>
                                    <div className="row">
                                      <div className="col-md-6 col-12">
                                        <div className="form-group mt-0">
                                          <label>Banner Width</label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Banner Width"
                                            name="app_UnityBannerWidth"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.app_UnityBannerWidth}
                                          />
                                          {errors.app_UnityBannerWidth &&
                                          touched.app_UnityBannerWidth ? (
                                            <span className="required-lable">
                                              {errors.app_UnityBannerWidth}{" "}
                                            </span>
                                          ) : null}
                                        </div>
                                      </div>
                                      <div className="col-md-6 col-12">
                                        <div className="form-group mt-0">
                                          <label>Banner Height</label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Banner Height"
                                            name="app_UnityBannerHeight"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.app_UnityBannerHeight}
                                          />
                                          {errors.app_UnityBannerHeight &&
                                          touched.app_UnityBannerHeight ? (
                                            <span className="required-lable">
                                              {errors.app_UnityBannerHeight}{" "}
                                            </span>
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-12"></div>
                                </div>
                              </div>
                            </Tab>
                          </Tabs>
                        </div>
                      </div>
                      <div className="col-md-12 col-12">
                        <div className="form-group mb-0">
                          <label>Extra Data : Json format data only...!!</label>
                          <textarea
                            className="form-control"
                            placeholder="Json format data only...!!"
                            name="monetize_app_extra"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.monetize_app_extra}
                            rows="10"
                          />
                          {errors.monetize_app_extra &&
                          touched.monetize_app_extra ? (
                            <span className="required-lable">
                              {errors.monetize_app_extra}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
              )}
              {(appType === "ads app" || appType === "ads-pay-app") && (
                <Tab
                  eventKey="app_custome_ad_settings"
                  title="Custome AD Settings"
                >
                  <div className="custome-ad-setting mt-24">
                    <div className="row">
                      <div className="col-md-4 col-12">
                        <div className="monetize-switch bg-primary-subtle border-0">
                          <p>Show Native Ad?</p>
                          <div className="button-app">
                            <div className="button r" id="button-1">
                              <input
                                type="checkbox"
                                className="checkbox"
                                checked={values.app_OnlyNativeADShow}
                                onChange={(status) =>
                                  setFieldValue(
                                    "app_OnlyNativeADShow",
                                    status.target.checked
                                  )
                                }
                              />
                              <div className="knobs"></div>
                              <div className="layer"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="monetize-switch bg-success-subtle border-0">
                          <p>Show Banner Ad?</p>
                          <div className="button-app">
                            <div className="button r" id="button-1">
                              <input
                                type="checkbox"
                                className="checkbox"
                                checked={values.app_OnlyBannerADShow}
                                onChange={(status) =>
                                  setFieldValue(
                                    "app_OnlyBannerADShow",
                                    status.target.checked
                                  )
                                }
                              />
                              <div className="knobs"></div>
                              <div className="layer"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="monetize-switch bg-danger-subtle border-0">
                          <p> Show Interstital Ad?</p>
                          <div className="button-app">
                            <div className="button r" id="button-1">
                              <input
                                type="checkbox"
                                className="checkbox"
                                checked={values.app_OnlyInterstitalADShow}
                                onChange={(status) =>
                                  setFieldValue(
                                    "app_OnlyInterstitalADShow",
                                    status.target.checked
                                  )
                                }
                              />
                              <div className="knobs"></div>
                              <div className="layer"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 col-12">
                        <div className="d-flex w-50 flex-column gap-2 m-auto align-items-center">
                          <div className="form-labale d-flex gap-2">
                            <label>Do you want show only qureka?</label>
                            <div className="button-app">
                              <div className="button r" id="button-1">
                                <input
                                  type="checkbox"
                                  className="checkbox"
                                  checked={values.app_OnlyQurekaStatusShow}
                                  onChange={(status) =>
                                    setFieldValue(
                                      "app_OnlyQurekaStatusShow",
                                      status.target.checked
                                    )
                                  }
                                />
                                <div className="knobs"></div>
                                <div className="layer"></div>
                              </div>
                            </div>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Qureka"
                            name="app_OnlyQurekaLink"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.app_OnlyQurekaLink}
                          />
                          {errors.app_OnlyQurekaLink &&
                          touched.app_OnlyQurekaLink ? (
                            <span className="required-lable">
                              {errors.app_OnlyQurekaLink}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row pt-4">
                      <div className="col-md-6 col-12">
                        <div className="form-group">
                          <label>Ad Click Count</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Ad Click Count"
                            name="app_CustClickCntSAd"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.app_CustClickCntSAd}
                          />
                          {errors.app_CustClickCntSAd &&
                          touched.app_CustClickCntSAd ? (
                            <span className="required-lable">
                              {errors.app_CustClickCntSAd}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="form-group">
                          <label>which kind of ad</label>
                          <select
                            className="form-control"
                            onChange={(custAd) =>
                              setFieldValue(
                                "app_CustomeADText",
                                custAd.target.value
                              )
                            }
                            onBlur={handleBlur}
                          >
                            <option value={""}>Select Ad Type</option>
                            <option
                              value={"custome"}
                              selected={
                                values.app_CustomeADText == "custome"
                                  ? true
                                  : false
                              }
                            >
                              Custome
                            </option>
                            <option
                              value={"qureka"}
                              selected={
                                values.app_CustomeADText == "qureka"
                                  ? true
                                  : false
                              }
                            >
                              Qureka
                            </option>
                          </select>
                          {errors.app_CustomeADText &&
                          touched.app_CustomeADText ? (
                            <span className="required-lable">
                              {errors.app_CustomeADText}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="form-group">
                          <label>Interstitial Link</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enetr Link"
                            name="app_CustomeAdInterstitialLink"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.app_CustomeAdInterstitialLink}
                          />
                          {errors.app_CustomeAdInterstitialLink &&
                          touched.app_CustomeAdInterstitialLink ? (
                            <span className="required-lable">
                              {errors.app_CustomeAdInterstitialLink}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="form-group">
                          <label>Interfull Screen Image</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enetr Image Link"
                            name="app_CustomeAdInterFullscreenImg"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.app_CustomeAdInterFullscreenImg}
                          />
                          {errors.app_CustomeAdInterFullscreenImg &&
                          touched.app_CustomeAdInterFullscreenImg ? (
                            <span className="required-lable">
                              {errors.app_CustomeAdInterFullscreenImg}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-4 col-12">
                        <div className="form-group">
                          <label>Qureka Link</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Link"
                            name="app_CustomeAdQurekaLink"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.app_CustomeAdQurekaLink}
                          />
                          {errors.app_CustomeAdQurekaLink &&
                          touched.app_CustomeAdQurekaLink ? (
                            <span className="required-lable">
                              {errors.app_CustomeAdQurekaLink}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="custome-title">
                      <p>Native Ads</p>
                    </div>
                    <div className="row pt-4">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Large Image</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Image"
                            name="app_CustomeAdNativeLargeImage"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.app_CustomeAdNativeLargeImage}
                          />
                          {errors.app_CustomeAdNativeLargeImage &&
                          touched.app_CustomeAdNativeLargeImage ? (
                            <span className="required-lable">
                              {errors.app_CustomeAdNativeLargeImage}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Icon Image</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Icon Image"
                            name="app_CustomeAdNativeIconImage"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.app_CustomeAdNativeIconImage}
                          />
                          {errors.app_CustomeAdNativeIconImage &&
                          touched.app_CustomeAdNativeIconImage ? (
                            <span className="required-lable">
                              {errors.app_CustomeAdNativeIconImage}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Title</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Title"
                            name="app_CustomeAdNativeTitle"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.app_CustomeAdNativeTitle}
                          />
                          {errors.app_CustomeAdNativeTitle &&
                          touched.app_CustomeAdNativeTitle ? (
                            <span className="required-lable">
                              {errors.app_CustomeAdNativeTitle}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>SubHeader</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter SubHeader"
                            name="app_CustomeAdNativesubHeader"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.app_CustomeAdNativesubHeader}
                          />
                          {errors.app_CustomeAdNativesubHeader &&
                          touched.app_CustomeAdNativesubHeader ? (
                            <span className="required-lable">
                              {errors.app_CustomeAdNativesubHeader}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>SubHeader 2</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter SubHeader2"
                            name="app_CustomeAdNativesubHeader2"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.app_CustomeAdNativesubHeader2}
                          />
                          {errors.app_CustomeAdNativesubHeader2 &&
                          touched.app_CustomeAdNativesubHeader2 ? (
                            <span className="required-lable">
                              {errors.app_CustomeAdNativesubHeader2}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Install Text</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Install Text"
                            name="app_CustomeAdNativeInstallText"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.app_CustomeAdNativeInstallText}
                          />
                          {errors.app_CustomeAdNativeInstallText &&
                          touched.app_CustomeAdNativeInstallText ? (
                            <span className="required-lable">
                              {errors.app_CustomeAdNativeInstallText}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>Link</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Link"
                            name="app_CustomeADNativeLink"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.app_CustomeADNativeLink}
                          />
                          {errors.app_CustomeADNativeLink &&
                          touched.app_CustomeADNativeLink ? (
                            <span className="required-lable">
                              {errors.app_CustomeADNativeLink}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="custome-title">
                      <p>Banner Ads</p>
                    </div>
                    <div className="row pt-4">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Icon</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Icon"
                            name="app_CustomeAdBannerIcon"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.app_CustomeAdBannerIcon}
                          />
                          {errors.app_CustomeAdBannerIcon &&
                          touched.app_CustomeAdBannerIcon ? (
                            <span className="required-lable">
                              {errors.app_CustomeAdBannerIcon}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Install Text</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Install Text"
                            name="app_CustomeAdBannerInstallText"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.app_CustomeAdBannerInstallText}
                          />
                          {errors.app_CustomeAdBannerInstallText &&
                          touched.app_CustomeAdBannerInstallText ? (
                            <span className="required-lable">
                              {errors.app_CustomeAdBannerInstallText}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Header</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter SubHeader"
                            name="app_CustomeAdBannerHeaderText"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.app_CustomeAdBannerHeaderText}
                          />
                          {errors.app_CustomeAdBannerHeaderText &&
                          touched.app_CustomeAdBannerHeaderText ? (
                            <span className="required-lable">
                              {errors.app_CustomeAdBannerHeaderText}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>SubHeader</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter SubHeader"
                            name="app_CustomeAdBannerSubHeaderText"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.app_CustomeAdBannerSubHeaderText}
                          />
                          {errors.app_CustomeAdBannerSubHeaderText &&
                          touched.app_CustomeAdBannerSubHeaderText ? (
                            <span className="required-lable">
                              {errors.app_CustomeAdBannerSubHeaderText}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Link</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Link"
                            name="app_CustomeADBannerLink"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.app_CustomeADBannerLink}
                          />
                          {errors.app_CustomeADBannerLink &&
                          touched.app_CustomeADBannerLink ? (
                            <span className="required-lable">
                              {errors.app_CustomeADBannerLink}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
              )}
              {(appType === "payment app" || appType === "ads-pay-app") && (
                <Tab eventKey="app_payment_settings" title="Payment Settings">
                  <div className="app_payment_settings">
                    <div className="row">
                      <div className="col-md-6 col-12">
                        <div className="form-group">
                          <label>Plan Play Store Key</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Plan Coin 99"
                            name="plan_coin_99_key"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.plan_coin_99_key}
                          />
                          {errors.plan_coin_99_key &&
                          touched.plan_coin_99_key ? (
                            <span className="required-lable">
                              {errors.plan_coin_99_key}{" "}
                            </span>
                          ) : null}
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Plan Coin 179"
                            name="plan_coin_179_key"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.plan_coin_179_key}
                          />
                          {errors.plan_coin_179_key &&
                          touched.plan_coin_179_key ? (
                            <span className="required-lable">
                              {errors.plan_coin_179_key}{" "}
                            </span>
                          ) : null}
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Plan Coin 349"
                            name="plan_coin_349_key"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.plan_coin_349_key}
                          />
                          {errors.plan_coin_349_key &&
                          touched.plan_coin_349_key ? (
                            <span className="required-lable">
                              {errors.plan_coin_349_key}{" "}
                            </span>
                          ) : null}
                        </div>
                        <div className="form-group">
                          <label>Plan Amount</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Plan Amt A"
                            name="plan_Amt_A"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.plan_Amt_A}
                          />
                          {errors.plan_Amt_A && touched.plan_Amt_A ? (
                            <span className="required-lable">
                              {errors.plan_Amt_A}{" "}
                            </span>
                          ) : null}
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Plan Amt B"
                            name="plan_Amt_B"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.plan_Amt_B}
                          />
                          {errors.plan_Amt_B && touched.plan_Amt_B ? (
                            <span className="required-lable">
                              {errors.plan_Amt_B}{" "}
                            </span>
                          ) : null}
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Plan Amt C"
                            name="plan_Amt_C"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.plan_Amt_C}
                          />
                          {errors.plan_Amt_C && touched.plan_Amt_C ? (
                            <span className="required-lable">
                              {errors.plan_Amt_C}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="form-group">
                          <label>Plan Coin</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Plan Coin A"
                            name="plan_Coin_A"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.plan_Coin_A}
                          />
                          {errors.plan_Coin_A && touched.plan_Coin_A ? (
                            <span className="required-lable">
                              {errors.plan_Coin_A}{" "}
                            </span>
                          ) : null}
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Plan Coin B"
                            name="plan_Coin_B"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.plan_Coin_B}
                          />
                          {errors.plan_Coin_B && touched.plan_Coin_B ? (
                            <span className="required-lable">
                              {errors.plan_Coin_B}{" "}
                            </span>
                          ) : null}
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Plan Coin C"
                            name="plan_Coin_C"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.plan_Coin_C}
                          />
                          {errors.plan_Coin_C && touched.plan_Coin_C ? (
                            <span className="required-lable">
                              {errors.plan_Coin_C}{" "}
                            </span>
                          ) : null}
                        </div>
                        <div className="form-group">
                          <label>Coin Url And Params</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Av O"
                            name="av_0"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.av_0}
                          />
                          {errors.av_0 && touched.av_0 ? (
                            <span className="required-lable">
                              {errors.av_0}{" "}
                            </span>
                          ) : null}
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Av 1"
                            name="av_1"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.av_1}
                          />
                          {errors.av_1 && touched.av_1 ? (
                            <span className="required-lable">
                              {errors.av_1}{" "}
                            </span>
                          ) : null}
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Av 2"
                            name="av_2"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.av_2}
                          />
                          {errors.av_2 && touched.av_2 ? (
                            <span className="required-lable">
                              {errors.av_2}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group mt-0">
                          <label>Call Per Coin</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Call Coin"
                            name="per_call_coin"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.per_call_coin}
                          />
                          {errors.per_call_coin && touched.per_call_coin ? (
                            <span className="required-lable">
                              {errors.per_call_coin}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group mt-0">
                          <label>Login Number</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Login Number"
                            name="login_num"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.login_num}
                          />
                          {errors.login_num && touched.login_num ? (
                            <span className="required-lable">
                              {errors.login_num}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group mt-0">
                          <label>Payment Option</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Payment Option"
                            name="payment_option"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.payment_option}
                          />
                          {errors.payment_option && touched.payment_option ? (
                            <span className="required-lable">
                              {errors.payment_option}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group mt-0">
                          <label>payeeVpa</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter payeeVpa"
                            name="payeeVpa"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.payeeVpa}
                          />
                          {errors.payeeVpa && touched.payeeVpa ? (
                            <span className="required-lable">
                              {errors.payeeVpa}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group mt-0">
                          <label>payeeName</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter payeeName"
                            name="payeeName"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.payeeName}
                          />
                          {errors.payeeName && touched.payeeName ? (
                            <span className="required-lable">
                              {errors.payeeName}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group mt-0">
                          <label>Description</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Description"
                            name="description"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.description}
                          />
                          {errors.description && touched.description ? (
                            <span className="required-lable">
                              {errors.description}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-12 col-12">
                        <div className="form-group mt-0 mb-24">
                          <label>Extra Data : Json format data only...!!</label>
                          <textarea
                            className="form-control"
                            placeholder="Json format data only...!!"
                            name="payment_app_extra"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.payment_app_extra}
                            rows="10"
                          />
                          {errors.payment_app_extra &&
                          touched.payment_app_extra ? (
                            <span className="required-lable">
                              {errors.payment_app_extra}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
              )}
              <Tab eventKey="app_master_key_details" title="Master Key">
                <div className="mt-24">
                  <div className="row">
                    <div className="col-md-4 col-12">
                      <div className="form-group mt-0">
                        <label>Country</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enetr country"
                          value={values.country}
                          name="country"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-12">
                      <div className="form-group mt-0">
                        <label>Region Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enetr Region Name"
                          value={values.regionName}
                          name="regionName"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-12">
                      <div className="form-group mt-0">
                        <label>isp</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enetr isp"
                          value={values.isp}
                          name="isp"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mt-0">
                        <label>Master Key</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Master Key"
                          name="master_key"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.master_key}
                        />
                        {errors.master_key && touched.master_key ? (
                          <span className="required-lable">
                            {errors.master_key}{" "}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mt-0">
                        <label>Third-party Link</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Third-party link"
                          name="referral_link"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.referral_link}
                        />
                        {errors.referral_link && touched.referral_link ? (
                          <span className="required-lable">
                            {errors.referral_link}{" "}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mt-0">
                        <label>Master Data : Json format data only...!!</label>
                        <textarea
                          className="form-control"
                          placeholder="Json format data only...!!"
                          name="app_extra"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.app_extra}
                          rows="10"
                        />
                        {errors.app_extra && touched.app_extra ? (
                          <span className="required-lable">
                            {errors.app_extra}{" "}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mt-0">
                        <label>
                          Referral Data by Third Party : Json format data
                          only...!!
                        </label>
                        <textarea
                          className="form-control"
                          placeholder="Json format data only...!!"
                          name="third_party"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.third_party}
                          rows="10"
                        />
                        {errors.third_party && touched.third_party ? (
                          <span className="required-lable">
                            {errors.third_party}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mt-0">
                        <label>
                          Master Data by gc-clid : Json format data only...!!
                        </label>
                        <textarea
                          className="form-control"
                          placeholder="Json format data only...!!"
                          name="gc_glide"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.gc_glide}
                          rows="10"
                        />
                        {errors.gc_glide && touched.gc_glide ? (
                          <span className="required-lable">
                            {errors.gc_glide}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mt-0">
                        <label>
                          Referral Data by fb-clid : Json format data only...!!
                        </label>
                        <textarea
                          className="form-control"
                          placeholder="Json format data only...!!"
                          name="fb_glide"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.fb_glide}
                          rows="10"
                        />
                        {errors.fb_glide && touched.fb_glide ? (
                          <span className="required-lable">
                            {errors.fb_glide}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
            <div className="add-submit">
              <button
                className="theme-btn-primary"
                type="submit"
                disabled={IsPending ? true : false}
              >
                {IsPending ? "Loding..." : "Submit"}
              </button>
            </div>
          </form>
        </>
      ) : (
        (appType !== "" || params.id) && (
          <div>
            <div className="ball1 ball"></div>
            <div className="ball2 ball"></div>
            <div className="ball3 ball"></div>
          </div>
        )
      )}
      {!isLoding && (appType === "" || !params) && (
        <>
          <p className="breadcrumb mb-3">
            <Link to={"/dashboard"} className="back-link">
              Dashboard
            </Link>
            /<span className="active text-capitalize">App Type</span>
          </p>
          <div className="select-app-type">
            <div className="app-type" onClick={() => setAppType("ads app")}>
              <div className="app-icon">
                <svg
                  width="80"
                  height="80"
                  viewBox="0 0 60 60"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_248_385)">
                    <path
                      d="M5 30C5 36.6304 7.63392 42.9893 12.3223 47.6777C17.0107 52.3661 23.3696 55 30 55C36.6304 55 42.9893 52.3661 47.6777 47.6777C52.3661 42.9893 55 36.6304 55 30C55 23.3696 52.3661 17.0107 47.6777 12.3223C42.9893 7.63392 36.6304 5 30 5C23.3696 5 17.0107 7.63392 12.3223 12.3223C7.63392 17.0107 5 23.3696 5 30Z"
                      stroke="#4A58EC"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M17.5 37.5V26.25C17.5 25.2554 17.8951 24.3016 18.5983 23.5983C19.3016 22.8951 20.2554 22.5 21.25 22.5C22.2446 22.5 23.1984 22.8951 23.9017 23.5983C24.6049 24.3016 25 25.2554 25 26.25V37.5"
                      stroke="#4A58EC"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M17.5 32.5H25"
                      stroke="#4A58EC"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M35 22.5V37.5H37.5C38.8261 37.5 40.0979 36.9732 41.0355 36.0355C41.9732 35.0979 42.5 33.8261 42.5 32.5V27.5C42.5 26.1739 41.9732 24.9021 41.0355 23.9645C40.0979 23.0268 38.8261 22.5 37.5 22.5H35Z"
                      stroke="#4A58EC"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_248_385">
                      <rect width="60" height="60" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <p>Ads Apps</p>
            </div>
            <div className="app-type" onClick={() => setAppType("payment app")}>
              <div className="app-icon" style={{ backgroundColor: "#D9F2DD" }}>
                <svg
                  width="80"
                  height="80"
                  viewBox="0 0 60 60"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_248_396)">
                    <path
                      d="M42.5 20V12.5C42.5 11.837 42.2366 11.2011 41.7678 10.7322C41.2989 10.2634 40.663 10 40 10H15C13.6739 10 12.4021 10.5268 11.4645 11.4645C10.5268 12.4021 10 13.6739 10 15M10 15C10 16.3261 10.5268 17.5979 11.4645 18.5355C12.4021 19.4732 13.6739 20 15 20H45C45.663 20 46.2989 20.2634 46.7678 20.7322C47.2366 21.2011 47.5 21.837 47.5 22.5V30M10 15V45C10 46.3261 10.5268 47.5979 11.4645 48.5355C12.4021 49.4732 13.6739 50 15 50H45C45.663 50 46.2989 49.7366 46.7678 49.2678C47.2366 48.7989 47.5 48.163 47.5 47.5V40"
                      stroke="#0EB17F"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M50 30V40H40C38.6739 40 37.4021 39.4732 36.4645 38.5355C35.5268 37.5979 35 36.3261 35 35C35 33.6739 35.5268 32.4021 36.4645 31.4645C37.4021 30.5268 38.6739 30 40 30H50Z"
                      stroke="#0EB17F"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_248_396">
                      <rect width="60" height="60" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <p>Payment Apps</p>
            </div>
            <div
              className="app-type"
              onClick={() => setAppType("ads-payment app")}
            >
              <div className="app-icon" style={{ backgroundColor: "#FFF4D5" }}>
                <svg
                  width="80"
                  height="80"
                  viewBox="0 0 60 60"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_248_369)">
                    <path
                      d="M47.2999 20.2002V12.7002C47.2999 12.0372 47.0365 11.4013 46.5677 10.9324C46.0988 10.4636 45.4629 10.2002 44.7999 10.2002H19.7999C18.4738 10.2002 17.202 10.727 16.2644 11.6647C15.3267 12.6023 14.7999 13.8741 14.7999 15.2002M14.7999 15.2002C14.7999 16.5263 15.3267 17.798 16.2644 18.7357C17.202 19.6734 18.4738 20.2002 19.7999 20.2002H49.7999C50.4629 20.2002 51.0988 20.4636 51.5677 20.9324C52.0365 21.4013 52.2999 22.0372 52.2999 22.7002V30.2002M14.7999 15.2002V45.2002C14.7999 46.5263 15.3267 47.798 16.2644 48.7357C17.202 49.6734 18.4738 50.2002 19.7999 50.2002H49.7999C50.4629 50.2002 51.0988 49.9368 51.5677 49.468C52.0365 48.9991 52.2999 48.3632 52.2999 47.7002V40.2002"
                      stroke="#F58A0B"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M54.8001 30.1997V40.1997H44.8001C43.474 40.1997 42.2022 39.6729 41.2645 38.7352C40.3269 37.7976 39.8001 36.5258 39.8001 35.1997C39.8001 33.8736 40.3269 32.6019 41.2645 31.6642C42.2022 30.7265 43.474 30.1997 44.8001 30.1997H54.8001Z"
                      stroke="#F58A0B"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <g clipPath="url(#clip1_248_369)">
                      <path
                        d="M7.00003 33.8008C7.00003 35.9225 7.84289 37.9573 9.34318 39.4576C10.8435 40.9579 12.8783 41.8008 15 41.8008C17.1218 41.8008 19.1566 40.9579 20.6569 39.4576C22.1572 37.9573 23 35.9225 23 33.8008C23 31.679 22.1572 29.6442 20.6569 28.1439C19.1566 26.6436 17.1218 25.8008 15 25.8008C12.8783 25.8008 10.8435 26.6436 9.34318 28.1439C7.84289 29.6442 7.00003 31.679 7.00003 33.8008Z"
                        fill="#F58A0B"
                        stroke="#F58A0B"
                        strokeWidth="1.2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11 36.2004V32.6004C11 32.2821 11.1264 31.9769 11.3515 31.7519C11.5765 31.5268 11.8817 31.4004 12.2 31.4004C12.5183 31.4004 12.8235 31.5268 13.0485 31.7519C13.2736 31.9769 13.4 32.2821 13.4 32.6004V36.2004"
                        stroke="white"
                        strokeWidth="1.2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11 34.6006H13.4"
                        stroke="white"
                        strokeWidth="1.2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.6 31.4004V36.2004H17.4C17.8244 36.2004 18.2313 36.0318 18.5314 35.7318C18.8314 35.4317 19 35.0247 19 34.6004V33.0004C19 32.576 18.8314 32.1691 18.5314 31.869C18.2313 31.569 17.8244 31.4004 17.4 31.4004H16.6Z"
                        stroke="white"
                        strokeWidth="1.2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                  </g>
                  <defs>
                    <clipPath id="clip0_248_369">
                      <rect width="60" height="60" fill="white" />
                    </clipPath>
                    <clipPath id="clip1_248_369">
                      <rect
                        width="19.2"
                        height="19.2"
                        fill="white"
                        transform="translate(5.39999 24.1997)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <p>Ads & Payment Apps</p>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default AddApp;
