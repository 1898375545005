import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import { useLocation } from "react-router-dom";
import { getApi } from "../../helpers/api";
import Pagination from "../Pagination";
export default function SalaryEmployee() {
  const [salaryData, setSalaryData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const search = useLocation().search;
  const params = new URLSearchParams(search);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const [type, setType] = useState("");
  const [count, setCount] = useState(0);
  const fetchData = async () => {
    let salaryurl =
      startDate && endDate
        ? `salarys?id=${params.get(
            "employee"
          )}&sDate=${startDate}&eDate=${endDate}&page=${currentPage}`
        : `salarys?id=${params.get("employee")}&page=${currentPage}`;
    await getApi(salaryurl, true).then((res) => {
      if (res.status === 200) {
        setSalaryData(res.data.data);
        setTotalPages(res.data.totalPages);
        setCount(res.data.count);
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, type]);
  const SerachDate = async () => {
    fetchData(currentPage);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = async () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const getMonthName = (monthNumber) => {
    const date = new Date();
    date.setMonth(monthNumber - 1); // months are 0-based in JavaScript
    return format(date, "MMMM");
  };
  return (
    <div>
      <div className="content-header-right">
        <div className="search-date">
          <DatePicker
            showIcon
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            placeholderText="From Date"
            dateFormat="dd MMMM yyyy"
          />
          &nbsp;&nbsp;&nbsp;
          <DatePicker
            showIcon
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            placeholderText="To Date"
            dateFormat="dd MMMM yyyy"
          />
          &nbsp;&nbsp;&nbsp;
          <button
            className="theme-btn-primary py-0"
            onClick={SerachDate}
            disabled={
              startDate === undefined || endDate === undefined ? true : false
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M4 4h16v2.172a2 2 0 0 1 -.586 1.414l-4.414 4.414v7l-6 2v-8.5l-4.48 -4.928a2 2 0 0 1 -.52 -1.345v-2.227z"></path>
            </svg>
          </button>
        </div>
      </div>
      <table className="table-list">
        <thead>
          <tr>
            {/* <td>id</td> */}
            <td>Name</td>
            <td>Date</td>
            <td>Month</td>
            <td>Year</td>
            <td>Salary</td>
            <td>Status</td>
          </tr>
        </thead>
        <tbody>
          {salaryData?.length ? (
            salaryData?.map((val, key) => {
              return (
                <tr key={key}>
                  {/* <td>{val.id}</td> */}
                  <td>{val?.usersalary?.fullname}</td>
                  <td>{format(new Date(val.date), "dd MMM yyyy")}</td>
                  <td>{getMonthName(val.month)}</td>
                  <td>{val.year}</td>
                  <td>{val.salary}</td>
                  <td>
                    <button
                      className={
                        "theme-btn-primary btnwdth " +
                        (val.status === 0
                          ? "btn-success"
                          : val.status === 2
                          ? "btn-danger"
                          : val.status === 3
                          ? "btn-dark"
                          : "")
                      }
                      // onClick={() => {
                      //   setId(val.id);
                      //   setSmShow(true);
                      // }}
                    >
                      {val.status === 0
                        ? "Received"
                        : val.status === 0
                        ? "Received"
                        : ""}
                    </button>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={6} className="text-center theme-text-danager">
                Not Found.
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {totalPages > 1 ? (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onNext={handleNextPage}
          onPrev={handlePrevPage}
          handlePageChange={handlePageChange}
        />
      ) : null}
    </div>
  );
}
