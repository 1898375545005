import React, { useState, useEffect } from 'react';
import { useStopwatch } from 'react-timer-hook';

const MyStopwatch = () => {
    const {
        totalSeconds: sessionTotalSeconds,
        seconds: sessionSeconds,
        minutes: sessionMinutes,
        hours: sessionHours,
        days: sessionDays,
        isRunning: isSessionRunning,
        start: startSession,
        pause: pauseSession,
    } = useStopwatch({ autoStart: false });

    const {
        totalSeconds: lunchTotalSeconds,
        seconds: lunchSeconds,
        minutes: lunchMinutes,
        hours: lunchHours,
        days: lunchDays,
        isRunning: isLunchRunning,
        start: startLunch,
        pause: pauseLunch,
    } = useStopwatch({ autoStart: false });

    const {
        totalSeconds: teaTotalSeconds,
        seconds: teaSeconds,
        minutes: teaMinutes,
        hours: teaHours,
        days: teaDays,
        isRunning: isTeaRunning,
        start: startTea,
        pause: pauseTea,
    } = useStopwatch({ autoStart: false });

    const {
        totalSeconds: whTotalSeconds,
        seconds: whSeconds,
        minutes: whMinutes,
        hours: whHours,
        days: whDays,
        isRunning: isWhRunning,
        start: startWh,
        pause: pauseWh,
    } = useStopwatch({ autoStart: false });

    const [activeTimer, setActiveTimer] = useState('session'); // 'session', 'lunch', 'tea', 'wh'

    const [sessionStartTime, setSessionStartTime] = useState(null);
    const [sessionDuration, setSessionDuration] = useState(0);
    const [lunchStartTime, setLunchStartTime] = useState(null);
    const [lunchDuration, setLunchDuration] = useState(0);
    const [teaStartTime, setTeaStartTime] = useState(null);
    const [teaDuration, setTeaDuration] = useState(0);
    const [whStartTime, setWhStartTime] = useState(null);
    const [whDuration, setWhDuration] = useState(0);

    // Load saved state from localStorage on component mount
    useEffect(() => {
        const savedState = JSON.parse(localStorage.getItem('myStopwatchState'));
        if (savedState) {
            setSessionStartTime(savedState.sessionStartTime);
            setSessionDuration(savedState.sessionDuration || 0);
            setLunchStartTime(savedState.lunchStartTime);
            setLunchDuration(savedState.lunchDuration || 0);
            setTeaStartTime(savedState.teaStartTime);
            setTeaDuration(savedState.teaDuration || 0);
            setWhStartTime(savedState.whStartTime);
            setWhDuration(savedState.whDuration || 0);
            setActiveTimer(savedState.activeTimer || 'session');
        }
    }, []);

    // Save state to localStorage whenever it changes
    useEffect(() => {
        const stateToSave = {
            sessionStartTime,
            sessionDuration,
            lunchStartTime,
            lunchDuration,
            teaStartTime,
            teaDuration,
            whStartTime,
            whDuration,
            activeTimer,
        };
        localStorage.setItem('myStopwatchState', JSON.stringify(stateToSave));
    }, [sessionStartTime, sessionDuration, lunchStartTime, lunchDuration, teaStartTime, teaDuration, whStartTime, whDuration, activeTimer]);

    // Function to start the active timer (session, lunch, tea, wh)
    const handleStart = () => {
        switch (activeTimer) {
            case 'session':
                startSession();
                setSessionStartTime(Date.now());
                break;
            case 'lunch':
                startLunch();
                setLunchStartTime(Date.now());
                break;
            case 'tea':
                startTea();
                setTeaStartTime(Date.now());
                break;
            case 'wh':
                startWh();
                setWhStartTime(Date.now());
                break;
            default:
                break;
        }
    };

    // Function to pause the active timer (session, lunch, tea, wh)
    const handlePause = () => {
        switch (activeTimer) {
            case 'session':
                pauseSession();
                if (sessionStartTime) {
                    const currentTime = Date.now();
                    const durationInSeconds = Math.floor((currentTime - sessionStartTime) / 1000);
                    setSessionDuration(prevDuration => prevDuration + durationInSeconds);
                    setSessionStartTime(null);
                }
                break;
            case 'lunch':
                pauseLunch();
                if (lunchStartTime) {
                    const currentTime = Date.now();
                    const durationInSeconds = Math.floor((currentTime - lunchStartTime) / 1000);
                    setLunchDuration(prevDuration => prevDuration + durationInSeconds);
                    setLunchStartTime(null);
                }
                break;
            case 'tea':
                pauseTea();
                if (teaStartTime) {
                    const currentTime = Date.now();
                    const durationInSeconds = Math.floor((currentTime - teaStartTime) / 1000);
                    setTeaDuration(prevDuration => prevDuration + durationInSeconds);
                    setTeaStartTime(null);
                }
                break;
            case 'wh':
                pauseWh();
                if (whStartTime) {
                    const currentTime = Date.now();
                    const durationInSeconds = Math.floor((currentTime - whStartTime) / 1000);
                    setWhDuration(prevDuration => prevDuration + durationInSeconds);
                    setWhStartTime(null);
                }
                break;
            default:
                break;
        }
    };

    // Function to switch between session, lunch, tea, wh timers
    const handleSwitchTimer = (timer) => {
        if (activeTimer !== timer) {
            switch (activeTimer) {
                case 'session':
                    pauseSession();
                    if (sessionStartTime) {
                        const currentTime = Date.now();
                        const durationInSeconds = Math.floor((currentTime - sessionStartTime) / 1000);
                        setSessionDuration(prevDuration => prevDuration + durationInSeconds);
                        setSessionStartTime(null);
                    }
                    break;
                case 'lunch':
                    pauseLunch();
                    if (lunchStartTime) {
                        const currentTime = Date.now();
                        const durationInSeconds = Math.floor((currentTime - lunchStartTime) / 1000);
                        setLunchDuration(prevDuration => prevDuration + durationInSeconds);
                        setLunchStartTime(null);
                    }
                    break;
                case 'tea':
                    pauseTea();
                    if (teaStartTime) {
                        const currentTime = Date.now();
                        const durationInSeconds = Math.floor((currentTime - teaStartTime) / 1000);
                        setTeaDuration(prevDuration => prevDuration + durationInSeconds);
                        setTeaStartTime(null);
                    }
                    break;
                case 'wh':
                    pauseWh();
                    if (whStartTime) {
                        const currentTime = Date.now();
                        const durationInSeconds = Math.floor((currentTime - whStartTime) / 1000);
                        setWhDuration(prevDuration => prevDuration + durationInSeconds);
                        setWhStartTime(null);
                    }
                    break;
                default:
                    break;
            }

            setActiveTimer(timer);
        }
    };

    // Format time in hours, minutes, and seconds
    const formatTime = (seconds) => {
        const hrs = Math.floor(seconds / 3600);
        const mins = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${hrs}h ${mins}m ${secs}s`;
    };

    return (
        <div style={{ textAlign: 'center' }}>
            <h1>react-timer-hook</h1>
            <p>Total Elapsed Time</p>

            {/* Display total elapsed time */}
            <div style={{ fontSize: '40px' }}>
                <span>{sessionDays + lunchDays + teaDays + whDays}</span>:<span>{sessionHours + lunchHours + teaHours + whHours}</span>:<span>{sessionMinutes + lunchMinutes + teaMinutes + whMinutes}</span>:<span>{sessionSeconds + lunchSeconds + teaSeconds + whSeconds}</span>
            </div>

            {/* Start buttons for session, lunch, tea, wh */}
            <div style={{ margin: '20px' }}>
                <button onClick={() => handleSwitchTimer('session')}>Session</button>
                <button onClick={() => handleSwitchTimer('lunch')}>Lunch</button>
                <button onClick={() => handleSwitchTimer('tea')}>Tea</button>
                <button onClick={() => handleSwitchTimer('wh')}>WH</button>
            </div>

            {/* Display total durations */}
            <div style={{ margin: '20px' }}>
                <p>Total Durations</p>
                <p>Session: {formatTime(sessionDuration)}</p>
                <p>Lunch: {formatTime(lunchDuration)}</p>
                <p>Tea: {formatTime(teaDuration)}</p>
                <p>WH: {formatTime(whDuration)}</p>
            </div>

            {/* Start/Pause button */}
            <div style={{ margin: '20px' }}>
                <button onClick={handleStart}>{activeTimer !== 'session' ? 'Start Timer' : (isSessionRunning ? 'Pause' : 'Start')}</button>
            </div>
        </div>
    );
};

export default MyStopwatch;
