import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { format } from "date-fns";
import { getApi, postApi, deleteApi } from "../../helpers/api"; // Adjust the import path according to your project structure
import { Link } from "react-router-dom";
import moment from 'moment';

const CustomCalendar = () => {
  const [value, setValue] = useState(new Date());
  const [holidays, setHolidays] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  const getLeave = async () => {
    try {
      const { data } = await getApi(`get-multi-leave`, true);
      const result = data.reduce((acc, { id, start_date, reason }) => {
        acc[start_date] = { id, reason };
        return acc;
      }, {});
      setHolidays(result);
    } catch (error) {
      console.error(error);
    }
  };

  const getYearFromDate = (date) => {
    const year = new Date(date).getFullYear();
    return year;
  };

  useEffect(() => {
    getLeave();
  }, []);

  const handleDateClick = (date) => {
    const dateString = format(date, "yyyy-MM-dd");
    if (!holidays[dateString]) {
      setHolidays((prev) => ({
        ...prev,
        [dateString]: { reason: "" },
      }));
    }
  };

  const handleReasonChange = (date, reason) => {
    setHolidays((prev) => ({
      ...prev,
      [date]: { ...prev[date], reason },
    }));
  };

  const handleDelete = async (date) => {    
    try {
      let newData = {
      start_date: date,
    };
      const response = await postApi("delete-leave", newData);
      if (response.status === 200) {
        getLeave();
        setErrorMessage("");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSubmit = async () => {
    const formattedData = Object.keys(holidays)
      .filter((date) => holidays[date].reason.trim() !== "")
      .map((date) => ({
        start_date: date,
        end_date: date,
        is_company_leave: true,
        reason: holidays[date].reason,
      }));

    if (formattedData.length === 0) {
      setErrorMessage("Please provide reasons for all selected holidays.");
      return;
    }

    try {
      const response = await postApi("add-multi-leave", formattedData, true);
      if (response.status === 200) {
        getLeave();
        setErrorMessage("");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0"); // Ensures two-digit day
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Ensures two-digit month
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const getThirdSaturdaysOfYear =async (year) => {  
    const thirdSaturdays = [];

    // Iterate through each month of the year
    for (let month = 0; month < 12; month++) {
      // Create a moment object for the first of the current month
      let firstOfMonth = moment([year, month, 1]);

      // Find the first Saturday of the month
      let firstSaturday = firstOfMonth.day(6); // 6 is the index for Saturday in Moment.js

      // Add 14 days to get the third Saturday
      let thirdSaturday = firstSaturday.add(14, "days");

      // Format the date and push it to the array
      thirdSaturdays.push(thirdSaturday.format("YYYY-MM-DD"));
    }    
    const formattedDates = thirdSaturdays.map((date) => ({
      start_date: date,
      reason: "3rd Saturday",
      end_date: date,
      is_company_leave: true,
    }));    
    try {
      const response = await postApi("add-multi-leave", formattedDates, true);
      if (response.status === 200) {
        getLeave();
        setErrorMessage("");
      }
    } catch (error) {
      console.error("Error:", error);
    }
    return thirdSaturdays;
  };

  return (
    <div className="d-flex justify-content-between gap-4">
      <div className="calendar-container">
        <Calendar
          onChange={setValue}
          value={value}
          onClickDay={handleDateClick}
          tileClassName={({ date }) => {
            const dateString = format(date, "yyyy-MM-dd");
            return holidays[dateString] ? "holiday" : "";
          }}
        />
      </div>
      {Object.keys(holidays).length > 0 && (
        <div className="w-50">
          <form className="reason-form" onSubmit={(e) => e.preventDefault()}>
            <div className="text-end">
              <button
                className="btn btn-dark"
                onClick={() => getThirdSaturdaysOfYear(getYearFromDate(value))}
              >
                Add Saturday
              </button>
            </div>
            <table className="reason-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Reason</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(holidays).map((date) => (
                  <tr key={date}>
                    <td>{formatDate(date)}</td>
                    <td>
                      <input
                        type="text"
                        value={holidays[date].reason || ""}
                        onChange={(e) =>
                          handleReasonChange(date, e.target.value)
                        }
                        placeholder="Enter Reason"
                        className="w-100"
                      />
                    </td>
                    <td className="text-center">
                      <button
                        type="button"
                        onClick={() => handleDelete(date)}
                        className="btn btn-danger"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {errorMessage && <p className="error">{errorMessage}</p>}
            <button
              type="button"
              className="btn btn-primary mt-3"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default CustomCalendar;

// import React, { useEffect, useState } from "react";
// import Table from "react-bootstrap/Table";
// import {
//   format,
//   startOfMonth,
//   endOfMonth,
//   eachDayOfInterval,
//   isSameMonth,
//   isSameDay,
//   startOfWeek,
//   addDays,
//   subMonths,
//   addMonths,
//   setYear,
//   getDay,
// } from "date-fns";
// import { getApi, postApi } from "../../helpers/api";

// function Calendar() {
//   const [value, setValue] = useState([]);
//   const [currentMonth, setCurrentMonth] = useState(new Date());
//   const [selectedDates, setSelectedDates] = useState({});
//   const [holidays, setHolidays] = useState({});
//   const [isOpen, setIsOpen] = useState(true);
//   const [errorMessage, setErrorMessage] = useState("");

//   const years = Array.from(
//     new Array(10),
//     (_, index) => new Date().getFullYear() - 5 + index
//   );

//   const getLeave = async () => {
//     try {
//       const { data } = await getApi(`get-multi-leave`, true);
//       setValue(data);
//       const result = data.reduce((acc, { start_date, reason }) => {
//         acc[start_date] = reason;
//         return acc;
//       }, {});
//       setHolidays(result);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   useEffect(() => {
//     getLeave();
//   }, []);

//   const handleDateClick = (date) => {
//     const dateString = format(date, "yyyy-MM-dd");
//     if (holidays[dateString]) {
//       const newHolidays = { ...holidays };
//       delete newHolidays[dateString];
//       setHolidays(newHolidays);
//     } else if (selectedDates[dateString]) {
//       const newDates = { ...selectedDates };
//       delete newDates[dateString];
//       setSelectedDates(newDates);
//     } else {
//       setHolidays((prev) => ({ ...prev, [dateString]: "" }));
//     }
//   };

//   const handleReasonChange = (date, reason) => {
//     setHolidays((prev) => ({
//       ...prev,
//       [date]: reason,
//     }));
//   };

//   const handleDelete = (date) => {
//     const newHolidays = { ...holidays };
//     delete newHolidays[date];
//     setHolidays(newHolidays);
//   };

//   const handleDeleteLeave = async (date) => {
//     let newData = {
//       start_date: date,
//     };
//     try {
//       const response = await postApi("delete-leave", newData);
//       if (response.status === 200) {
//         setIsOpen(!response.data.length);
//         getLeave();
//       }
//     } catch (error) {
//       console.error("Error:", error);
//     }
//   };

//   const handleSubmit = async () => {
//     const formattedData = Object.keys(holidays)
//       .filter((date) => holidays[date].trim() !== "")
//       .map((date) => ({
//         start_date: date,
//         end_date: date,
//         is_company_leave: true,
//         reason: holidays[date],
//       }));

//     if (formattedData.length === 0) {
//       setErrorMessage("Please provide reasons for all selected holidays.");
//       return;
//     }

//     try {
//       const response = await postApi("add-multi-leave", formattedData, true);
//       if (response.status === 200) {
//         getLeave();
//         setIsOpen(!response.data.length);
//         setErrorMessage("");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//     }
//   };

//   const renderDaysOfWeek = () => {
//     const daysOfWeek = [];
//     const startDay = startOfWeek(new Date(), { weekStartsOn: 1 }); // Start the week on Monday
//     for (let i = 0; i < 7; i++) {
//       daysOfWeek.push(
//         <div key={i} className="day-name">
//           {format(addDays(startDay, i), "EEE")}
//         </div>
//       );
//     }
//     return daysOfWeek;
//   };

//   const handlePrevMonth = (e) => {
//     e.preventDefault();
//     setCurrentMonth(subMonths(currentMonth, 1));
//   };

//   const handleNextMonth = (e) => {
//     e.preventDefault();
//     setCurrentMonth(addMonths(currentMonth, 1));
//   };

//   const handleYearChange = (e) => {
//     setCurrentMonth(setYear(currentMonth, parseInt(e.target.value)));
//   };

//   const formatDate = (dateString) => {
//     const date = new Date(dateString);
//     const day = date.getDate().toString().padStart(2, "0"); // Ensures two-digit day
//     const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Ensures two-digit month
//     const year = date.getFullYear();
//     return `${day}-${month}-${year}`;
//   };

//   const renderCalendarDays = () => {
//     const startDay = startOfWeek(startOfMonth(currentMonth), {
//       weekStartsOn: 1,
//     });
//     const endDay = endOfMonth(currentMonth);
//     const days = eachDayOfInterval({ start: startDay, end: endDay });
//     const calendarDays = [];

//     // Fill the first row with days from the previous month if necessary
//     for (let i = 0; i < getDay(startOfMonth(currentMonth)); i++) {
//       calendarDays.push(
//         <div key={`empty-${i}`} className="calendar-day empty" />
//       );
//     }

//     // Fill the days of the current month
//     for (let day of days) {
//       calendarDays.push(
//         <div
//           key={day}
//           onClick={() => handleDateClick(day)}
//           className={`calendar-day ${
//             isSameMonth(day, currentMonth) ? "" : "disabled"
//           } ${isSameDay(day, new Date()) ? "today" : ""} ${
//             holidays[format(day, "yyyy-MM-dd")] ? "holiday" : ""
//           } ${selectedDates[format(day, "yyyy-MM-dd")] ? "selected" : ""}`}
//         >
//           {format(day, "d")}
//         </div>
//       );
//     }

//     return calendarDays;
//   };

//   return (
//     <div className="d-flex justify-content-between gap-4">
//       <div className="calendar-container">
//         <div className="calendar-header">
//           <button onClick={handlePrevMonth}>
//             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
//               <path
//                 fill="currentColor"
//                 d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
//               />
//             </svg>
//           </button>
//           <div className="d-flex justify-content-between w-75 align-items-center">
//             <span className="month-title">{format(currentMonth, "MMMM")}</span>
//             <select
//               className="date-select"
//               value={currentMonth.getFullYear()}
//               onChange={handleYearChange}
//             >
//               {years.map((year) => (
//                 <option key={year} value={year}>
//                   {year}
//                 </option>
//               ))}
//             </select>
//           </div>
//           <button onClick={handleNextMonth}>
//             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
//               <path
//                 fill="currentColor"
//                 d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"
//               />
//             </svg>
//           </button>
//         </div>
//         <div className="calendar-grid">
//           {renderDaysOfWeek()}
//           {renderCalendarDays()}
//         </div>
//       </div>
//       {isOpen && Object.keys(holidays).length > 0 ? (
//         <form className="reason-form w-50">
//           {Object.keys(holidays).length > 0 && (
//             <div>
//               <table className="reason-table">
//                 <thead>
//                   <tr>
//                     <th>Date</th>
//                     <th>Reason</th>
//                     <th>Actions</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {Object.keys(holidays).map((date) => (
//                     <tr key={date}>
//                       <td>{formatDate(date)}</td>
//                       <td>
//                         <input
//                           type="text"
//                           value={holidays[date] || ""}
//                           onChange={(e) =>
//                             handleReasonChange(date, e.target.value)
//                           }
//                           placeholder="Enter Reason"
//                           className="w-100"
//                         />
//                       </td>
//                       <td className="text-center">
//                         <button
//                           type="button"
//                           onClick={() => handleDelete(date)}
//                           className="delete-button iconWrap"
//                         >
//                           <svg
//                             xmlns="http://www.w3.org/2000/svg"
//                             viewBox="0 0 448 512"
//                           >
//                             <path
//                               fill="currentColor"
//                               d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"
//                             />
//                           </svg>
//                         </button>
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </div>
//           )}
//           {errorMessage && <p className="error">{errorMessage}</p>}
//           {Object.keys(holidays).length > 0 && (
//             <button
//               type="button"
//               className="submit-button"
//               onClick={handleSubmit}
//             >
//               Submit
//             </button>
//           )}
//         </form>
//       ) : value.length > 0 ? (
//         <div className="leave-list w-50">
//           <div className="d-flex justify-content-between align-items-center">
//             <h5 className="">Holidays List</h5>
//             <button
//               className="submit-button m-0"
//               onClick={() => setIsOpen(true)}
//             >
//               Add/Edit Leave
//             </button>
//           </div>
//           <Table className="table table-striped">
//             <thead>
//               <tr>
//                 <th>Date</th>
//                 <th>List</th>
//               </tr>
//             </thead>
//             <tbody>
//               {value.map((item, index) => (
//                 <tr key={index}>
//                   <td>{formatDate(item.start_date)}</td>
//                   <td>{item.reason}</td>
//                   <td className="text-center">
//                     <button
//                       type="button"
//                       onClick={() => handleDeleteLeave(item.start_date)}
//                       className="delete-button iconWrap"
//                     >
//                       <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         viewBox="0 0 448 512"
//                       >
//                         <path
//                           fill="currentColor"
//                           d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"
//                         />
//                       </svg>
//                     </button>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </Table>
//         </div>
//       ) : null}
//     </div>
//   );
// }

// export default Calendar;
