import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import { Modal } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { getApi, postApi } from "../../helpers/api";
import "./project.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useDispatch, useSelector } from "react-redux";
import { NavDropdown } from "react-bootstrap";
import { ReactComponent as SprintSvg } from "../../images/sprint.svg";
const Profile = () => {
  const [IsPending, setPending] = useState(false);
  const user = useSelector((state) => state.user.user);
  const userPermission = JSON.parse(user.permission);
  //  ---- Projetc Data useState()
  const [serachRes, setserachRes] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [projectData, setProjectData] = useState([]);
  const [category, setCategory] = useState();
  const [msg, setMsg] = useState(" Not Found.");
  const [isUpdate, setIsUpadte] = useState(false);
  const [upid, setUpid] = useState(0);
  // ----
  // ---- Create Project Form Data --
  const animatedComponents = makeAnimated();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [isLoding, setIsLoding] = useState(true);
  const [projectCategory, setProjectCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [tlData, setTlData] = useState("");
  const [tmData, setTmData] = useState("");
  const [selectedTl, setSelectedTl] = useState([]);
  const [selectedTm, setSelectedTm] = useState([]);
  const [empArray, setEmpArray] = useState([]);

  const [uploadEmployee, setUploadEmployee] = useState(
    "./images/default-image.png"
  );
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
  // ----
  const successNotify = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

  const errorNotify = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
      theme: "colored",
    });

  useEffect(() => {
    getAllProject(currentPage);
  }, [currentPage, serachRes, category]);

  useEffect(() => {
    if (user?.userRole?.role_name !== "admin" && !userPermission?.project_r) {
      dispatch({ type: "LOGOUT", payload: null });
      localStorage.removeItem(process.env.REACT_APP_SECRET);
      navigate("/");
    } else {
      getAllProject(currentPage);
      getAllProjectCategory();
      getAllEmp();
    }
  }, [selectedCategory, selectedTl, selectedTm]);

  async function getAllProject(page) {
    !isLoding && setIsLoding(true);
    let ProjectUrl =
      serachRes && page
        ? `project?search=${serachRes}&pageCount=${page}`
        : category && page
        ? `project?category=${category}&pageCount=${page}`
        : `project?pageCount=${page}`;

    await getApi(ProjectUrl, true)
      .then((res) => {
        if (res.status === 200) {
          let newData = [...res.data];
          setProjectData(newData);
        }
        if (res.status === 403) {
          setMsg(res.data);
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  }

  async function getAllProjectCategory() {
    await getApi("project-category", true).then((res) => {
      const convertedData = res.data.map((item) => ({
        value: item.id,
        label: item.category_name,
      }));
      // setProjectCategory(convertedData);
      let data = filterData(convertedData, selectedCategory);
      setProjectCategory(data);
    });
  }

  async function getAllEmp() {
    let emptyArray = [];
    await getApi("my-employee", true).then((res) => {
      let newEmpArray = [];
      res.data?.map((val, key) => {
        newEmpArray.push({ value: val.id, label: val.fullname });
      });
      let data = filterData(newEmpArray, selectedTl, selectedTm);
      setEmpArray(data);
    });
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const addProjectModel = () => {
    setShow(true);
  };

  const fileChangeHandler = (event) => {
    setFieldValue("project_logo", event.target.files[0]);
    if (event.target.files && event.target.files.length) {
      setUploadEmployee(URL.createObjectURL(event.target.files[0]));
    }
  };

  async function closeMenu() {
    setShow(false);
    setUploadEmployee("./images/default-image.png");
    resetForm();
  }

  const [initialValues, setInitialValues] = useState({
    project_name: "",
    category_id: "",
    description: "",
    start_date: "",
    end_date: "",
    tl: "",
    tm: "",
    project_logo: "",
  });
  const notifyError = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

  const notifySuccess = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

  const validationSchema = yup.object({
    project_name: yup.string().required("Project is required."),
    category_id: yup.string().required("Category is required."),
    description: yup.string().required("Project Description is required."),
    start_date: yup.string().required("Start Date is required."),
    project_logo: yup
      .mixed()
      .test("size", "Upload less than 1 MB file.", (value) => {
        if (initialValues.project_logo) {
          return true;
        } else if (value && value.size >= 1048576) {
          return false;
        }
        return true;
      })
      .test("type", "Upload only .jpg, .jpeg, .png", (value) => {
        if (initialValues.project_logo) {
          return true;
        } else if (value && !SUPPORTED_FORMATS.includes(value.type)) {
          return false;
        }
        return true;
      })
      .nullable(true),
  });
  const deleteApp = async (id) => {
    await getApi(`delete-project?id=${id}`, true)
      .then((res) => {
        if (res.status === 200) {
          getAllProject(currentPage);
          notifyError(res.message);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const filterData = (data, ...excludeArrays) => {
    // Extracting values from each exclude array and parsing them to integers
    const excludeValues = excludeArrays
      .flat()
      .map((item) => parseInt(item.value));

    // Filtering the data array to exclude entries with values in excludeValues
    const filteredData = data.filter((entry) => {
      return !excludeValues.includes(entry.value);
    });

    return filteredData;
  };

  const editProject = async (id) => {
    setIsUpadte(true);
    setUpid(id);
    await getApi(`get-project?id=${id}`, true)
      .then((res) => {
        if (res.status === 200) {
          //  getAllProject(currentPage);
          setInitialValues(res.data);
          setSelectedCategory(res.data.category);
          setSelectedTl(res.data.teamLeader);
          setSelectedTm(res.data.teammember);
          addProjectModel();
          setUploadEmployee(
            `${process.env.REACT_APP_BASE_URL_UPLOAD}projectimages/${res.data.project_logo}`
          );
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      let newValues = { ...values };
      let tlArray = [];
      let tmArray = [];
      if (newValues.project_logo === "") {
        delete newValues.project_logo;
      }
      if (tlData !== "") {
        tlData.map((val, key) => {
          tlArray.push(val.value);
        });
        newValues.tl = tlArray.toString();
      }
      if (tmData !== "") {
        tmData.map((val, key) => {
          tmArray.push(val.value);
        });
        newValues.tm = tmArray.toString();
      }
      setPending(true);
      let url = isUpdate ? `edit-project?id=${upid}` : "add-project";
      await postApi(url, newValues, true, true).then((res) => {
        let result = { ...res };
        if (result.status === 200) {
          getAllProject(currentPage);
          setPending(false);
          successNotify(result.message);
          closeMenu();
          resetForm();
          setSelectedCategory([]);
          setSelectedTm([]);
          setSelectedTl([]);
        } else {
          setPending(false);
          errorNotify("Oops, Something went wrong.");
          errors.project = result.data;
        }
      });
    },
  });
  const toggleSwitch = async (id, currentStatus) => {
    let newData = {
      status: currentStatus,
    };
    await postApi(`status-project?id=${id}`, newData, true).then((res) => {
      if (res.status === 200) {
        successNotify(res.message);
        getAllProject(currentPage);
      }
    });
  };
  return (
    <>
      <ToastContainer />
      <div className="content-header">
        <p className="breadcrumb">
          <Link to={"/dashboard"} className="back-link">
            Dashboard
          </Link>
          /<span className="active">All Projects</span>
        </p>
        <div className="content-header-right">
          <div className="content-header-search">
            <span className="search-icon">
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 19.5C15.4183 19.5 19 15.9183 19 11.5C19 7.08172 15.4183 3.5 11 3.5C6.58172 3.5 3 7.08172 3 11.5C3 15.9183 6.58172 19.5 11 19.5Z"
                  stroke="#575A74"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M20.9999 21.4999L16.6499 17.1499"
                  stroke="#575A74"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <input
              type="search"
              value={serachRes}
              placeholder="Project name searching..."
              onChange={(search) => setserachRes(search.target.value)}
            />
          </div>
          <select onChange={(category) => setCategory(category.target.value)}>
            <option value={""}>Project Category</option>
            {projectCategory?.map((val, key) => {
              return (
                <option value={val.value}>
                  <div className="p-5">{val.label}</div>
                </option>
              );
            })}
          </select>
          {(user.userRole.role_name === "admin" ||
            userPermission?.project_c) && (
            <button className="theme-btn-primary" onClick={addProjectModel}>
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_86_9054)">
                  <path
                    d="M8 2.5C12.8 2.5 14 3.7 14 8.5C14 13.3 12.8 14.5 8 14.5C3.2 14.5 2 13.3 2 8.5C2 3.7 3.2 2.5 8 2.5Z"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10 8.5H6"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8 6.5V10.5"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_86_9054">
                    <rect
                      width="16"
                      height="16"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
              &nbsp;&nbsp;Create Project
            </button>
          )}
        </div>
      </div>
      <table className="table-list">
        <thead>
          <tr>
            {/* <td>id</td> */}
            <td>Project</td>
            <td>Category</td>
            <td>Team Leader</td>
            <td>Team Member</td>
            {user?.userRole?.role_name === "admin" ? <td>Status</td> : null}
            <td></td>
          </tr>
        </thead>
        <tbody>
          {projectData?.length ? (
            projectData.map((val, key) => {
              return (
                <tr>
                  {/* <td>{val.id}</td> */}
                  <td><Link to={`/add-sprint/${val.id}`} className="text-dark">{val.project_name}</Link></td>
                  <td>{val.categories}</td>
                  <td>{val.teamleader}</td>
                  <td>{val.teamember}</td>
                  {user?.userRole?.role_name === "admin" ? (
                    <td>
                      <div className="button-app" style={{ float: "none" }}>
                        <div className="button r" id="button-1">
                          <input
                            type="checkbox"
                            className="checkbox"
                            defaultChecked={val.status}
                            checked={val.success}
                            onChange={(status) => {
                              toggleSwitch(val.id, status.target.checked);
                            }}
                          />
                          <div className="knobs"></div>
                          <div className="layer"></div>
                        </div>
                      </div>
                    </td>
                  ) : null}
                  <td className="text-center table-actions-menu">
                    <NavDropdown title="Action">
                      {(user.userRole.role_name === "admin" ||
                        userPermission.project_e) && (
                        <NavDropdown.Item onClick={() => editProject(val.id)}>
                          <div className="action-btn">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_80_7131)">
                                <path
                                  d="M4 19.9998H8L18.5 9.49981C18.7626 9.23717 18.971 8.92537 19.1131 8.58221C19.2553 8.23905 19.3284 7.87125 19.3284 7.49981C19.3284 7.12838 19.2553 6.76058 19.1131 6.41742C18.971 6.07426 18.7626 5.76246 18.5 5.49981C18.2374 5.23717 17.9256 5.02883 17.5824 4.88669C17.2392 4.74455 16.8714 4.67139 16.5 4.67139C16.1286 4.67139 15.7608 4.74455 15.4176 4.88669C15.0744 5.02883 14.7626 5.23717 14.5 5.49981L4 15.9998V19.9998Z"
                                  stroke="#575A74"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M13.5 6.5L17.5 10.5"
                                  stroke="#575A74"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M16 19H22"
                                  stroke="#575A74"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_80_7131">
                                  <rect width="24" height="24" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <div className="action-text">Edit</div>
                          </div>
                        </NavDropdown.Item>
                      )}
                      {/* {(user.userRole.role_name === "admin" ||
                        userPermission.project_e) && ( */}
                      <NavDropdown.Item
                      //  onClick={() => deleteApp(appval.id)}
                      >
                        <Link to={`/add-sprint/${val.id}`}>
                          <div className="action-btn">
                            <SprintSvg />
                            <div className="action-text">Sprint</div>
                          </div>
                        </Link>
                      </NavDropdown.Item>
                      {/* )} */}
                      {(user.userRole.role_name === "admin" ||
                        userPermission.project_d) && (
                        <NavDropdown.Item onClick={() => deleteApp(val.id)}>
                          <div className="action-btn">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_146_525)">
                                <path
                                  d="M4 7H20"
                                  stroke="#575A74"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M10 11V17"
                                  stroke="#575A74"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M14 11V17"
                                  stroke="#575A74"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M5 7L6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19L19 7"
                                  stroke="#575A74"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M9 7V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7"
                                  stroke="#575A74"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_146_525">
                                  <rect width="24" height="24" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <div className="action-text">Delete</div>
                          </div>
                        </NavDropdown.Item>
                      )}
                    </NavDropdown>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={5} className="text-center theme-text-danager">
                {msg}
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <Modal
        size="lg"
        show={show}
        onHide={closeMenu}
        aria-labelledby="employee-added"
        // className="employee-model"
      >
        <Modal.Header closeButton>
          <Modal.Title id="employee-added">Create Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="employee-information">
            <form
              onSubmit={handleSubmit}
              autoComplete="off"
              encType="multipart/form-data"
            >
              <div className="row">
                <div className="col-md-12">
                  {errors.project_logo && touched.project_logo ? (
                    <span className="required-lable mt-2 d-block text-center">
                      {errors.project_logo}{" "}
                    </span>
                  ) : null}
                  <div className="employee-upload-profile mt-4">
                    <img src={uploadEmployee} alt="employee upload." />
                    <div className="image-upload-btn">
                      <label htmlFor="image-upload">Upload</label>
                      <input
                        type="file"
                        name="project_logo"
                        className="d-none"
                        id="image-upload"
                        onChange={(e) => fileChangeHandler(e)}
                        accept="image/png, image/jpeg"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mt-0">
                    <label>
                      Project<span className="required-lable">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Project"
                      name="project_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.project_name}
                    />
                    {errors.project_name && touched.project_name ? (
                      <span className="required-lable">
                        {errors.project_name}{" "}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mt-0">
                    <label>
                      Category<span className="required-lable">*</span>
                    </label>
                    <Select
                      isMulti
                      // defaultValue={selectedOption}
                      onChange={(e) => {
                        setSelectedCategory(e);
                        setFieldValue(
                          "category_id",
                          e.map((item) => item.value).toString()
                        );
                      }}
                      options={projectCategory}
                      value={selectedCategory}
                      name="project"
                      required
                    />
                    {errors.category_id && touched.category_id ? (
                      <span className="required-lable">
                        {errors.category_id}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mt-0">
                    <label>
                      Start Date<span className="required-lable">*</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Start Date"
                      name="start_date"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.start_date}
                    />
                    {errors.start_date && touched.start_date ? (
                      <span className="required-lable">
                        {errors.start_date}{" "}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mt-0">
                    <label>End Date</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="End Date"
                      name="end_date"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.end_date}
                    />
                    {errors.end_date && touched.end_date ? (
                      <span className="required-lable">{errors.end_date} </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group m-0">
                    <label>Add Project Leader</label>
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      options={empArray}
                      onChange={(e) => {
                        setSelectedTl(e);
                        setTlData(e);
                      }}
                      value={selectedTl}
                    />
                    {errors.tl && touched.tl ? (
                      <span className="required-lable">{errors.tl} </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Add Project Member</label>
                    <Select
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      options={empArray}
                      onChange={(e) => {
                        setSelectedTm(e);
                        setTmData(e);
                      }}
                      value={selectedTm}
                    />
                    {errors.tm && touched.tm ? (
                      <span className="required-lable">{errors.tm} </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group mt-0">
                    <label>
                      Description<span className="required-lable">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      placeholder="Project Description..."
                      name="description"
                      rows={3}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                    />
                    {errors.description && touched.description ? (
                      <span className="required-lable">
                        {errors.description}{" "}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-12">
                  <button
                    type="submit"
                    className="theme-btn-primary d-block m-auto mb-4"
                  >
                    {isUpdate ? "Update Project" : "Add Project"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Profile;
