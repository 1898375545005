import axios from "axios";

const postApi = async (url, values, headers = false, content_type = false) => {
  let postData;
  if (headers === true && content_type === false) {
    await axios
      .post(process.env.REACT_APP_BASE_URL + url, values, {
        headers: {
          "x-access-token": localStorage.getItem(
            process.env.REACT_APP_SECRET || ""
          ),
        },
      })
      .then((res) => {
        postData = {
          status: 200,
          data: res.data.data,
          message: res.data.message,
        };
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/";
        } else if (error.response.status === 422) {
          postData = {
            status: error.response.status,
            data: error.response.data,
          };
        } else {
          postData = {
            status: error.response.status,
            data: error.response.data.message,
          };
        }
      });
  } else if (content_type === true && headers === true) {
    await axios
      .post(process.env.REACT_APP_BASE_URL + url, values, {
        headers: {
          "x-access-token": localStorage.getItem(
            process.env.REACT_APP_SECRET || ""
          ),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        postData = {
          status: 200,
          data: res.data.data,
          message: res.data.message,
        };
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/";
        } else {
          postData = {
            status: error.response.status,
            data: error.response.data.message,
          };
        }
      });
  } else {
    await axios
      .post(process.env.REACT_APP_BASE_URL + url, values)
      .then((res) => {
        postData = {
          status: 200,
          data: res.data.data,
          message: res.data.message,
        };
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/";
        } else if (error.response.status === 422) {
          postData = {
            status: error.response.status,
            data: error.response.data,
          };
        } else {
          postData = {
            status: error.response.status,
            data: error.response.data.message,
          };
        }
      });
  }
  return postData;
};

const getApi = async (url, headers = false) => {
  let getData;
  if (headers === false) {
    await axios
      .get(process.env.REACT_APP_BASE_URL + url)
      .then((res) => {
        getData = {
          status: 200,
          data: res.data.data,
          message: res.data.message,
        };
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/";
        } else if (error.response.status === 403) {
          getData = { status: 403, data: error.response.data.message };
        } else {
          getData = { status: 404, data: error.response.data.message };
        }
      });
  } else {
    await axios
      .get(process.env.REACT_APP_BASE_URL + url, {
        headers: {
          "x-access-token": localStorage.getItem(
            process.env.REACT_APP_SECRET || ""
          ),
        },
      })
      .then((res) => {
        getData = {
          status: 200,
          data: res.data.data,
          message: res.data.message,
        };
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.href = "/";
        } else if (error.response.status === 403) {
          getData = { status: 403, data: error.response.data.message };
        } else {
          getData = { status: 404, data: error.response.data.message };
        }
      });
  }
  return getData;
};

export { postApi, getApi };

// import axios from "axios";

// export const postApi = async (url, values, headers = null) => {
//   let postData;
//   if (headers === null) {
//     await axios
//       .post(process.env.REACT_APP_BASE_URL + url, values)
//       .then((res) => {
//         postData = {
//           status: 200,
//           data: res.data.data,
//           message: res.data.message,
//         };
//       })
//       .catch((error) => {
//         postData = { status: 404, data: error.response.data.message };
//       });
//   } else {
//     await axios
//       .post(process.env.REACT_APP_BASE_URL + url, values, {
//         headers: {
//           "x-access-token": headers,
//         },
//       })
//       .then((res) => {
//         postData = {
//           status: 200,
//           data: res.data.data,
//           message: res.data.message,
//         };
//       })
//       .catch((error) => {
//         postData = { status: 404, data: error.response.data.message };
//       });
//   }
//   return postData;
// };

// export const getApi = async (url, headers = null) => {
//   let getData;
//   if (headers === null) {
//     await axios
//       .get(process.env.REACT_APP_BASE_URL + url)
//       .then((res) => {
//         getData = {
//           status: 200,
//           data: res.data.data,
//           message: res.data.message,
//           dataCount: res.data.count,
//         };
//       })
//       .catch((error) => {
//         getData = { status: 404, data: error.response.data.message };
//       });
//   } else {
//     await axios
//       .get(process.env.REACT_APP_BASE_URL + url, {
//         headers: {
//           "x-access-token": headers,
//         },
//       })
//       .then((res) => {
//         getData = {
//           status: 200,
//           data: res.data.data,
//           message: res.data.message,
//           dataCount: res.data.count,
//         };
//       })
//       .catch((error) => {
//         getData = { status: 404, data: error.response.data.message };
//       });
//   }
//   return getData;
// };
