import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { getApi, postApi } from "../../helpers/api";
import { Calendar } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import weekends from "react-multi-date-picker/plugins/highlight_weekends";
import "./setting.css";

function Category() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  // ---- Roles userData --
  const [pendingRole, setPendingRole] = useState(false);
  const [role, setRole] = useState([]);
  // ------

  // ---- Leave userData --
  const [leaveData, setLeaveData] = useState([]);
  const [pendingLeave, setPendingLeave] = useState(false);
  // ------

  // ---- Category userData --
  const [categoryData, setCategory] = useState("");
  const [categoryDataError, setCategoryError] = useState(false);
  const [pendingCategory, setPendingCategory] = useState(false);
  const [dataCategory, setDataCategory] = useState([]);
  // ------

  useEffect(() => {
    if (user.userRole.role_name === "admin") {
      getRole();
      getLeave();
      getCategory();
    } else {
      dispatch({ type: "LOGOUT", payload: null });
      localStorage.removeItem(process.env.REACT_APP_SECRET);
      navigate("/");
    }
  }, []);

  const successNotify = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

  const errorNotify = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

  const getRole = async () => {
    await getApi(`my-role`, true)
      .then(({ data }) => {
        setRole(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getLeave = async () => {
    await getApi(`leave`, true)
      .then(({ data }) => {
        setLeaveData(data.leave);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validationSchema = yup.object({
    role_name: yup.string().required("Role is required."),
  });

  const [initialValues, setInitialValues] = useState({
    role_name: "",
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    resetForm,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      setPendingRole(true);
      await postApi("add-role", values)
        .then((response) => {
          getRole();
          successNotify("A new role has been added.");
          setPendingRole(false);
          resetForm();
        })
        .catch((error) => {
          setPendingRole(false);
        });
    },
  });

  const leavesetting = async () => {
    setPendingLeave(true);
    let newLeaveDate = [...leaveData];
    await postApi({
      method: "post",
      url: `${
        process.env.REACT_APP_BASE_URL
      }add-leave?id=${"653ce951a0a1c7888b73248e"}`,
      data: { leave: newLeaveDate },
    })
      .then((response) => {
        successNotify("A new leave has been added.");
        getLeave();
        setPendingLeave(false);
      })
      .catch((error) => {
        console.log(error);
        setPendingLeave(false);
      });
  };

  const deleteRole = async (id) => {
    await getApi(`delete-role?role=${id}`, true)
      .then((res) => {
        if (res.status === 200) {
          getRole();
          errorNotify(res.message);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const getCategory = async () => {
    await getApi(`category`, true)
      .then(({ data }) => {
        setDataCategory(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addCategory = async () => {
    if (categoryData !== "") {
      setCategoryError(false);
      let values = { category_name: categoryData };
      await postApi("add-product-category", values)
        .then((response) => {
          successNotify("A new category has been added.");
          setCategory("");
          getCategory();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setCategoryError(true);
    }
  };

  const deleteCategory = async (id) => {
    await getApi(`delete-product-category?category=${id}`)
      .then((res) => {
        if (res.status === 200) {
          getCategory();
          errorNotify(res.message);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  return (
    <>
      <ToastContainer />
      <div className="row">
        <div className="col-6">
          <div className="settings">
            <form autoComplete="off">
              <div className="row">
                <div className="col-md-12">
                  <h3 className="text-center mb-24">Project Category</h3>
                </div>
                <div className="col-md-12">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        {/* <th>#</th> */}
                        <th>Category</th>
                        <th className="text-center">Count of Product</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataCategory?.length ? (
                        dataCategory.map((val, key) => {
                          return (
                            <tr key={key}>
                              {/* <td>{val.id}</td> */}
                              <td
                                style={{ textTransform: "capitalize" }}                                
                              >
                                {val.category_name}
                              </td>
                              <td className="text-center">
                                {val.number_of_project}
                              </td>
                              <td className="text-center">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  onClick={() => {
                                    deleteCategory(val.id);
                                  }}
                                >
                                  <g clipPath="url(#clip0_146_525)">
                                    <path
                                      d="M4 7H20"
                                      stroke="#FF5636"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M10 11V17"
                                      stroke="#FF5636"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M14 11V17"
                                      stroke="#FF5636"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M5 7L6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19L19 7"
                                      stroke="#FF5636"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M9 7V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7"
                                      stroke="#FF5636"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_146_525">
                                      <rect
                                        width="24"
                                        height="24"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td
                            colSpan={4}
                            className="text-center theme-text-danager"
                          >
                            Not Found.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="Enter Project Category"
                      name="category"
                      onChange={(e) => {
                        setCategory(e.target.value);
                      }}
                      value={categoryData}
                    />
                    {categoryDataError && (
                      <span className="required-lable">
                        category is required.
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </form>
            <div className="add-submit mt-0">
              <button
                type="submit"
                className="theme-btn-primary"
                onClick={addCategory}
                disabled={pendingCategory ? true : false}
              >
                {pendingCategory ? "Loding..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Category;
