import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import "./profile.css";
import { ReactComponent as UserSvg } from "../../images/user.svg";
const Profile = () => {
  const user = useSelector((state) => state.user.user);
  return (
    <>
      <ToastContainer />
      <section className="content user-profile">
            <div className="profile-tab">
              {/* <p className="profile-head">Personals Information</p> */}
              <form method="post">
                <div className="row">
                  <div className="col-md-12">
                    <div className="profile-info">
                      <div className="profile-user-avatar">
                        {!user.employee_image ? (
                          <UserSvg />
                        ) : (
                          <img
                            src={`${process.env.REACT_APP_BASE_URL_UPLOAD}employeeimages/${user.employee_image}`}
                            alt="Default Images"
                          />
                        )}
                      </div>
                      <div className="profile-user-info">
                        <p>{user.fullname}</p>
                        <p className="profile-mail">{user.email}</p>
                        <span className="user-position">
                          {user?.userRole?.role_name === "admin"
                            ? "Adminer"
                            : user?.userRole?.role_name}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Full Name</label>
                      <input
                        type="text"
                        placeholder="Enter Full Name"
                        name="fullname"
                        value={user.fullname}
                        disabled={
                          user?.userRole?.role_name === "admin" ? false : true
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="text"
                        placeholder="Enter Email"
                        name="email"
                        value={user.email}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mt-0">
                      <label>Mobile No</label>
                      <input
                        type="text"
                        placeholder="Enter Mobile No"
                        name="mobile_no"
                        value={user.mobile_no}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mt-0">
                      <label>Role</label>
                      <input
                        type="text"
                        value={user?.userRole?.role_name}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          
      </section>
    </>
  );
};

export default Profile;
