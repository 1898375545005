import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import {
  format,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from "date-fns";
import DatePicker from "react-datepicker";
import { useLocation } from "react-router-dom";
import { getApi } from "../../helpers/api";
import Pagination from "../Pagination";
import Modal from "react-bootstrap/Modal";
import { NavDropdown } from "react-bootstrap";
import dot from "../../images/menu.svg"
export default function AttendanceEmployee() {
  const [attendanceData, setAttendanceData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const search = useLocation().search;
  const params = new URLSearchParams(search);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [tasknotes, setTaskNotes] = useState("");
  const [show, setShow] = useState(false);
  const [isLoding, setIsLoding] = useState(true);
  const [type, setType] = useState("");
  const [count, setCount] = useState(0);
  const [todoView, setTodoView] = useState(0);
  const [mtodoView, setMTodoView] = useState(0);
  const [project, setProject] = useState([]);  
  const [showLeaveView, setShowLeaveView] = useState(false);
  const fetchData = async () => {
    let url =
      startDate && endDate
        ? `all-attendance?employee=${params.get(
            "employee"
          )}&sDate=${startDate}&eDate=${endDate}&pageCount=${currentPage}`
        : `all-attendance?employee=${params.get(
            "employee"
          )}&pageCount=${currentPage}`;
    if (type !== null && type !== undefined) url += `&type=${type}`;
    await getApi(url, true).then((res) => {      
      if (res.status === 200) {
        setAttendanceData(res.data.attendance);
        setTotalPages(res.data.totalPages);
        setCount(res.data.count);
        setIsLoding(false);
      }
    });
  };
  useEffect(() => {
    fetchData();
  }, [currentPage, type]);
  const SerachDate = async () => {
    fetchData(currentPage);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = async () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const showMyTask = async (id) => {
    await getApi(`all-attendance?my_task=${id}`, true)
      .then((response) => {
        let taskNote = response.data.attendance[0].todo;
        setTaskNotes(taskNote);
        setShow(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleCalculateDifference = (start, end) => {
    const diffInHours = differenceInHours(end, start);
    const diffInMinutes = differenceInMinutes(end, start) % 60;
    const diffInSeconds = differenceInSeconds(end, start) % 60;

    return {
      hours: diffInHours,
      minutes: diffInMinutes,
      seconds: diffInSeconds,
    };
  };
  return (
    <div>
      <div className="content-header-right">
        <div className="search-date">
          <DatePicker
            showIcon
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            placeholderText="From Date"
            dateFormat="dd MMMM yyyy"
          />
          &nbsp;&nbsp;&nbsp;
          <DatePicker
            showIcon
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            placeholderText="To Date"
            dateFormat="dd MMMM yyyy"
          />
          &nbsp;&nbsp;&nbsp;
          <button
            className="theme-btn-primary py-0"
            onClick={SerachDate}
            disabled={
              startDate === undefined || endDate === undefined ? true : false
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M4 4h16v2.172a2 2 0 0 1 -.586 1.414l-4.414 4.414v7l-6 2v-8.5l-4.48 -4.928a2 2 0 0 1 -.52 -1.345v-2.227z"></path>
            </svg>
          </button>
        </div>
      </div>
      <table className="table-list">
        <thead>
          <tr>
            <th className="attend-filed">Date</th>
            <th className="attend-filed">Check In</th>
            <th className="attend-filed">Lunch Time</th>
            <th className="attend-filed">Tea Time</th>
            <th className="attend-filed">WH Leave Time</th>
            <th className="attend-filed">Check Out</th>
            <th className="attend-filed">Hours</th>
            <th className="table-action">Action</th>
          </tr>
        </thead>
        <tbody>
          {!isLoding ? (
            attendanceData.length ? (
              attendanceData.map((attendval, attendkey) => {
                var checkIn = new Date(attendval.check_in);
                var checkOut =
                  attendval.check_out && new Date(attendval.check_out);
                var checkInLunch =
                  attendval.check_in_lunch &&
                  new Date(attendval.check_in_lunch);
                var checkOutLunch =
                  attendval.check_out_lunch &&
                  new Date(attendval.check_out_lunch);
                var lunchTime = handleCalculateDifference(
                  checkInLunch,
                  checkOutLunch
                );
                var checkInTea =
                  attendval.check_in_tea && new Date(attendval.check_in_tea);

                var checkOutTea =
                  attendval.check_out_tea && new Date(attendval.check_out_tea);
                var TeaTime = handleCalculateDifference(
                  checkInTea,
                  checkOutTea
                );
                var checkInwh =
                  attendval.check_in_wh && new Date(attendval.check_in_wh);

                var checkOutwh =
                  attendval.check_out_wh && new Date(attendval.check_out_wh);
                var whTime = handleCalculateDifference(checkInwh, checkOutwh);
                var date = new Date(attendval.date);
                const formatDuration = (duration) => {
                  if (
                    duration.hours === 0 &&
                    duration.minutes === 0 &&
                    duration.seconds !== 0
                  ) {
                    return `${duration.seconds} Seconds`;
                  } else {
                    return `${duration.hours ? `${duration.hours} Hour ` : ""}${
                      duration.minutes ? `${duration.minutes} Minutes ` : ""
                    }`;
                  }
                };
                if (attendval.is_sunday === 0 &&
                  attendval.is_employee_leave === 0 &&
                  attendval.is_holiday_company === 0) {
                  return (
                    <tr key={attendkey}>
                      <td>{date.toDateString()}</td>
                      <td>
                        {checkIn.toLocaleTimeString("en-US", {
                          hour: "numeric",
                          hour12: true,
                          minute: "numeric",
                        })}
                      </td>
                      <td>
                        {" "}
                        {formatDuration(lunchTime) === ""
                          ? "--"
                          : formatDuration(lunchTime)}
                      </td>
                      <td>
                        {" "}
                        {formatDuration(TeaTime) === ""
                          ? "--"
                          : formatDuration(TeaTime)}
                      </td>
                      <td>
                        {formatDuration(whTime) === ""
                          ? "--"
                          : formatDuration(whTime)}
                      </td>
                      <td>
                        {attendval.check_out
                          ? checkOut.toLocaleTimeString("en-US", {
                              hour: "numeric",
                              hour12: true,
                              minute: "numeric",
                            })
                          : "--"}
                      </td>
                      <td>
                        {attendval.total_hours ? attendval.total_hours : "--"}
                      </td>
                      <td className="text-end">
                        <Dropdown>
                          <Dropdown.Toggle className="dotbtn">
                            <img src={dot} alt="" />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => {
                                setShowLeaveView(true);
                                setTodoView(attendval?.todo);
                                setMTodoView(attendval?.todomorning);
                                setProject(attendval?.projects);
                              }}
                            >
                              View Task
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>                      
                      </td>
                    </tr>
                  );
                } 
                else if (attendval.is_employee_leave === 1){
                  return (
                    <tr>
                      <td>{date.toDateString()}</td>
                      <td colspan="8" className="text-center text-danger">
                        <h5>-- Employee Leave --</h5>
                      </td>
                    </tr>
                  );
                } else if (attendval.is_holiday_company === 1) {
                  return (
                    <tr>
                      <td>{date.toDateString()}</td>
                      <td colspan="8" className="text-center text-success">
                        <h5>-- Company Holiday --</h5>
                      </td>
                    </tr>
                  );
                }
                else {
                  return (
                    <tr>
                      <td>{date.toDateString()}</td>
                      <td colspan="8" className="text-center text-success">
                        <h5>-- Sunday Holiday --</h5>
                      </td>
                    </tr>
                  );
                }
              })
            ) : (
              <tr>
                <td colSpan={6} className="text-center">
                  No Records Found
                </td>
              </tr>
            )
          ) : (
            <tr>
              <td
                colSpan={6}
                className="text-center theme-text-danager position-relative"
              >
                <div className="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {totalPages > 1 ? (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onNext={handleNextPage}
          onPrev={handlePrevPage}
          handlePageChange={handlePageChange}
        />
      ) : null}
      <Modal
        show={showLeaveView}
        size="lg"
        onHide={() => setShowLeaveView(false)}
        aria-labelledby="leave-added-model"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="leave-added-model">View Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              {(todoView === "" ||
                todoView === null ||
                todoView === undefined) &&
              (mtodoView === "" ||
                mtodoView === null ||
                mtodoView === undefined) ? (
                <h5 className="text-center mt-2 mb-2">No Task Found</h5>
              ) : (
                <div className="col-md-12">
                  <h5 className="mb-2">{project.toString()}</h5>
                  {todoView === "" ||
                  todoView === null ||
                  todoView === undefined ? null : (
                    <div className="form-group mt-0">
                      <h6 className="mb-2">Before Lunch Work</h6>
                      <textarea
                        value={todoView}
                        rows={5}
                        className="border-0 p-0"
                        disabled
                      />
                    </div>
                  )}
                  {mtodoView === "" ||
                  mtodoView === null ||
                  mtodoView === undefined ? null : (
                    <div className="form-group mt-0">
                      <h6 className="mb-2">After Lunch Work</h6>
                      <textarea
                        value={mtodoView}
                        rows={4}
                        className="border-0 p-0"
                        disabled
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
