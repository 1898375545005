import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import dot from "../../images/menu.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { ToastContainer, toast } from "react-toastify";
import { NavDropdown, Tab, Tabs } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getApi, postApi } from "../../helpers/api";
import "./profile.css";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ReactComponent as UserSvg } from "../../images/user.svg";
import { ReactComponent as LeaveSvg } from "../../images/leave.svg";
import { ReactComponent as WatchSVG } from "../../images/watch.svg";
import { ReactComponent as LunchsSVG } from "../../images/lunch.svg";
import { ReactComponent as TeasSVG } from "../../images/tea.svg";
import { ReactComponent as WhSVG } from "../../images/leave (1).svg";
import { ReactComponent as COSVG } from "../../images/check in out.svg";
import Pagination from "../Pagination";
import {
  format,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from "date-fns";
const Leaves = () => {
  const today = new Date();
  const todayString = today?.toISOString()?.split("T")[0];
  const [IsPending, setPending] = useState(false);
  const [IsPendingBtn, setIsPendingBtn] = useState(false);
  const user = useSelector((state) => state.user.user);
  const search = useLocation().search;
  const params = new URLSearchParams(search);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [reason, setReason] = useState("");
  const [isLoding, setIsLoding] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataCount, setDataCount] = useState(0);
  const [attendanceData, setAttendanceData] = useState([]);
  const [salary, setSalary] = useState([]);
  const [title, setTitle] = useState("");
  const [reasonView, setReasonView] = useState("");
  const [leaveTypeView, setLeaveTypeView] = useState("");
  const filteredData = attendanceData.filter(
    (item) => item.date === todayString
  );
  const getMonthName = (monthNumber) => {
    const date = new Date();
    date.setMonth(monthNumber - 1); // months are 0-based in JavaScript
    return format(date, "MMMM");
  };
  const [actionMenu, setActionMenu] = useState({ id: null, status: "d-none" });
  const [show, setShow] = useState(false);
  const [showTask, setShowTask] = useState(false);
  const [tasknotes, setTaskNotes] = useState({
    todo: "",
    checkIn: "",
    todomorning: "",
  });
  // Leave
  const [showLeave, setShowLeave] = useState(false);
  const [showLeaveView, setShowLeaveView] = useState(false);
  const [IsLeavePendingBtn, setIsLeavePendingBtn] = useState(false);
  const [project, setProject] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [errors, setErrors] = useState("");
  const [leaveList, setLeaveList] = useState([]);
  const [projectResult, setProjectResult] = useState([]);
  /*************Pagination */
  const [totalPages, setTotalPages] = useState(1);
  const [currentPages, setCurrentPages] = useState(1);
  const [type, setType] = useState("");
  const [count, setCount] = useState(0);

  const handleNextPage = () => {
    if (currentPages < totalPages) {
      setCurrentPages(currentPages + 1);
    }
  };

  const handlePrevPage = async () => {
    if (currentPages > 1) {
      setCurrentPages(currentPages - 1);
    }
  };
  const handlePageChanges = (pageNumber) => {
    setCurrentPages(pageNumber);
  };
  /***************** */
  // --- ---
  let CurrentDate = new Date();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let todayDate =
    CurrentDate.getDate() +
    " " +
    months[CurrentDate.getMonth()] +
    " " +
    CurrentDate.getFullYear();

  const successNotify = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

  const errorNotify = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
      theme: "colored",
    });

  useEffect(() => {
    getAllAttendance(currentPage);
  }, [currentPage, currentPages, type]);

  async function getAllAttendance(page) {
    !isLoding && setIsLoding(true);
    let AttendanceUrl =
      sDate && eDate
        ? `all-attendance?sDate=${sDate}&eDate=${eDate}&pageCount=${page}`
        : `all-attendance?pageCount=${page}`;
    await getApi(AttendanceUrl, true)
      .then((res) => {
        let newData = { ...res.data };
        if (res.status === 200) {
          setDataCount(newData.attendanceCount);
          setAttendanceData(newData.attendance);
          setIsLoding(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    await getApi("projects", true).then((res) => {
      if (res.status === 200) {
        setProject(res.data);
      }
    });
    let url =
      sDate && eDate
        ? `employee-leave-get?sDate=${sDate}&eDate=${eDate}&page=${currentPages}`
        : `employee-leave-get?pageCount=${currentPages}`;
    if (type !== null && type !== undefined) url += `&type=${type}`;
    await getApi(url, true)
      .then((res) => {
        setLeaveList(res.data.data);
        setTotalPages(res.data.totalPages);
        setCount(res.data.count);
      })
      .catch((err) => {
        console.error(err);
      });
   
  }

  let sDate, eDate;
  const SerachDate = async () => {
    sDate = startDate;
    eDate = endDate;
    getAllAttendance(currentPage);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
function roundToHalf(value) {
  const converted = parseFloat(value); // Ensure the value is a number
  const decimal = converted - Math.floor(converted);

  let roundedValue;
  if (decimal < 0.25) {
    roundedValue = Math.ceil(converted); // Round up to nearest integer
  } else if (decimal >= 0.25 && decimal < 0.75) {
    roundedValue = Math.floor(converted) + 0.5;
    roundedValue=Math.round(roundedValue) // Round to nearest half
  } else {
    roundedValue = Math.ceil(converted); // Round up to nearest integer
  }
  return roundedValue.toFixed(1);
}
  const calculateLeaveDuration = (
    start_date,
    end_date,
    start_time,
    end_time
  ) => {
    const startDateTime = new Date(`${start_date}T${start_time}`);
    const endDateTime = new Date(`${end_date}T${end_time}`);

    // Calculate the duration in hours
    const durationInHours = (endDateTime - startDateTime) / (1000 * 60 * 60);    
    // Convert hours to fractions of a day
    const durationInDays = durationInHours / 24;    
    if (durationInHours < 24) {
      if (durationInHours <= 4) {
        return 0.5;
      } else {
        return 1;
      }
    }

    // Round to the nearest 0.5
    const roundedDuration = (durationInDays * 2) / 2;
    const roundedNumber = roundToHalf(roundedDuration);
    return roundedNumber;
  }; 
  function getTimeDifferenceInHours(startTime, endTime) {
    const [startHour, startMinute] = startTime.split(":").map(Number);
    const [endHour, endMinute] = endTime.split(":").map(Number);

    const startDate = new Date();
    startDate.setHours(startHour, startMinute, 0, 0);

    const endDate = new Date();
    endDate.setHours(endHour, endMinute, 0, 0);

    // If end time is before start time, assume it is the next day
    if (endDate < startDate) {
      endDate.setDate(endDate.getDate() + 1);
    }

    const diffInMilliseconds = endDate - startDate;
    const diffInHours = diffInMilliseconds / (1000 * 60 * 60);

    return diffInHours;
  }
  const SignupSchema = Yup.object().shape({
    start_date: Yup.string().required("Start date is required"),
    start_time: Yup.string().required("Start time is required"),
    end_date: Yup.string().required("End date is required"),
    end_time: Yup.string().required("End time is required"),
    reason: Yup.string().required("Reason is required"),
    leave_type: Yup.string().required("Leave type is required"),
  });
  const formik = useFormik({
    initialValues: {
      start_date: "",
      end_date: "",
      start_time: "",
      end_time: "",
      duration: "",
      reason: "",
      leave_type: "",
    },
    validationSchema: SignupSchema,
    onSubmit: async (values) => {
      let isSameDay = values.start_date === values.end_date;
      let diff = getTimeDifferenceInHours(values.start_time, values.end_time);
      if (isSameDay) {
        if (diff < 4) {
          formik.setFieldError(
            "end_time",
            "End time must be at least 4 hours after start time"
          );
          formik.setSubmitting(false); // Stop form submission if there's an error
          return;
        }
      }
      setIsPendingBtn(true);
      const differenceInDays = calculateLeaveDuration(
        values.start_date,
        values.end_date,
        values.start_time,
        values.end_time
      );
      let leaveData;
      leaveData = {
        ...values,
        duration: differenceInDays,
      };
      await postApi("emaployee-leave", leaveData, true).then(async (res) => {
        if (res.status === 200) {
          setIsPendingBtn(false);
          setShowLeave(false);
          formik.resetForm();
          await getApi("employee-leave-get", true).then((resp) => {
            setLeaveList(resp.data.data);
          });
        }
      });
    },
  });

  return (
    <>
      <ToastContainer />
      <section className="content user-profile">       
            <div className="profile-tab">
              <div className="attendance-head">
                <div className="search-date mt-3 mt-xl-0">
                  <DatePicker
                    showIcon
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText="From Date"
                    dateFormat="dd MMMM yyyy"
                  />
                  &nbsp;&nbsp;&nbsp;
                  <DatePicker
                    showIcon
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    placeholderText="To Date"
                    dateFormat="dd MMMM yyyy"
                  />
                  &nbsp;&nbsp;&nbsp;
                  <button
                    className="theme-btn-primary py-0"
                    onClick={SerachDate}
                    disabled={
                      startDate === undefined || endDate === undefined
                        ? true
                        : false
                    }
                  >
                    Go
                  </button>
                </div>
                {
                  <>
                    <div className="filterwrap content-header-right">
                      <select
                        name="type"
                        onChange={(e) => {
                          setType(e.target.value);
                        }}
                      >
                        <option value="">Select Leave Type</option>
                        <option value="0">Pending</option>
                        <option value="1">Approved</option>
                        <option value="2">Reject</option>
                        <option value="3">Cancel</option>
                      </select>
                    </div>
                    <div className="check-in-out-btn mt-3 mt-xl-0">
                      <button
                        className="theme-btn-primary calsvg"
                        onClick={() => setShowLeave(true)}
                      >
                        <LeaveSvg />
                        &nbsp;&nbsp;Add Leave
                      </button>
                    </div>
                  </>
                }
              </div>
              <div>
                <table className="table-list">
                  <thead>
                    <tr>
                      <th className="attend-filed">Start Date</th>
                      <th className="attend-filed">End Date</th>
                      <th className="attend-filed">Duration</th>
                      <th className="attend-filed">Leave Type</th>
                      <th className="attend-filed" style={{ width: 400 }}>
                        Reason
                      </th>
                      <th className="attend-filed text-center" style={{ width: 100 }}>
                        Status
                      </th>
                      <th className="attend-filed" style={{ width: 20 }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isLoding ? (
                      leaveList.length ? (
                        leaveList.map((attendval, attendkey) => {
                          return (
                            <tr key={attendkey}>
                              <td>
                                {format(
                                  new Date(attendval.start_date),
                                  "dd MMM yyyy"
                                )}
                              </td>
                              <td>
                                {format(
                                  new Date(attendval.end_date),
                                  "dd MMM yyyy"
                                )}
                              </td>
                              <td>
                                {attendval.duration === "Half Day"
                                  ? "Half Leave"
                                  : attendval.duration <= 1
                                  ? `${attendval.duration} Day`
                                  : attendval.duration.includes(":")
                                  ? attendval.duration // If duration includes ":" assume it's formatted correctly
                                  : `${attendval.duration} Days`}
                              </td>
                              <td>{attendval?.leave_type?.substr(0, 30)}</td>
                              <td>{attendval?.reason?.substr(0, 100)}</td>
                              <td className="text-center">
                                <button
                                  className={
                                    "theme-btn-primary btnwdth ml-2 " +
                                    (attendval.status === 1
                                      ? "btn-success"
                                      : attendval.status === 2
                                      ? "btn-danger"
                                      : attendval.status === 3
                                      ? "btn-dark"
                                      : "")
                                  }
                                >
                                  {attendval.status === 0
                                    ? "Pending"
                                    : attendval.status === 1
                                    ? "Approved"
                                    : attendval.status === 2
                                    ? "Rejected"
                                    : attendval.status === 3
                                    ? "Cancel"
                                    : ""}
                                </button>
                              </td>
                              <td className="text-end">
                                <Dropdown>
                                  <Dropdown.Toggle className="dotbtn">
                                    <img src={dot} alt="" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={() => {
                                        setShowLeaveView(true);
                                        setReasonView(attendval?.reason);
                                        setLeaveTypeView(attendval?.leave_type);
                                      }}
                                    >
                                      View Reason
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td
                            colSpan={6}
                            className="text-center theme-text-danager"
                          >
                            No Records Found
                          </td>
                        </tr>
                      )
                    ) : (
                      <tr>
                        <td
                          colSpan={6}
                          className="text-center theme-text-danager position-relative"
                        >
                          <div className="lds-ring">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {totalPages > 1 ? (
                  <Pagination
                    currentPage={currentPages}
                    totalPages={totalPages}
                    onNext={handleNextPage}
                    onPrev={handlePrevPage}
                    handlePageChange={handlePageChanges}
                  />
                ) : null}
              </div>
            </div>          
      </section>
      <Modal
        show={showLeave}
        size="lg"
        onHide={() => setShowLeave(false)}
        aria-labelledby="leave-added-model"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="leave-added-model">Add Leave</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group mt-0 datttepicker text-end">
                  <label className="text-start w-100">
                    From Date<span className="required-lable">*</span>
                  </label>
                  <input
                    type="date"
                    className={`${
                      formik.errors.start_date && formik.touched.start_date
                        ? "inputError"
                        : ""
                    }`}
                    placeholder="From Date"
                    name="start_date"
                    onChange={formik.handleChange}
                  />
                  <div className="error mt-2">
                    {formik.touched.start_date && formik.errors.start_date ? (
                      <p> {formik.errors.start_date}</p>
                    ) : null}
                  </div>
                  <input
                    type="time"
                    name="start_time"
                    min="09:00"
                    max="18:30"
                    required
                    className="mt-2"
                    onChange={formik.handleChange}
                  />
                  <div className="error mt-2">
                    {formik.touched.start_time && formik.errors.start_time ? (
                      <p> {formik.errors.start_time}</p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mt-0 text-end">
                  <label className="text-start w-100">
                    To Date<span className="required-lable">*</span>
                  </label>
                  <input
                    type="date"
                    className={`${
                      formik.errors.end_date && formik.touched.end_date
                        ? "inputError"
                        : ""
                    }`}
                    min={formik.values.start_date}
                    placeholder="To Date"
                    name="end_date"
                    onChange={formik.handleChange}
                  />
                  <div className="error mt-2">
                    {formik.touched.end_date && formik.errors.end_date ? (
                      <p> {formik.errors.end_date}</p>
                    ) : null}
                  </div>
                  <input
                    type="time"
                    name="end_time"
                    min="09:00"
                    max="18:30"
                    required
                    className="mt-2"
                    onChange={formik.handleChange}
                  />
                  <div className="error mt-2">
                    {formik.touched.end_time && formik.errors.end_time ? (
                      <p> {formik.errors.end_time}</p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group mt-0">
                  <label>
                    Leave Type<span className="required-lable">*</span>
                  </label>
                  <input
                    type="text"
                    name="leave_type"
                    onChange={formik.handleChange}
                    value={formik.values.leave_type}
                    rows={7}
                    className={
                      formik.errors.leave_type && formik.touched.leave_type
                        ? "inputError"
                        : ""
                    }
                  />
                  <div className="error mt-2">
                    {formik.touched.leave_type && formik.errors.leave_type ? (
                      <p> {formik.errors.leave_type}</p>
                    ) : null}
                  </div>
                </div>
                <div className="form-group mt-0">
                  <label>
                    Reason<span className="required-lable">*</span>
                  </label>
                  <textarea
                    name="reason"
                    onChange={formik.handleChange}
                    // onBlur={formik.handleBlur}
                    value={formik.values.reason}
                    rows={7}
                    className={
                      formik.errors.reason && formik.touched.reason
                        ? "inputError"
                        : ""
                    }
                  />
                  <div className="error mt-2">
                    {formik.touched.reason && formik.errors.reason ? (
                      <p> {formik.errors.reason}</p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-md-12 text-center">
                {/* <button type="submit">Submit</button> */}
                <button
                  className="theme-btn-primary"
                  // onClick={() => addLeave()}
                  type="submit"
                  disabled={IsLeavePendingBtn ? true : false}
                >
                  {IsLeavePendingBtn ? "Loding..." : "Submit"}
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Modal
        show={showLeaveView}
        size="lg"
        onHide={() => setShowLeaveView(false)}
        aria-labelledby="leave-added-model"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="leave-added-model">View Leave</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group mt-0">
                  <h6 className="mb-2">Leave Type</h6>
                  <input
                    type="text"
                    value={leaveTypeView}
                    rows={7}
                    className="border-0 p-0"
                    disabled
                  />
                </div>
                <div className="form-group mt-0">
                  <h6 className="mb-2">Reason</h6>
                  <textarea
                    value={reasonView}
                    rows={7}
                    className="border-0 p-0"
                    disabled
                  />
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Leaves;
