import React, { useEffect, useState } from "react";
import { Modal, Form, Dropdown, Tab, Tabs } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { getApi, postApi } from "../../helpers/api";
import { ToastContainer, toast } from "react-toastify";
import "./employee.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";
import LeaveEmployee from "./leave";
import AttendanceEmployee from "./attendence";
import SalaryEmployee from "./salary";
import axios from "axios";
function ViewEmployee() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let user = useSelector((state) => state.user.user);
  const [permission, setPermission] = useState({});
  const [peojectPermission, setPeojectPermission] = useState({});  
  const search = useLocation().search;
  const params = new URLSearchParams(search);
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
  // ---- Employee useState --
  const [employeeData, setEmployeeData] = useState([]);
  const [roleData, setRoleData] = useState([]);

  // ---- Commend useState --
  const [uploadEmployee, setUploadEmployee] = useState(
    "./images/default-image.png"
  );
  const [isLoding, setIsLoding] = useState(true);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [tasknotes, setTaskNotes] = useState("");
  const [show, setShow] = useState(false);
  const [IsPending, setPending] = useState(false);
  // ---- Attendance useState --
  const [dataAttenCount, setDataAttenCount] = useState(0);
  const [attendanceData, setAttendanceData] = useState([]);
  const [currentAttenPage, setCurrentAttenPage] = useState(1);

  const notifySuccess = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

  useEffect(() => {
    if (user.userRole.role_name === "admin") {
      getEmployee();
      getRole();
      getAllAttendance(currentAttenPage);
    } else {
      dispatch({ type: "LOGOUT", payload: null });
      localStorage.removeItem(process.env.REACT_APP_SECRET);
      navigate("/");
    }
  }, []);

  async function getEmployee() {    
    try {
      let employeeUrl = `my-employee?employee=${params.get("employee")}`;
      const employee = await getApi(employeeUrl, true);
      let newData = { ...employee.data[0] };
      setEmployeeData(newData);
      setInitialValues(newData);
      setPermission(JSON.parse(newData.permission));
       setPeojectPermission(JSON.parse(newData.permission));
      setIsLoding(false);
      setUploadEmployee(
        `${process.env.REACT_APP_BASE_URL_UPLOAD}employeeimages/${newData.employee_image}`
      );
    } catch (error) {
      console.error(error);
    }
  }

  async function getAllAttendance(page) {
    !isLoding && setIsLoding(true);
    let attendanceUrl =
      startDate && endDate && page
        ? `all-attendance?employee=${params.get(
            "employee"
          )}&sDate=${startDate}&eDate=${endDate}&pageCount=${page}`
        : `all-attendance?employee=${params.get("employee")}&pageCount=${page}`;
    await getApi(attendanceUrl, true)
      .then((response) => {
        let attendanceDataCopy;
        if (response.status === 200) {
          attendanceDataCopy = response.data.attendance;
          setDataAttenCount(Math.ceil(response.dataCount / 10));
          setAttendanceData(attendanceDataCopy);
        }
        setIsLoding(false);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  const showMyTask = async (id) => {
    await getApi(`all-attendance?my_task=${id}`, true)
      .then((response) => {
        let taskNote = response.data.attendance[0].todo;
        setTaskNotes(taskNote);
        setShow(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let sDate, eDate;
  const SerachDate = async () => {
    // sDate = startDate;
    // eDate = endDate;
    getAllAttendance(currentAttenPage);
  };

  const handleAttenPageChange = (page) => {
    setCurrentAttenPage(page);
    getAllAttendance(page);
  };

  const fileChangeHandler = (event) => {
    setFieldValue("employee_image", event.target.files[0]);
    if (event.target.files && event.target.files.length) {
      setUploadEmployee(URL.createObjectURL(event.target.files[0]));
    }
  };

  async function getRole() {
    let roleUrl = `role`;
    await getApi(roleUrl)
      .then((response) => {
        let newData = [...response.data];
        setRoleData(newData);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }
  const UserPermission = (event) => {
    const { name, checked } = event.target;
    setPermission({
      ...permission,
      [name]: checked,
    });
  };
  const UserPermissionpro = (event) => {
    console.log("UserPermissionpro");
    const { name, checked } = event.target;
    setPeojectPermission({
      ...peojectPermission,
      [name]: checked,
    });
  };
  const allowPermission = async (id) => {
    let newData = {
      ...permission,
      ...peojectPermission,
    };
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}permission?user=${id}`, {
        permission: JSON.stringify(newData),
      })
      .then((response) => {
        navigate("/employee");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [initialValues, setInitialValues] = useState({
    fullname: "",
    email: "",
    password: "",
    mobile_no: "",
    gender: "",
    role_id: "",
    dob: "",
    doj: "",
    work_type: "",
    experience: "",
    education: "",
    address: "",
    employee_image: "",
    salary: "",
  });

  const validationSchema = yup.object({
    fullname: yup.string().required("Fullname is required."),
    email: yup
      .string()
      .required("Email is required.")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Please enter valid email address."
      ),
    mobile_no: yup
      .string()
      .required("Mobile number is required.")
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Phone number is not valid."
      ),
    gender: yup.string().required("Gender is required."),
    role_id: yup.string().required("Role is required."),
    dob: yup.string().required("Date of Birth is required."),
    doj: yup.string().required("Date of Joining required."),
    work_type: yup.string().required("WorkedAt is required."),
    experience: yup.string().required("Experience is required."),
    address: yup.string().required("Address is required."),
    salary: yup
      .number()
      .typeError("Salary must be a number") // Ensures the input is a number
      .required("Salary is required"),
    employee_image: yup
      .mixed()
      .test("size", "Upload less than 1 MB file.", (value) => {
        if (initialValues.employee_image) {
          return true;
        } else if (!value || value.size >= 1048576) {
          return false;
        }
        return true;
      })
      .test("type", "Upload only .jpg, .jpeg, .png", (value) => {
        if (initialValues.employee_image) {
          return true;
        } else if (!value || !SUPPORTED_FORMATS.includes(value.type)) {
          return false;
        }
        return true;
      })
      .nullable(true),
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      let newData = {
        ...permission,
        ...peojectPermission,
      };
      values = {
        ...values,
        permission: JSON.stringify(newData),
      };
      setPending(true);
      await postApi(
        `add-employee?employee=${employeeData.id}`,
        values,
        true,
        true
      ).then((res) => {
        let result = { ...res };
        if (result.status === 200) {
          getEmployee();
          closeMenu();
          setPending(false);
          notifySuccess(result.message);
        } else {
          setPending(false);
        }
      });
    },
  });

  async function deleteEmployee(id) {
    let employeeUrl = `delete-employee?employee=${id}`;
    await getApi(employeeUrl)
      .then((response) => {
        let newData = { ...response };
        if (newData.data) {
          navigate("/employee");
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  async function openMenu(id) {
    let employeeUrl = `my-employee?empl_id=${id}`;
    await getApi(employeeUrl, true)
      .then((response) => {
        let newData = { ...response.data[0] };
        setUploadEmployee(
          newData.employee_image === null
            ? "./images/default-image.png"
            : `${process.env.REACT_APP_BASE_URL_UPLOAD}employeeimages/${newData.employee_image}`
        );
        delete newData.role;
        setInitialValues(newData);
        setShow(true);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  async function closeMenu() {
    setShow(false);
    setUploadEmployee("./images/default-image.png");
    resetForm();
  }

  async function statusEmployee(id, status) {
    let newStatus = status === 1 ? 0 : 1;
    let newData = { status: newStatus };
    await postApi(`status-employee?employee=${id}`, newData)
      .then((res) => {
        let result = { ...res };
        if (result.status === 200) {
          getEmployee();
          notifySuccess(result.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
      <ToastContainer />
      {/* <div className="content-header pb-4">
        <p className="breadcrumb">
          <Link to={"/employee"} className="back-link">
            Employees
          </Link>
          /<span className="active">{employeeData.fullname}</span>
        </p>
      </div> */}
      <div className="row">
        <div className="col-md-12">
          <div className="profile-setting">
            <div className="empname">{employeeData.fullname}</div>
            <Tabs
              defaultActiveKey="employee"
              id="uncontrolled-tab-example"
              className=""
            >
              <Tab eventKey="employee" title="Employee">
                <div className="profile-view">
                  <div className="profile-view-image">
                    {employeeData.employee_image !== "" &&
                    employeeData.employee_image !== null &&
                    employeeData.employee_image !== undefined ? (
                      <img
                        src={`${process.env.REACT_APP_BASE_URL_UPLOAD}employeeimages/${employeeData.employee_image}`}
                        alt={employeeData.fullname}
                      />
                    ) : (
                      <div className="employee-profile-text">
                        {employeeData.fullname?.charAt(0)}
                      </div>
                    )}
                  </div>
                  <div className="profile-view-info">
                    <div className="row">
                      <div className="col-md-9">
                        <div className="profile-left">
                          <table>
                            <tr>
                              <th className="profile-title">Employee:</th>
                              <td>{employeeData.fullname}</td>
                            </tr>
                            <tr>
                              <th className="profile-title">Employee ID:</th>
                              <td>{employeeData.id}</td>
                            </tr>
                            <tr>
                              <th className="profile-title">Designation:</th>
                              <td>{employeeData.userRole?.role_name}</td>
                            </tr>
                            <tr>
                              <th className="profile-title">Gender:</th>
                              <td className="text-capitalize">
                                {employeeData.gender}
                              </td>
                            </tr>
                            <tr>
                              <th className="profile-title">Date of Join:</th>
                              <td>
                                {new Date(employeeData.doj).getDate() +
                                  " " +
                                  month[new Date(employeeData.doj).getMonth()] +
                                  " " +
                                  new Date(employeeData.doj).getFullYear()}
                              </td>
                            </tr>
                            <tr>
                              <th className="profile-title">Worked At:</th>
                              <td>
                                {employeeData.work_type === 1
                                  ? "Freelancer"
                                  : employeeData.work_type === 2
                                  ? "Work from home"
                                  : "On-site"}
                              </td>
                              <th className="profile-title">Experience:</th>
                              <td>{employeeData.experience}</td>
                            </tr>
                            <tr>
                              <th className="profile-title">Phone:</th>
                              <td>{employeeData.mobile_no}</td>
                            </tr>
                            <tr>
                              <th className="profile-title">Email:</th>
                              <td>{employeeData.email}</td>
                            </tr>
                            <tr>
                              <th className="profile-title">Birthday:</th>
                              <td>
                                {new Date(employeeData.dob).getDate() +
                                  " " +
                                  month[new Date(employeeData.dob).getMonth()] +
                                  " " +
                                  new Date(employeeData.dob).getFullYear()}
                              </td>
                            </tr>
                            <tr>
                              <th className="profile-title">Address:</th>
                              <td>{employeeData.address}</td>
                            </tr>
                            <tr>
                              <th className="profile-title">Education:</th>
                              <td>{employeeData.education}</td>
                            </tr>
                            {/* <tr>
                              <th className="profile-title">Reports to:</th>
                              <td>Jeffery Lalor</td>
                            </tr> */}
                            <tr>
                              <th className="profile-title">Salary:</th>
                              <td>{employeeData.salary}</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      {/* <div className="col-md-7">
                        <div className="profile-right">
                          <table>
                            <tr>
                              <th className="profile-title">Phone:</th>
                              <td>{employeeData.mobile_no}</td>
                            </tr>
                            <tr>
                              <th className="profile-title">Email:</th>
                              <td>{employeeData.email}</td>
                            </tr>
                            <tr>
                              <th className="profile-title">Birthday:</th>
                              <td>
                                {new Date(employeeData.dob).getDate() +
                                  " " +
                                  month[new Date(employeeData.dob).getMonth()] +
                                  " " +
                                  new Date(employeeData.dob).getFullYear()}
                              </td>
                            </tr>
                            <tr>
                              <th className="profile-title">Address:</th>
                              <td>{employeeData.address}</td>
                            </tr>
                            <tr>
                              <th className="profile-title">Education:</th>
                              <td>{employeeData.education}</td>
                            </tr>
                            <tr>
                              <th className="profile-title">Reports to:</th>
                              <td>Jeffery Lalor</td>
                            </tr>
                          </table>
                        </div>
                      </div> */}
                    </div>
                    <div className="action-profile">
                      <button
                        className="btn-profile theme-primary"
                        title="Edit"
                        onClick={() => openMenu(employeeData.id)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                          <path d="M6 21v-2a4 4 0 0 1 4 -4h3.5" />
                          <path d="M18.42 15.61a2.1 2.1 0 0 1 2.97 2.97l-3.39 3.42h-3v-3l3.42 -3.39z" />
                        </svg>
                      </button>
                      <button
                        className="btn-profile theme-danger"
                        title="Delete"
                        onClick={() => deleteEmployee(employeeData.id)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M4 7l16 0" />
                          <path d="M10 11l0 6" />
                          <path d="M14 11l0 6" />
                          <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                          <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                        </svg>
                      </button>
                      <button
                        className="btn-profile theme-success"
                        title="view"
                        onClick={() =>
                          statusEmployee(employeeData.id, employeeData.status)
                        }
                      >
                        {employeeData.status === 1 ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                            <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-eye-off"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M10.585 10.587a2 2 0 0 0 2.829 2.828" />
                            <path d="M16.681 16.673a8.717 8.717 0 0 1 -4.681 1.327c-3.6 0 -6.6 -2 -9 -6c1.272 -2.12 2.712 -3.678 4.32 -4.674m2.86 -1.146a9.055 9.055 0 0 1 1.82 -.18c3.6 0 6.6 2 9 6c-.666 1.11 -1.379 2.067 -2.138 2.87" />
                            <path d="M3 3l18 18" />
                          </svg>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="attendance" title="Attendances">
                <AttendanceEmployee />
              </Tab>
              <Tab eventKey="leave" title="Leave">
                <LeaveEmployee />
              </Tab>
              <Tab eventKey="salary" title="Salary">
                <SalaryEmployee />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        size="lg"
        onHide={() => setShow(false)}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Task Notes
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form method="post">
            <div className="form-group m-0">
              <textarea
                placeholder="Task Note..."
                name="app_note"
                rows={15}
                onChange={(task) => setTaskNotes(task.target.value)}
                value={tasknotes}
                disabled
              />
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={show}
        onHide={closeMenu}
        aria-labelledby="employee-added"
        className="employee-model"
      >
        <Modal.Header closeButton>
          <Modal.Title id="employee-added">Added Employee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex">
            <div className="employee-information col-6">
              <form
                onSubmit={handleSubmit}
                autoComplete="off"
                encType="multipart/form-data"
              >
                <div className="row">
                  <div className="col-md-12">
                    {errors.employee_image && touched.employee_image ? (
                      <span className="required-lable mt-2 d-block text-center">
                        {errors.employee_image}{" "}
                      </span>
                    ) : null}
                    <div className="employee-upload-profile mt-4">
                      <img src={uploadEmployee} alt="employee upload." />
                      <div className="image-upload-btn">
                        <label htmlFor="image-upload">Upload</label>
                        <input
                          type="file"
                          name="employee_image"
                          className="d-none"
                          id="image-upload"
                          onChange={(e) => fileChangeHandler(e)}
                          accept="image/png, image/jpeg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mt-0">
                      <label>
                        Fullname
                        <span className="required-lable">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Fullname"
                        name="fullname"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.fullname}
                      />
                      {errors.fullname && touched.fullname ? (
                        <span className="required-lable">
                          {errors.fullname}{" "}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mt-0">
                      <label>
                        Email<span className="required-lable">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      {errors.email && touched.email ? (
                        <span className="required-lable">{errors.email} </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mt-0">
                      <label>
                        Password<span className="required-lable">*</span>
                      </label>
                      <div className="password-input-container passwordWrap">
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control password-input"
                          placeholder="Password"
                          name="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          // value={values.password}
                        />
                        <span
                          className="password-toggle-icon"
                          onClick={togglePasswordVisibility}
                        >
                          <FontAwesomeIcon
                            icon={showPassword ? faEyeSlash : faEye}
                          />
                        </span>
                      </div>
                      {errors.password && touched.password ? (
                        <span className="required-lable">
                          {errors.password}{" "}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mt-0">
                      <label>
                        Mobile Number<span className="required-lable">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Mobile Number"
                        name="mobile_no"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.mobile_no}
                      />
                      {errors.mobile_no && touched.mobile_no ? (
                        <span className="required-lable">
                          {errors.mobile_no}{" "}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mt-0">
                      <label>
                        Gender<span className="required-lable">*</span>
                      </label>
                      <div key={`inline-radio`}>
                        <Form.Check
                          inline
                          label="Male"
                          name="gender"
                          type={"radio"}
                          id={`inline-radio-1`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={"male"}
                          checked={values.gender === "male" ? true : false}
                        />
                        <Form.Check
                          inline
                          label="Female"
                          name="gender"
                          type={"radio"}
                          id={`inline-radio-2`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={"female"}
                          checked={values.gender === "female" ? true : false}
                        />
                      </div>
                      {errors.gender && touched.gender ? (
                        <span className="required-lable">{errors.gender} </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mt-0">
                      <label>
                        Role<span className="required-lable">*</span>
                      </label>
                      <select
                        name="role_id"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value={""}>Select role</option>
                        {roleData.map((val, key) => {
                          return (
                            <option
                              key={key}
                              selected={val.id === values.role_id}
                              value={val.id}
                            >
                              {val.role_name}
                            </option>
                          );
                        })}
                      </select>
                      {errors.role_id && touched.role_id ? (
                        <span className="required-lable">
                          {errors.role_id}{" "}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mt-0">
                      <label>
                        Date Of Birth<span className="required-lable">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Date of Birth"
                        name="dob"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.dob}
                      />
                      {errors.dob && touched.dob ? (
                        <span className="required-lable">{errors.dob} </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mt-0">
                      <label>
                        Date of Joining<span className="required-lable">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Date of Joining"
                        name="doj"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.doj}
                      />
                      {errors.doj && touched.doj ? (
                        <span className="required-lable">{errors.doj} </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mt-0">
                      <label>
                        Worked At<span className="required-lable">*</span>
                      </label>
                      <select
                        name="work_type"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.work_type}
                      >
                        <option value={""}>Select Worked type</option>
                        <option value={1}>Freelancer</option>
                        <option value={2}>Work from home</option>
                        <option value={3}>On-site</option>
                      </select>
                      {errors.work_type && touched.work_type ? (
                        <span className="required-lable">
                          {errors.work_type}{" "}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mt-0">
                      <label>
                        Experience<span className="required-lable">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Experience"
                        name="experience"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.experience}
                      />
                      {errors.experience && touched.experience ? (
                        <span className="required-lable">
                          {errors.experience}{" "}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mt-0">
                      <label>Education</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Education"
                        name="education"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.education}
                      />
                      {errors.education && touched.education ? (
                        <span className="required-lable">
                          {errors.education}{" "}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mt-0">
                      <label>
                        Address<span className="required-lable">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        placeholder="Address"
                        name="address"
                        rows={3}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.address}
                      />
                      {errors.address && touched.address ? (
                        <span className="required-lable">
                          {errors.address}{" "}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mt-0">
                      <label>Salary</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Salary"
                        name="salary"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.salary}
                      />
                      {errors.salary && touched.salary ? (
                        <span className="required-lable">{errors.salary} </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <button
                      type="submit"
                      className="theme-btn-primary d-block m-auto mb-4"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-6">
              <section className="content">
                <p className="permission-title">Application Permission</p>
                <div className="permission-box">
                  <input
                    type="checkbox"
                    id="app_r"
                    name="app_r"
                    checked={permission?.app_r}
                    onChange={UserPermission}
                  />
                  <label for="app_r">Application List</label>
                  <input
                    type="checkbox"
                    id="app_c"
                    name="app_c"
                    checked={permission?.app_c}
                    onChange={UserPermission}
                  />
                  <label for="app_c">Create App</label>
                  <input
                    type="checkbox"
                    id="app_e"
                    name="app_e"
                    checked={permission?.app_e}
                    onChange={UserPermission}
                  />
                  <label for="app_e">Edit App</label>
                  <input
                    type="checkbox"
                    id="app_d"
                    name="app_d"
                    checked={permission?.app_d}
                    onChange={UserPermission}
                  />
                  <label for="app_d">Delete App</label>
                </div>
                <p className="permission-title">Project Permission</p>
                <div className="permission-box">
                  <input
                    type="checkbox"
                    id="project_r"
                    name="project_r"
                    checked={peojectPermission?.project_r}
                    onChange={UserPermissionpro}
                  />
                  <label htmlFor="project_r">Project List</label>
                  <input
                    type="checkbox"
                    id="project_c"
                    name="project_c"
                    checked={peojectPermission?.project_c}
                    onChange={UserPermissionpro}
                  />
                  <label htmlFor="project_c">Create Project</label>
                  <input
                    type="checkbox"
                    id="project_e"
                    name="project_e"
                    checked={peojectPermission?.project_e}
                    onChange={UserPermissionpro}
                  />
                  <label htmlFor="project_e">Edit Project</label>
                  <input
                    type="checkbox"
                    id="project_d"
                    name="project_d"
                    checked={peojectPermission?.project_d}
                    onChange={UserPermissionpro}
                  />
                  <label htmlFor="project_d">Delete App</label>
                </div>
                {/* <p className="permission-title">Dashboard Permission</p>
                <div className="permission-box mb-0">
                  <input
                    type="checkbox"
                    id="home"
                    name="home"
                    checked={permission?.home}
                    onChange={UserPermission}
                  />
                  <label for="home">Dashboard</label>
                </div> */}
                {params.id && (
                  <div className="add-submit mt-3">
                    <button
                      type="submit"
                      className="theme-btn-primary"
                      onClick={() => allowPermission(params.id)}
                      disabled={IsPending ? true : false}
                    >
                      {IsPending ? "Loding..." : "Submit"}
                    </button>
                  </div>
                )}
              </section>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ViewEmployee;
