import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import cat from "../../images/cat-animal (2).svg";
import { getApi, postApi } from "../../helpers/api";
import { format } from "date-fns";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Pagination from "../Pagination";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useFormik } from "formik";
import * as Yup from "yup";
const Salary = () => {
  const [data, setData] = useState([]);
  const [id, setId] = useState(0);
  const [smShow, setSmShow] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [user, setUser] = useState([]);
  const [month,setMonth]=useState();
  /*************Pagination */
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [salary, setSalary] = useState(0);
  const [leavedays, setLeaveDays] = useState(0);
  const [payablesalary, setPayableSalary] = useState(0);
  const [perdaysalary, setperdaysalary]=useState(0);
  let userWithIdOne = [];
  const [type, setType] = useState("");
  const [count, setCount] = useState(0);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = async () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  /***************** */
  const successNotify = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

  const errorNotify = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
      theme: "colored",
    });
  const fetchData = async () => {
    let url =
      sDate && eDate
        ? `salary?sDate=${sDate}&eDate=${eDate}&page=${currentPage}`
        : `salary?page=${currentPage}`;
    await getApi(url, true).then((res) => {
      if (res.status === 200) {
        setData(res.data.data);
        setTotalPages(res.data.totalPages);
        setCount(res.data.count);
      }
    });
  };
  const fetchuser = async () => {
    let url =
      sDate && eDate
        ? `my-employee?sDate=${sDate}&eDate=${eDate}&page=${currentPage}`
        : `my-employee?page=${currentPage}`;
    await getApi(url, true).then((res) => {
      let m = res.data.filter((item) => {
        return item.role_id !== 1;
      });
      if (res.status === 200) {
        setUser(m);
      }
    });
  };
  useEffect(() => {
    fetchData();
    fetchuser();
  }, [currentPage, type]);
  const handleLeave = async (status) => {
    let newdata = {
      status: status,
    };
    await postApi(`leave-approval/${id}`, newdata, true).then((res) => {
      if (res.status === 200) {
        setSmShow(false);
      }
    });
  };
  let sDate, eDate;
  const SerachDate = async () => {
    sDate = startDate;
    eDate = endDate;
    fetchData(currentPage);
  };
  const formik = useFormik({
    initialValues: {
      user_id: "",
      month: "",
      date: "",
      salary: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      user_id: Yup.string().required("User ID is required"),
      month: Yup.string().required("Month is required"),
      date: Yup.date().required("Date is required"),
      salary: Yup.number()
        .typeError("Salary must be a number") // Ensures the input is a number
        .required("Salary is required"),
    }),
    onSubmit: async (values) => {
      await postApi("add-salary", values, true).then((res) => {
        if (res.status === 200) {
          formik.resetForm();
          fetchData(currentPage);
        }
      });
    },
  });
  const getMonthName = (monthNumber) => {
    const date = new Date();
    date.setMonth(monthNumber - 1); // months are 0-based in JavaScript
    return format(date, "MMMM");
  };

  const handleUserChange = async (e) => {
    let user_id = e.target.value;
    if (user_id) {
      formik.setFieldValue("user_id", parseInt(user_id));
      let foundUser = user.find(
        (item) => parseInt(item.id) === parseInt(user_id)
      );
      if (foundUser) {
        setSalary(foundUser.salary);
        setId(user_id);
      }
    }
  };
  return (
    <>
      <ToastContainer />
      <Tabs
        defaultActiveKey="home"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="home" title="Salary">
          <div className="d-flex justify-content-between">
            <div className="">
              <div className="search-date mt-3 mt-xl-0">
                <DatePicker
                  showIcon
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="From Date"
                  dateFormat="dd MMMM yyyy"
                />
                &nbsp;&nbsp;&nbsp;
                <DatePicker
                  showIcon
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  placeholderText="To Date"
                  dateFormat="dd MMMM yyyy"
                />
                &nbsp;&nbsp;&nbsp;
                <button
                  className="theme-btn-primary py-0"
                  onClick={SerachDate}
                  disabled={
                    startDate === undefined || endDate === undefined
                      ? true
                      : false
                  }
                >
                  Go
                </button>
              </div>
            </div>
          </div>
          <table className="table-list">
            <thead>
              <tr>
                {/* <td>id</td> */}
                <td>Name</td>
                <td>Date</td>
                <td>Month</td>
                <td>Year</td>
                <td>Salary</td>
                <td>Status</td>
              </tr>
            </thead>
            <tbody>
              {data?.length ? (
                data?.map((val, key) => {
                  return (
                    <tr key={key}>
                      {/* <td>{val.id}</td> */}
                      <td>{val?.usersalary?.fullname}</td>
                      <td>{format(new Date(val.date), "dd MMM yyyy")}</td>
                      <td>{getMonthName(val.month)}</td>
                      <td>{val.year}</td>
                      <td>{val.salary}</td>
                      <td>
                        <button
                          className={
                            "theme-btn-primary btnwdth " +
                            (val.status === 0
                              ? "btn-success"
                              : val.status === 2
                              ? "btn-danger"
                              : val.status === 3
                              ? "btn-dark"
                              : "")
                          }
                          // onClick={() => {
                          //   setId(val.id);
                          //   setSmShow(true);
                          // }}
                        >
                          {val.status === 0
                            ? "Received"
                            : val.status === 0
                            ? "Received"
                            : ""}
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={6} className="text-center theme-text-danager">
                    Not Found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {totalPages > 1 ? (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onNext={handleNextPage}
              onPrev={handlePrevPage}
              handlePageChange={handlePageChange}
            />
          ) : null}
          <Modal
            size="sm"
            show={smShow}
            onHide={() => setSmShow(false)}
            aria-labelledby="example-modal-sizes-title-sm contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title
                id="example-modal-sizes-title-sm"
                className="text-center justify-content-center"
              >
                Leave Approvement
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex justify-content-center align-items-center gap-4">
                <div className="imageWrap">
                  <img src={cat} alt="cat" />
                </div>
                {/* <h3>Leave Approval!</h3> */}
              </div>
            </Modal.Body>
            <Modal.Footer className="text-center justify-content-center">
              <button
                className="theme-btn-primary btn-success"
                onClick={() => handleLeave(1)}
              >
                Accept
              </button>
              <button
                className="theme-btn-primary btn-danger"
                onClick={() => handleLeave(2)}
              >
                Reject
              </button>
              <button
                className="theme-btn-primary btn-dark"
                onClick={() => handleLeave(3)}
              >
                Cancel
              </button>
            </Modal.Footer>
          </Modal>
        </Tab>
        <Tab eventKey="add-salary" title="Add Salary">
          
          <form onSubmit={formik.handleSubmit} className="d-flex gap-4 flex-wrap">
            <div className="col-md-5">
            <div className="form-group mt-0 mb-0">
              <label htmlFor="user_id">Employee</label>
              <select
                name="user_id"
                value={formik.values.user_id}
                onChange={async(e) => {
                  handleUserChange(e);
                  setLeaveDays(0);
                  setPayableSalary(0);
                  setperdaysalary(0);
                  formik.setFieldValue("month", '');
                  await getApi(
                    `salary-calculation?user=${e}&month=${month}`,
                    true
                  ).then((res) => {
                    if (res.status === 200) {
                      setLeaveDays(res.data.leave);
                      setPayableSalary(res.data.salary);
                      setperdaysalary(res.data.perdaySalary)                      
                    }
                  });
                }}
              >
                <option value="">Select Employee</option>
                {user?.map((user) => (
                  <option value={user.id}>{user.fullname}</option>
                ))}
              </select>
            </div>
            </div>
            <div className="col-md-5">
              <div className="form-group mt-0 mb-0">
              <label htmlFor="month">Month</label>
              <input
                type="month"
                id="month"
                name="month"
                className={`form-control ${
                  formik.touched.month && formik.errors.month
                    ? "inputError"
                    : ""
                }`}
                onChange={async (e) => {
                  formik.setFieldValue("month", e.target.value);
                  setMonth(e.target.value);
                  await getApi(
                    `salary-calculation?user=${id}&month=${e.target.value}`,
                    true
                  ).then((res) => {
                    if (res.status === 200) {
                      setLeaveDays(res.data.leave);
                      setPayableSalary(res.data.salary);
                      setperdaysalary(res.data.perdaySalary)
                    }
                  });
                }}
                onBlur={formik.handleBlur}
                value={formik.values.month}
              />
              {formik.touched.month && formik.errors.month ? (
                <div className="invalid-feedback">{formik.errors.month}</div>
              ) : null}
            </div>
            </div>
            <div className="col-md-5">
              <div className="form-group mt-0 mb-0">
              <label htmlFor="date">Date</label>
              <input
                type="date"
                id="date"
                name="date"
                className={`form-control ${
                  formik.touched.date && formik.errors.date ? "inputError" : ""
                }`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.date}
              />
              {formik.touched.date && formik.errors.date ? (
                <div className="invalid-feedback">{formik.errors.date}</div>
              ) : null}
            </div>
            </div>           
            <div className="col-md-5">            
              <div className="form-group mt-0 mb-0">
              <label htmlFor="salary">Salary</label>
              <input
                type="number"
                id="salary"
                name="salary"
                className={`form-control ${
                  formik.touched.salary && formik.errors.salary
                    ? "inputError"
                    : ""
                }`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.salary}
              />
              {formik.touched.salary && formik.errors.salary ? (
                <div className="invalid-feedback">{formik.errors.salary}</div>
              ) : null}
            </div>
</div>

            <div className="form-group w-100 mt-0 mb-2" >
              <Button type="submit">Submit</Button>
            </div>
          </form>
          {salary !== 0 ||
            salary !== null ||
            salary !== undefined ||
            salary !== "" ? (
            <h6>Actual Salary : {salary}</h6>
          ) : null}
          {leavedays !== 0 ||
            leavedays !== null ||
            leavedays !== undefined ||
            leavedays !== "" ? (
            <h6>Leave Days : {leavedays} Days</h6>
          ) : null}
          {payablesalary !== 0 ||
            payablesalary !== null ||
            payablesalary !== undefined ||
            payablesalary !== "" ? (
            <h6>Payable Salary : {Math.round(payablesalary)}</h6>
          ) : null}
          {perdaysalary !== 0 ||
            perdaysalary !== null ||
            perdaysalary !== undefined ||
            perdaysalary !== "" ? (
              <h6>Per Day Salary : {Math.round(perdaysalary)}</h6>
          ) : null}
        </Tab>
      </Tabs>
    </>
  );
};

export default Salary;
