import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";

// ----- Auth -------
import SignIn from "./auth/signIn";
import SignUp from "./auth/signUp";
import ResetPassword from "./auth/resetPassword";
import ForgotPassword from "./auth/forgotPassword";

// ------- Components ------

// ----- My Role -----
import MyRole from "./components/role";
import AddRole from "./components/role/form";
// --- End --- My Role --- End ---

// ----- My User ------
// import AddUser from "./components/myEmployee/form";
import MyEmployee from "./components/employee";
import ViewUser from "./components/employee/view";
// --- End --- My User --- End ---

// ----- My App ------
import AddApp from "./components/app/form";
import MyApp from "./components/app";
// --- End --- My App --- End ---

// ----- Test Mode ------
import AddTestMode from "./components/testMode/form";
// --- End --- Test Mode --- End ---

import Profile from "./components/profile";
import Attendance from "./components/attendance";

// --- End ---- components --- End ---

// ----- Dashboard ------
import Layout from "./layout";
import Dashboard from "./components/dashboard";
// --- End ---- Dashboard --- End ---

// ----- Page-Not-Found ------
import PageNotFound from "./layout/pageNotFound";
import PageUnauthorized from "./layout/pageUnauthorized";
// --- End ---- Page-Not-Found --- End ---

// ----- Settings ------
import Settings from "./components/setting";
// --- End ---- Settings --- End ---

// ----- Projects ------
import Projects from "./components/project";
// --- End ---- Projects --- End ---

import ViewSource from "./components/source";
import Leave from "./components/leave";
import Salary from "./components/salary";
import Holiday from "./components/setting/Holiday";
import Role from "./components/setting/Role";
import Category from "./components/setting/Category";
import Holidays from "./components/profile/holidays";
import SprintForm from "./components/project/SprintForm";
import Attendancess from "./components/profile/attendence";
import Leaves from "./components/profile/leave";
import Salarys from "./components/profile/salary";
import AllEmp from "./components/allEmp";
import EmployeeCheckout from "./components/employee/employeeCheckout";

function App() {
  return (
    <Provider store={store}>
      <Routes>
        {/* Auth */}
        <Route path="/" element={<SignIn />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        {/* --- End --- Auth --- End --- */}

        {/* Components */}

        {/* Dashboard */}
        <Route
          path="/dashboard"
          element={
            <Layout>
              <Dashboard />
            </Layout>
          }
        ></Route>
        {/* --- End --- Dashboard --- End --- */}

        {/* ----- My Role ------ */}
        {/* <Route path="/my-role" element={<Layout><MyRole /></Layout>}></Route>
        <Route path="/add-role" element={<Layout><AddRole /></Layout>}></Route> */}
        {/* --- End --- My Role --- End --- */}

        {/* ----- My User ------ */}
        <Route
          path="/employee"
          element={
            <Layout>
              <MyEmployee />
            </Layout>
          }
        ></Route>
        <Route
          path="/employee-profile"
          element={
            <Layout>
              <ViewUser />
            </Layout>
          }
        ></Route>
        {/* --- End --- My User --- End --- */}

        {/* ----- My App ------ */}
        <Route
          path="/apps"
          element={
            <Layout>
              <MyApp />
            </Layout>
          }
        ></Route>
        <Route
          path="/add-app/:id?"
          element={
            <Layout>
              <AddApp />
            </Layout>
          }
        ></Route>
        <Route
          path="/add-sprint/:id"
          element={
            <Layout>
              <SprintForm />
            </Layout>
          }
        ></Route>
        {/* --- End --- My App --- End --- */}

        {/* ----- Test Mode ------ */}
        <Route
          path="/add-test-data/:id?"
          element={
            <Layout>
              <AddTestMode />
            </Layout>
          }
        ></Route>
        {/* --- End --- Test Mode --- End --- */}

        {/* ----- Attendance Mode ------ */}
        <Route
          path="/attendance"
          element={
            <Layout>
              <Attendance />
            </Layout>
          }
        ></Route>
        {/* --- End --- Attendance Mode --- End --- */}

        {/* ----- Profile ------ */}
        <Route
          path="/profile"
          element={
            <Layout>
              <Profile />
            </Layout>
          }
        ></Route>
        <Route
          path="/allEmployessAttendence"
          element={
            <Layout>
              <AllEmp />
            </Layout>
          }
        ></Route>
        <Route
          path="/employeecheckout"
          element={
            <Layout>
              <EmployeeCheckout />
            </Layout>
          }
        ></Route>
        <Route
          path="/attendances"
          element={
            <Layout>
              <Attendancess />
            </Layout>
          }
        ></Route>
        <Route
          path="/leaves"
          element={
            <Layout>
              <Leaves />
            </Layout>
          }
        ></Route>
        <Route
          path="/salarys"
          element={
            <Layout>
              <Salarys />
            </Layout>
          }
        ></Route>
        <Route
          path="/holidays"
          element={
            <Layout>
              <Holidays />
            </Layout>
          }
        ></Route>
        {/* --- End --- Profile --- End --- */}
        {/* ----- Profile ------ */}
        <Route
          path="/leave"
          element={
            <Layout>
              <Leave />
            </Layout>
          }
        ></Route>
        <Route
          path="/salary"
          element={
            <Layout>
              <Salary />
            </Layout>
          }
        ></Route>
        <Route
          path="/category"
          element={
            <Layout>
              <Category />
            </Layout>
          }
        ></Route>
        <Route
          path="/role"
          element={
            <Layout>
              <Role />
            </Layout>
          }
        ></Route>
        <Route
          path="/holiday"
          element={
            <Layout>
              <Holiday />
            </Layout>
          }
        ></Route>
        {/* --- End --- Profile --- End --- */}

        {/* ----- View Source ------ */}
        <Route path="/app-response" element={<ViewSource />}></Route>
        {/* --- End --- View Source --- End --- */}

        {/* ----- Setting ------ */}
        <Route
          path="/settings"
          element={
            <Layout>
              <Settings />
            </Layout>
          }
        ></Route>
        {/* --- End --- Setting --- End --- */}

        {/* ----- Project ------ */}
        <Route
          path="/projects"
          element={
            <Layout>
              <Projects />
            </Layout>
          }
        ></Route>
        {/* --- End --- Project --- End --- */}

        {/* ---- end ---- Components ---- end ---- */}

        {/* PageNotFound & PageUnauthorized */}
        <Route
          path="*"
          element={
            <Layout>
              <PageNotFound />
            </Layout>
          }
        />
        <Route
          path="PageUnauthorized"
          element={
            <Layout>
              <PageUnauthorized />
            </Layout>
          }
        />
        {/* ---- end ---- PageNotFound & PageUnauthorized ---- end ---- */}
      </Routes>
    </Provider>
  );
}

export default App;
