import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import employee from "../images/profile-2user.svg";
import leaves from "../images/calendar-remove.svg";
import project from "../images/favorite-chart.svg";
import apps from "../images/category (1).svg";
import { ReactComponent as ClockSvg } from "../images/clock.svg";
import { ReactComponent as CalSvg } from "../images/calendar.svg";
import { ReactComponent as EmpSvg } from "../images/profile-2user (1).svg";
import { ReactComponent as SprintSvg } from "../images/calendar (1).svg";
import { ReactComponent as LeaveSvg } from "../images/task-square.svg";
import plusSvg from "../images/plus-circle.svg";
import { getApi } from "../helpers/api";
import { ToastContainer, toast } from "react-toastify";
function Dashboard() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  let navigate = useNavigate();
  const userPermission = JSON.parse(user.permission);
  const [data, setData] = useState([]);
  const [sprint, setSprint] = useState([]);
  const [leave, setLeave] = useState([]);
  const [empcount,setEmpCount]=useState();
  
  const [projectcount, setProjectCount] = useState();
  const [appcount, setAppCount] = useState();
  const [leaveScrollShow,setLeaveScrollShow]=useState(false);
  const [sprintScrollShow, setSprintScrollShow] = useState(false);
  const [empScrollShow, setEmpScrollShow] = useState(false);
  const [toastShown, setToastShown] = useState(false); 
   const todays = new Date(); // Get current date and time
   const year = todays.getFullYear();
   const month = String(todays.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
   const day = String(todays.getDate()).padStart(2, "0");
   const todayDateOnly = `${year}-${month}-${day}`;
  useEffect(() => {
    if (user?.userRole?.role_name !== "admin" && !userPermission?.home) {
      dispatch({ type: "LOGOUT", payload: null });
      localStorage.removeItem(process.env.REACT_APP_SECRET);
      navigate("/");
    }
    fetchEmployeeData();
    fetchSprint();
    getLeave();
  }, []);
  const fetchEmployeeData = async () => {
    await getApi(`all-attendance-dash?date=${today()}`, true).then(async(res) => {
      if (res?.status === 200) {
        setData(res?.data);
         await getApi(`my-employee?dashboard=true`, true).then((resp) => {
       if (resp.status === 200) {         
         setEmpCount((resp?.data?.length - res.data?.length) - 1);
       }
     });     
      }
    });
    

  };
  const fetchSprint = async () => {
    await getApi(`sprint-dash`, true).then((res) => {
      if (res.status === 200) {
        setSprint(res.data);
        setAppCount(res.message.appCount);
        setProjectCount(res.message.projectcount);
      }
    });
    await getApi(`leave-dash`, true).then((res) => {
      if (res.status === 200) {
        setLeave(res.data);
      }
    });
  };
  // Helper function to get today's date in the desired format
  const today = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  function formatDate(dateString) {
    const date = new Date(dateString);
    return date
      .toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      })
      .replace(/ /g, " ");
  }
  function formatDatey(dateString) {
    const date = new Date(dateString);
    return date
      .toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
      })
      .replace(/ /g, " ");
  }
  const getFirstName = (fullName) => {    
    return fullName?.split(" ")[0];
  };
    const getLeave = async () => {
      await getApi(`upcomingholiday`, true)
        .then((res) => {
          if (res.status === 200) {             
            const notify = () => {
              const holidayDateString = res.data.start_date;
              const holidayDate = new Date(holidayDateString);
              const formattedDate = formatDate(holidayDate);
              toast(
                <div>
                  <h6 className="text-dark mb-2">Upcoming Holiday.</h6>
                  <p className="text-dark">{formattedDate} , {res.data.reason}</p>                  
                </div>,
                {
                  className: "dark-toast",
                  progressClassName: "transparent-progress",
                  autoClose: false,
                }
              );
            };
            if (!toastShown) {
            notify();
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
  return (
    <>
      {" "}
      <ToastContainer
        toastClassName="dark-toast"
        progressClassName="transparent-progress"
      />
      <div className="d-flex gap-4 mb-3">
        <div className="col-3 dash-bg">
          <div className="d-flex align-items-center justify-content-between">
            <div className="">
              <h6 className="mb-3">Active Employee</h6>
              <h4>{data?.length}</h4>
            </div>
            <div className="dash-image">
              <img src={employee} alt="emp image" />
            </div>
          </div>
        </div>
        <div className="col-3 dash-bg pbg">
          <div className="d-flex align-items-center justify-content-between">
            <div className="">
              <h6 className="mb-3">Employee on Leave</h6>
              <h4>{empcount}</h4>
            </div>
            <div className="dash-image">
              <img src={leaves} alt="emp leave" />
            </div>
          </div>
        </div>
        <div className="col-3 dash-bg bg">
          <div className="d-flex align-items-center justify-content-between">
            <div className="">
              <h6 className="mb-3">Active Projects</h6>
              <h4>{projectcount}</h4>
            </div>
            <div className="dash-image">
              <img src={project} alt="emp leave" />
            </div>
          </div>
        </div>
        <div className="col-3 dash-bg bgg">
          <div className="d-flex align-items-center justify-content-between">
            <div className="">
              <h6 className="mb-3">Active Apps</h6>
              <h4>{appcount}</h4>
            </div>
            <div className="dash-image">
              <img src={apps} alt="emp leave" />
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex gap-4 flex-wrap">
        <div className="col-4 empdtl">
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="mb-2">
              <EmpSvg /> Employee Login Status
            </h5>
            <h6>{formatDate(todayDateOnly)}</h6>
            <button
              className="border-0"
              onClick={() => navigate("/allEmployessAttendence")}
            >
              <img width="25" height="25" src={plusSvg} alt="plus" />
            </button>
          </div>
          <div
            className={`table-wrap ${
              empScrollShow ? "overflow-y-visible" : ""
            }`}
          >
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Employee</th>
                  <th scope="col" style={{width:105}}>Check In</th>
                  <th scope="col" style={{ width: 105 }}>Check Out</th>
                  <th scope="col">Average</th>
                </tr>
              </thead>
              <tbody>
                {data?.length > 0
                  ? data?.map((data) => {
                      var checkIn = new Date(data.check_in);                    
                    let averageCheckInTime =data?.averageCheckInTime;
                    let h,m;
                    if (averageCheckInTime !== null){
                      let t = averageCheckInTime?.split(':')
                      h=parseInt(t[0]) + 5
                      
                      m=parseInt(t[1]) + 30
                      if(m>60){
                        h=h+1
                        m=m-60
                      }
                    }                                                  
                      var checkOut = new Date(data.check_out)                    
                      return (
                        <tr key={data.id} className="align-middle">
                          <td>
                            <Link
                              to={`/employee-profile?employee=${data?.attendanceUser?.id}`}
                              className="text-dark d-flex gap-3 align-items-center"
                            >
                              <div className="imagewrapdsh">
                                {data?.attendanceUser?.employee_image !== "" &&
                                data?.attendanceUser?.employee_image !== null &&
                                data?.attendanceUser?.employee_image !==
                                  undefined ? (
                                  <img
                                    src={`${process.env.REACT_APP_BASE_URL_UPLOAD}employeeimages/${data?.attendanceUser?.employee_image}`}
                                    alt={data?.attendanceUser?.employee_image}
                                  />
                                ) : (
                                  <div className="employee-profile-text lh">
                                    {data?.attendanceUser?.fullname?.charAt(0)}
                                  </div>
                                )}
                              </div>
                              <p>{data?.attendanceUser?.fullname}</p>
                            </Link>
                          </td>
                          <td>
                            <ClockSvg className="marginrigth" />
                            {/* {data.check_in
                              ? `${checkIn
                                  .getHours()
                                  .toString()
                                  .padStart(2, "0")}:${checkIn
                                  .getMinutes()
                                  .toString()
                                  .padStart(2, "0")}`
                              : "--"} */}
                            {data.check_in
                              ? checkIn.toLocaleTimeString("en-US", {
                                  hour: "numeric",
                                  hour12: true,
                                  minute: "numeric",
                                })
                              : "--"}
                          </td>
                          <td>
                            <ClockSvg className="marginrigth" />                       
                            {data.check_out
                              ? checkOut.toLocaleTimeString("en-US", {
                                  hour: "numeric",
                                  hour12: true,
                                  minute: "numeric",
                                })
                              : "--"}
                          </td>
                          <td>
                            <ClockSvg className="marginrigth" />   
                            {averageCheckInTime ? `${String(h).padStart(2, '0')}:${String(m).padStart(2, '0') }`:"--"}                                                   
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-4 empdtl">
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="mb-2">
              <SprintSvg /> Latest Sprints
            </h5>
            <h6>{formatDate(todayDateOnly)}</h6>
            <button
              className="border-0"
              onClick={() => navigate("/projects")}
            >
              <img width="25" height="25" src={plusSvg} alt="plus" />
            </button>
          </div>

          <div
            className={`table-wrap ${
              sprintScrollShow ? "overflow-y-visible" : ""
            }`}
          >
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Employee</th>
                  <th scope="col">Sprint Title</th>
                  <th scope="col" style={{ width: 180 }}>
                    Date
                  </th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                {sprint?.length > 0
                  ? sprint?.map((data) => {
                      var sdate = formatDatey(data?.start_date);
                      var edate = formatDatey(data?.end_date);
                      return (
                        <tr key={data.id} className="align-middle">
                          <td>
                            <div className="d-flex gap-3 align-items-center text-dark">
                              <div className="imagewrapdsh">
                                {data?.usersprint?.employee_image !== "" &&
                                data?.usersprint?.employee_image !== null &&
                                data?.usersprint?.employee_image !==
                                  undefined ? (
                                  <img
                                    src={`${process.env.REACT_APP_BASE_URL_UPLOAD}employeeimages/${data?.usersprint?.employee_image}`}
                                    alt={data.fullname}
                                  />
                                ) : (
                                  <div className="employee-profile-text lh">
                                    {data?.usersprint?.fullname?.charAt(0)}
                                  </div>
                                )}
                              </div>
                              <p>{getFirstName(data?.usersprint?.fullname)}</p>
                            </div>
                          </td>
                          <td>
                            <Link
                              to={`/add-sprint/${data.project_id}`}
                              className="text-dark"
                            >
                              {data?.title}
                            </Link>
                          </td>
                          <td>
                            <CalSvg className="marginrigth" />
                            {sdate + " - " + edate}
                          </td>
                          <td
                            className={
                              data.status === 1
                                ? "text-info"
                                : data.status === 2
                                ? "text-success"
                                : data.status === 3
                                ? "text-danger"
                                : ""
                            }
                          >
                            {data.status === 1
                              ? "Inprogress"
                              : data.status === 2
                              ? "Complete"
                              : data.status === 3
                              ? "Overdue"
                              : ""}
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-4 empdtl">
          <div className="d-flex gap-4 justify-content-between align-items-center">
            <h5 className="mb-2">
              <LeaveSvg /> Leave Applications
            </h5>
            <h6>{formatDate(todayDateOnly)}</h6>
            <button
              className="border-0"              
              onClick={() => navigate("/leave")}
            >
              <img width="25" height="25" src={plusSvg} alt="plus" />
            </button>
          </div>
          <div
            className={`table-wrap ${
              leaveScrollShow ? "overflow-y-visible" : ""
            }`}
          >
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Employee</th>
                  <th scope="col">Date</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                {leave?.length > 0
                  ? leave?.map((data) => {
                      var sdate = formatDatey(data?.start_date);
                      var edate = formatDatey(data?.end_date);
                      return (
                        <tr key={data.id} className="align-middle">
                          <td>
                            <Link
                              to={`/leave`}
                              className="d-flex gap-3 align-items-center text-dark"
                            >
                              <div className="imagewrapdsh">
                                {data?.userleave?.employee_image !== "" &&
                                data?.userleave?.employee_image !== null &&
                                data?.userleave?.employee_image !==
                                  undefined ? (
                                  <img
                                    src={`${process.env.REACT_APP_BASE_URL_UPLOAD}employeeimages/${data?.userleave?.employee_image}`}
                                    alt={data.fullname}
                                  />
                                ) : (
                                  <div className="employee-profile-text lh">
                                    {data?.userleave?.fullname?.charAt(0)}
                                  </div>
                                )}
                              </div>
                              <p>{getFirstName(data?.userleave?.fullname)}</p>
                            </Link>
                          </td>
                          <td>
                            <CalSvg className="marginrigth" />
                            {sdate + " - " + edate}
                          </td>
                          <td
                            className={
                              data.status === 0
                                ? "text-primary"
                                : data.status === 1
                                ? "text-success"
                                : data.status === 2
                                ? "text-danger"
                                : ""
                            }
                          >
                            {data.status === 0
                              ? "Pending"
                              : data.status === 1
                              ? "Approved"
                              : data.status === 2
                              ? "Rejected"
                              : "Cancel"}
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
