import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { getApi, postApi } from "../../helpers/api";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Dropdown from 'react-bootstrap/Dropdown';
import dot from '../../images/menu.svg'
const SprintForm = () => {
  const [notestatus, setNotesStatus] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [show, setShow] = useState(false);
  const [showTask, setShowTask] = useState(false);
  const [isupdate, setIsUpdate] = useState(false);
  const [updateid, setUpdateId] = useState(0);
  const [projectData, setProjectData] = useState();
  const [users, setUsers] = useState([]);
  const [sprintData, setSprintData] = useState([]);
  const { id } = useParams();
  const [task, setTask] = useState("");  
  const [tasktitle, setTaskTitle] = useState("");
  const [notes, setNotes] = useState("");
  const user = useSelector((state) => state.user.user);
  const {
    id: user_id,
    userRole: { role_name: role },
  } = user;
  const [key, setKey] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseTask = () => setShowTask(false);
  const handleShowTask = () => setShowTask(true);

  const fetchSprintData = async () => {
    if (id) {
      try {
        const url =
          key !== undefined && key !== null && key !== ""
            ? `get-sprints?id=${id}&userid=${key}`
            : `get-sprints?id=${id}`;
        const { data } = await getApi(url, true);
        setSprintData(data);
      } catch (error) {
        console.error("Error fetching sprint data:", error);
      }
    }
  };

  const getProject = async () => {
    if (id) {
      try {
        const { data } = await getApi(`get-project?id=${id}`, true);
        setProjectData(data);
        setUsers([...data?.teamLeader, ...data?.teammember]);
      } catch (error) {
        console.error("Error fetching sprint data:", error);
      }
    }
  };

  useEffect(() => {
    fetchSprintData();
    getProject();
  }, [id, key]);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0"); // Ensures two-digit day
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Ensures two-digit month
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  const notifySuccess = (msg) =>
    toast.success(msg, { theme: "colored", autoClose: 5000 });

  const formik = useFormik({
    initialValues: {
      start_date: "",
      end_date: "",
      title: "",
      task: "",
      final_date: null,
      notes: "",
      status:'',
      priority:'',
      duration:''
    },
    validationSchema:
      role === "admin"
        ? null
        : Yup.object({
            start_date: Yup.date().required("Start date is required"),
            end_date: Yup.date()
              .required("End date is required")
              .min(
                Yup.ref("start_date"),
                "End date can't be before start date"
              ),
            task: Yup.string()
              .required("Module is required")
              .min(5, "Task must be at least 5 characters"),
            title: Yup.string().required("Task Title is required"),
             status: Yup.string()
              .required("status is required"),
               priority: Yup.string()
              .required("priority is required")
          }),
    enableReinitialize: true,
    onSubmit: async (values) => {
      values = {
        ...values,
        project_id: id,
        duration:differenceInDays(new Date(formik.values.start_date),new Date(formik.values.end_date)),
        ...(role !== "admin" && { user_id }),
      };
      const url = isupdate ? `edit-sprint?id=${updateid}` : "add-sprint";
      const res = await postApi(url, values, true);
      if (res.status === 200) {
        handleClose();
        fetchSprintData();
        notifySuccess(res.message);
        setShowForm(false);
        formik.resetForm();
      }
    },
  });

  const getSprintData = async (sprintId) => {
    const res = await getApi(`get-sprint?id=${sprintId}`, true);
    if (res.status === 200) {
      formik.setValues({
        start_date: res.data.start_date,
        end_date: res.data.end_date,
        title: res.data.title,
        task: res.data.task,
        final_date: res.data.final_date,
        notes: res.data.notes,
        status: res.data.status,
        priority:res.data.priority
      });
    }
  };
  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length <= wordLimit) {
      return text;
    }
    return words.slice(0, wordLimit).join(" ") + "...";
  };
  function differenceInDays(date1, date2) {
  const dt1 = new Date(date1);
  const dt2 = new Date(date2);

  const diffTime = Math.abs(dt2 - dt1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

  return diffDays;
}
  const viewInfo = (
    <div className="d-flex gap-4 flex-wrap">
      {sprintData?.length > 0 &&
        sprintData.map((sprint) => {
          return(
          <div className={`mb-2 pb-2 cardsprint ${sprint.status === 1 ? "inprogress" : sprint.status === 2 ? "complete" :sprint.status === 3?"overdue":""}`} key={sprint.id}>
          <div className="mb-3 d-flex justify-content-between align-items-center">
          <h5 className="mb-0">
  {sprint.status === 1
    ? "In Progress"
    : sprint.status === 2
    ? "Complete"
    : sprint.status === 3
    ? "Overdue"
    : ""}
</h5>
 <Dropdown>
      <Dropdown.Toggle className="dotbtn">        
      <img src={dot} alt=''/>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item
                      onClick={() => {
                        handleShowTask();
                        setTask(sprint.task);
                        setNotes(sprint.notes);
                        setTaskTitle(sprint.title);
                      }}
                    >
                      View Sprint
        </Dropdown.Item>
        <Dropdown.Item    
                      onClick={() => {
                        setIsUpdate(true);
                        handleShow();
                        setUpdateId(sprint.id);
                        getSprintData(sprint.id);
                      }}
                    >
                      Edit Sprint
        </Dropdown.Item>       
      </Dropdown.Menu>
    </Dropdown>
              </div>
          <div className="scard" >
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h3 className="text-capitalize text-wraptext-capitalize text-wrap col-8 overflow-hidden">
                {sprint.title}
              </h3>             
            </div>
            {sprint.final_date === "0000-00-00" || sprint.final_date === null ?null: (
              <>
                <small className="text-muted">Final date</small>
                <h5 className="text-danger mb-2">
                  {formatDate(sprint.final_date)}
                </h5>
              </>
            )}
            <div className="d-flex justify-content-between align-items-center mb-2">
              <div>
                <small className="text-muted">create date</small>
                <p>{formatDate(sprint.date)}</p>
              </div>
              <div>
                <small className="text-muted">Start date</small>
                <p className="text-dark">{formatDate(sprint.start_date)}</p>
              </div>
              <div>
                <small className="text-muted">Due date</small>
                <p>{formatDate(sprint.end_date)}</p>
              </div>
            </div>
            <div className="word-break">
              <small className="text-muted">Task</small>
              <p>{truncateText(sprint.task, 20)}</p>
            </div>
            {sprint.notes && (
              <div className="word-break">
                <small className="text-muted">Notes</small>
                <p>{truncateText(sprint.notes, 10)}</p>
              </div>
            )}
          </div>
          </div>
        )})}
    </div>
  );
  return (
    <div>
      <ToastContainer />
      <div className="d-flex justify-content-between align-items-center">
        <h5 className="mb-3">{projectData?.project_name}</h5>
        {role !== "admin" && (
          <button
            type="button"
            className="theme-btn-primary mb-2"
            onClick={handleShow}
          >
            {showForm ? "Hide Form" : "Add Sprint"}
          </button>
        )}
      </div>
      {role === "admin" ? (
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          {users?.length > 0 &&
            users.map((item) => (
              <Tab eventKey={item.value} title={item.label} key={item.value}>
                {viewInfo}
              </Tab>
            ))}
        </Tabs>
      ) : (
        <div className="">{viewInfo}</div>
      )}

      <Modal show={show} onHide={handleClose} className="sprint-modal">
        <Modal.Header closeButton>
          <Modal.Title>{isupdate ? "Update Sprint" : "Add Sprint"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit} className="spgap">
            {role === "admin" ? (
              <div className="form-group">
                <label htmlFor="final_date">Final Date</label>
                <input
                  type="date"
                  name="final_date"
                  id="final_date"
                  value={formik.values.final_date}
                  onChange={formik.handleChange}
                  className="form-control"
                />
                {formik.touched.final_date && formik.errors.final_date && (
                  <div className="text-danger">{formik.errors.final_date}</div>
                )}
              </div>
            ) : (
              <>
              <div className="d-flex gap-3 sprintgap flex-wrap">
                <div className="form-group col-6">
                  <label htmlFor="start_date">Start Date</label>
                  <input
                    type="date"
                    name="start_date"
                    id="start_date"
                    value={formik.values.start_date}
                    onChange={formik.handleChange}
                    className="form-control"
                  />
                  {formik.touched.start_date && formik.errors.start_date && (
                    <div className="text-danger">
                      {formik.errors.start_date}
                    </div>
                  )}
                </div>
                <div className="form-group col-6">
                  <label htmlFor="end_date">End Date</label>
                  <input
                    type="date"
                    name="end_date"
                    id="end_date"
                    value={formik.values.end_date}
                    onChange={formik.handleChange}
                    className="form-control"
                  />
                  {formik.touched.end_date && formik.errors.end_date && (
                    <div className="text-danger">{formik.errors.end_date}</div>
                  )}
                  {/* {formik.values.start_date===null && formik.values.end_date ?null:<small className="text-end">Duration : {differenceInDays(new Date(formik.values.start_date),new Date(formik.values.end_date))} Day</small>} */}
                   {(formik.values.start_date === null || formik.values.start_date === '') || (formik.values.end_date===null ||formik.values.end_date==='') ? null : (
        <small className="text-end">
          Duration : {differenceInDays(new Date(formik.values.start_date),new Date(formik.values.end_date))} {differenceInDays(new Date(formik.values.start_date),new Date(formik.values.end_date)) === 1 ? 'Day' : 'Days'}
        </small>
      )}

                </div>    
                <div className="form-group col-6">
                  <label htmlFor="start_date">Status</label>
                  <select                    
                    name="status"                    
                    value={formik.values.status}
                    onChange={formik.handleChange}
                    className="form-control"
                  >
                    <option value=''>Select Status</option>
                    <option value='3'>Overdue</option>
                    <option value='2'>Complete</option>
                    <option value='1'>In Progress</option>
                  </select>
                  {formik.touched.status && formik.errors.status && (
                    <div className="text-danger">
                      {formik.errors.status}
                    </div>
                  )}
                </div>    
                <div className="form-group col-6">
                  <label htmlFor="start_date">Priority</label>
                  <select                    
                    name="priority"                    
                    value={formik.values.priority}
                    onChange={formik.handleChange}
                    className="form-control"
                  >
                    <option value=''>Select Priority</option>
                    <option value='3'>High</option>
                    <option value='2'>Medium</option>
                    <option value='1'>Low</option>
                  </select>
                  {formik.touched.priority && formik.errors.priority && (
                    <div className="text-danger">
                      {formik.errors.priority}
                    </div>
                  )}
                </div>         
                </div>
                <div className="form-group">
                  <label htmlFor="task">Task Title</label>
                  <input
                    type="text"
                    name="title"
                    id="title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    className="form-control"
                  />
                  {formik.touched.title && formik.errors.title && (
                    <div className="text-danger">{formik.errors.title}</div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="task">Module</label>
                  <textarea
                    name="task"
                    id="task"
                    value={formik.values.task}
                    onChange={formik.handleChange}
                    className="form-control"
                    rows="7"
                  />
                  {formik.touched.task && formik.errors.task && (
                    <div className="text-danger">{formik.errors.task}</div>
                  )}
                </div>
                <button
                  type="button"
                  className="theme-btn-primary py-0 btn-dark mb-2"
                  onClick={() => setNotesStatus((prevStatus) => !prevStatus)}
                >
                    {role === "admin" ? "See Comment" : "Add Comment"}
                </button>
                {notestatus && (
                  <div className="form-group">
                    <label htmlFor="notes">Comments</label>
                    <textarea
                      name="notes"
                      id="notes"
                      value={formik.values.notes}
                      onChange={formik.handleChange}
                      className="form-control"
                      rows="3"
                    />
                    {formik.touched.notes && formik.errors.notes && (
                      <div className="text-danger">{formik.errors.notes}</div>
                    )}
                  </div>
                )}
              </>
            )}
            <div className="clearfix mb-2"></div>
            <div className="text-center">
              <button type="submit" className="theme-btn-primary">
                {isupdate ? "Update Sprint" : "Add Sprint"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <Modal show={showTask} onHide={handleCloseTask}>
        <Modal.Header closeButton>
          <Modal.Title>View Sprint</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="sFrm">
          <div className="form-group">
              <label htmlFor="notes">Task Title</label>
              <textarea               
                value={tasktitle}
                className="form-control"                
                disabled
              />
            </div>
            <div className="form-group">
              <label htmlFor="notes">Task</label>
              <textarea                
                value={task}
                className="form-control"
                rows="8"
                disabled
              />              
            </div>
            {notes && (
              <div className="form-group">
                <label htmlFor="notes">Notes</label>
                <textarea                
                  value={notes}                 
                  className="form-control"
                  rows="3"
                  disabled
                />              
              </div>
            )}
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SprintForm;
