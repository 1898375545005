import React, { useEffect, useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getApi, postApi } from "../../helpers/api";
import { ToastContainer, toast } from "react-toastify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "./employee.css";
import axios from "axios";
function MyEmployee() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let params = useParams();
  let user = useSelector((state) => state.user.user);
  // ---- Commen useState --
  const [isLoding, setIsLoding] = useState(true);
  const [serachRes, setserachRes] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [uploadEmployee, setUploadEmployee] = useState(
    "./images/default-image.png"
  );
  const [IsPending, setPending] = useState(false);
  // ---- Add employee model useState --
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  // ---- Employee useState --
  const [employeeData, setEmployeeData] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [permission, setPermission] = useState({});
  const [peojectPermission, setPeojectPermission] = useState({});
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

  const notifySuccess = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

  useEffect(() => {
    if (user.userRole.role_name === "admin") {
      getAllEmployee(currentPage);
      getRole();
    } else {
      dispatch({ type: "LOGOUT", payload: null });
      localStorage.removeItem(process.env.REACT_APP_SECRET);
      navigate("/");
    }
  }, [serachRes, currentPage]);

  async function getAllEmployee(page) {
    !isLoding && setIsLoding(true);
    let employeeUrl =
      serachRes && page
        ? `my-employee?search=${serachRes}&pageCount=${page}`
        : `my-employee?pageCount=${page}`;
    await getApi(employeeUrl, true)
      .then((response) => {
        let newData = [...response.data];
        setDataCount(Math.ceil(response.dataCount / 10));
        setEmployeeData(response.data);
        setIsLoding(false);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  async function getRole() {
    let roleUrl = `role`;
    await getApi(roleUrl)
      .then((response) => {
        let newData = [...response.data];
        setRoleData(newData);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const fileChangeHandler = (event) => {
    setFieldValue("employee_image", event.target.files[0]);
    if (event.target.files && event.target.files.length) {
      setUploadEmployee(URL.createObjectURL(event.target.files[0]));
    }
  };

  const [initialValues, setInitialValues] = useState({
    fullname: "",
    email: "",
    password: "",
    mobile_no: "",
    gender: "",
    role_id: "",
    dob: "",
    doj: "",
    salary: "",
    work_type: "",
    experience: "",
    education: "",
    address: "",
    employee_image: "",
    salary: "",
  });

  const validationSchema = yup.object({
    fullname: yup.string().required("Fullname is required."),
    email: yup
      .string()
      .required("Email is required.")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Please enter valid email address"
      ),
    password: yup
      .string()
      .min(8, "should be 8 chars minimum.")
      .required("Password is required."),
    mobile_no: yup
      .string()
      .required("Mobile number is required.")
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Phone number is not valid"
      ),
    gender: yup.string().required("Gender is required."),
    salary: yup
      .number()
      .typeError("Salary must be a number") // Ensures the input is a number
      .required("Salary is required"),
    role_id: yup.string().required("Role is required."),
    dob: yup.string().required("Date of Birth is required."),
    doj: yup.string().required("Date of Joining required."),
    work_type: yup.string().required("WorkedAt is required."),
    experience: yup.string().required("Experience is required."),
    address: yup.string().required("Address is required."),
    employee_image: yup
      .mixed()
      .test("size", "Upload less than 1 MB file.", (value) => {
        if (initialValues.employee_image) {
          return true;
        } else if (!value || value.size >= 1048576) {
          return false;
        }
        return true;
      })
      .test("type", "Upload only .jpg, .jpeg, .png", (value) => {
        if (initialValues.employee_image) {
          return true;
        } else if (!value || !SUPPORTED_FORMATS.includes(value.type)) {
          return false;
        }
        return true;
      })
      .required("You need to provide a file."),
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      setPending(true);
      let newData = {
        ...permission,
        ...peojectPermission,
      };
      values = {
        ...values,
        permission: JSON.stringify(newData),
      };
      await postApi("add-employee", values, true, true).then((res) => {
        let result = { ...res };
        if (result.status === 200) {
          getAllEmployee(currentPage);
          closeMenu();
          setPending(false);
        } else {
          notifySuccess(result.data);
          setPending(false);
        }
      });
    },
  });

  async function closeMenu() {
    setShow(false);
    setUploadEmployee("./images/default-image.png");
    resetForm();
  }
  const handleStatus = async (status, id) => {
    const newdata = {
      status: status,
    };
    await postApi(`status-employee/${id}`, newdata, true).then((res) => {
      if (res.status === 200) {
        notifySuccess(res.message);
        getAllEmployee(currentPage);
      }
    });
  };
  const UserPermission = (event) => {
    const { name, checked } = event.target;
    setPermission({
      ...permission,
      [name]: checked,
    });
  };
  const UserPermissionpro = (event) => {    
    const { name, checked } = event.target;
    setPeojectPermission({
      ...peojectPermission,
      [name]: checked,
    });
  };
  const allowPermission = async (id) => {
    let newData = {
      ...permission,
      ...peojectPermission,
    };
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}permission?user=${id}`, {
        permission: JSON.stringify(newData),
      })
      .then((response) => {
        navigate("/employee");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
      <ToastContainer />
      <div className="content-header pb-4">
        <p className="breadcrumb">
          <Link to={"/dashboard"} className="back-link">
            Dashboard
          </Link>
          /<span className="active">All Employees</span>
        </p>
        <div className="content-header-right">
          <div className="content-header-search">
            <span className="search-icon">
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 19.5C15.4183 19.5 19 15.9183 19 11.5C19 7.08172 15.4183 3.5 11 3.5C6.58172 3.5 3 7.08172 3 11.5C3 15.9183 6.58172 19.5 11 19.5Z"
                  stroke="#575A74"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M20.9999 21.4999L16.6499 17.1499"
                  stroke="#575A74"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <input
              type="search"
              id=""
              className=""
              value={serachRes}
              placeholder="Employee name searching..."
              onChange={(search) => setserachRes(search.target.value)}
            />
          </div>
          <button
            className="theme-btn-primary"
            onClick={() => setShow(true)}
            key="add-user-link"
          >
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_86_9054)">
                <path
                  d="M8 2.5C12.8 2.5 14 3.7 14 8.5C14 13.3 12.8 14.5 8 14.5C3.2 14.5 2 13.3 2 8.5C2 3.7 3.2 2.5 8 2.5Z"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10 8.5H6"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8 6.5V10.5"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_86_9054">
                  <rect
                    width="16"
                    height="16"
                    fill="white"
                    transform="translate(0 0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
            &nbsp;&nbsp;Add Employee
          </button>
        </div>
      </div>
      <div className="d-flex gap-4 flex-wrap">
        {!isLoding ? (
          employeeData.length > 0 ? (
            employeeData.map((empVal, empKey) => {
              return (
                <>
                  <div className="col-3 userwrapkk" key={empVal.id}>
                    {/* <Link to={`/employee-profile?employee=${empVal.id}`}> */}
                    <div className="profiles d-flex align-items-center">
                      <Link to={`/employee-profile?employee=${empVal.id}`}>
                        <div
                          to={`/employee-profile?employee=${empVal.id}`}
                          className="profile-img"
                        >
                          {empVal.employee_image !== "" &&
                          empVal.employee_image !== null &&
                          empVal.employee_image !== undefined ? (
                            <img
                              src={`${process.env.REACT_APP_BASE_URL_UPLOAD}employeeimages/${empVal.employee_image}`}
                              alt={empVal.fullname}
                            />
                          ) : (
                            <div className="employee-profile-text">
                              {empVal.fullname?.charAt(0)}
                            </div>
                          )}
                        </div>
                      </Link>
                      <div className="">
                        <Link to={`/employee-profile?employee=${empVal.id}`}>
                          <p className="profile-name">{empVal.fullname}</p>
                        </Link>
                        <Link to={`/employee-profile?employee=${empVal.id}`}>
                          <p className="profile-role">
                            <b>Employee : </b>
                            {empVal.userRole.role_name === "admin"
                              ? "admin"
                              : empVal.userRole.role_name}
                          </p>
                        </Link>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={empVal.status}
                            onChange={() =>
                              handleStatus(
                                empVal.status === 1 ? 0 : 1,
                                empVal.id
                              )
                            }
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                    {/* </Link> */}
                  </div>
                </>
              );
            })
          ) : (
            <div className="text-danger text-center">Not Found.</div>
          )
        ) : (
          <div className="preloader">
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
      </div>
      {dataCount > 10 && (
        <div className="footer-pagination">
          <ul className="paginations d-flex justify-content-end gap-1">
            {Array.apply(null, Array(dataCount)).map((val, key) => {
              return (
                <li
                  key={key}
                  className={`pages-item ${
                    currentPage === key + 1 ? "active" : ""
                  }`}
                  onClick={() => handlePageChange(key + 1)}
                >
                  {key + 1}
                </li>
              );
            })}
          </ul>
        </div>
      )}
      <Modal
        size="lg"
        show={show}
        onHide={closeMenu}
        aria-labelledby="employee-added"
        className="employee-model"
      >
        <Modal.Header closeButton>
          <Modal.Title id="employee-added">Added Employee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex">
            <div className="employee-information col-6">
              <form
                onSubmit={handleSubmit}
                autoComplete="off"
                encType="multipart/form-data"
              >
                <div className="row">
                  <div className="col-md-12">
                    {errors.employee_image && touched.employee_image ? (
                      <span className="required-lable mt-2 d-block text-center">
                        {errors.employee_image}{" "}
                      </span>
                    ) : null}
                    <div className="employee-upload-profile mt-4">
                      <img src={uploadEmployee} alt="employee upload." />
                      <div className="image-upload-btn">
                        <label htmlFor="image-upload">Upload</label>
                        <input
                          type="file"
                          name="employee_image"
                          className="d-none"
                          id="image-upload"
                          onChange={(e) => fileChangeHandler(e)}
                          accept="image/png, image/jpeg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mt-0">
                      <label>
                        Full Name
                        <span className="required-lable">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Fullname"
                        name="fullname"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.fullname}
                      />
                      {errors.fullname && touched.fullname ? (
                        <span className="required-lable">
                          {errors.fullname}{" "}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mt-0">
                      <label>
                        Email<span className="required-lable">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      {errors.email && touched.email ? (
                        <span className="required-lable">{errors.email} </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mt-0">
                      <label>
                        Password<span className="required-lable">*</span>
                      </label>
                      <div className="password-input-container passwordWrap">
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control password-input"
                          placeholder="Password"
                          name="password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                        />
                        <span
                          className="password-toggle-icon"
                          onClick={togglePasswordVisibility}
                        >
                          <FontAwesomeIcon
                            icon={showPassword ? faEyeSlash : faEye}
                          />
                        </span>
                      </div>
                      {errors.password && touched.password ? (
                        <span className="required-lable">
                          {errors.password}{" "}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mt-0">
                      <label>
                        Mobile Number<span className="required-lable">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Mobile Number"
                        name="mobile_no"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.mobile_num}
                      />
                      {errors.mobile_num && touched.mobile_num ? (
                        <span className="required-lable">
                          {errors.mobile_num}{" "}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mt-0">
                      <label>
                        Gender<span className="required-lable">*</span>
                      </label>
                      <div key={`inline-radio`}>
                        <Form.Check
                          inline
                          label="Male"
                          name="gender"
                          type={"radio"}
                          id={`inline-radio-1`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={"male"}
                        />
                        <Form.Check
                          inline
                          label="Female"
                          name="gender"
                          type={"radio"}
                          id={`inline-radio-2`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={"female"}
                        />
                      </div>
                      {errors.gender && touched.gender ? (
                        <span className="required-lable">{errors.gender} </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mt-0">
                      <label>
                        Role<span className="required-lable">*</span>
                      </label>
                      <select
                        name="role_id"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value={""}>Select role</option>
                        {roleData.map((val, key) => {
                          return (
                            <option value={val.id}>{val.role_name}</option>
                          );
                        })}
                      </select>
                      {errors.role_id && touched.role_id ? (
                        <span className="required-lable">
                          {errors.role_id}{" "}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mt-0">
                      <label>
                        Date Of Birth<span className="required-lable">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Date of Birth"
                        name="dob"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.dob}
                      />
                      {errors.dob && touched.dob ? (
                        <span className="required-lable">{errors.dob} </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mt-0">
                      <label>
                        Date of Joining<span className="required-lable">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Date of Joining"
                        name="doj"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.doj}
                      />
                      {errors.doj && touched.doj ? (
                        <span className="required-lable">{errors.doj} </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mt-0">
                      <label>
                        Worked At<span className="required-lable">*</span>
                      </label>
                      <select
                        name="work_type"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.work_type}
                      >
                        <option value={""}>Select Worked type</option>
                        <option value={1}>Freelancer</option>
                        <option value={2}>Work from home</option>
                        <option value={3}>On-site</option>
                      </select>
                      {errors.work_type && touched.work_type ? (
                        <span className="required-lable">
                          {errors.work_type}{" "}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mt-0">
                      <label>
                        Experience<span className="required-lable">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Experience"
                        name="experience"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.experience}
                      />
                      {errors.experience && touched.experience ? (
                        <span className="required-lable">
                          {errors.experience}{" "}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mt-0">
                      <label>Education</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Education"
                        name="education"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.education}
                      />
                      {errors.education && touched.education ? (
                        <span className="required-lable">
                          {errors.education}{" "}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mt-0">
                      <label>
                        Address<span className="required-lable">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        placeholder="Address"
                        name="address"
                        rows={3}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.address}
                      />
                      {errors.address && touched.address ? (
                        <span className="required-lable">
                          {errors.address}{" "}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mt-0">
                      <label>
                        Salary<span className="required-lable">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Salary"
                        name="salary"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.salary}
                      />
                      {errors.salary && touched.salary ? (
                        <span className="required-lable">{errors.salary} </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <button
                      type="submit"
                      className="theme-btn-primary d-block m-auto mb-4"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-6">
              <section className="content">
                <p className="permission-title">Application Permission</p>
                <div className="permission-box">
                  <input
                    type="checkbox"
                    id="app_r"
                    name="app_r"
                    checked={permission.app_r}
                    onChange={UserPermission}
                  />
                  <label htmlFor="app_r">Application List</label>
                  <input
                    type="checkbox"
                    id="app_c"
                    name="app_c"
                    checked={permission.app_c}
                    onChange={UserPermission}
                  />
                  <label htmlFor="app_c">Create App</label>
                  <input
                    type="checkbox"
                    id="app_e"
                    name="app_e"
                    checked={permission.app_e}
                    onChange={UserPermission}
                  />
                  <label htmlFor="app_e">Edit App</label>
                  <input
                    type="checkbox"
                    id="app_d"
                    name="app_d"
                    checked={permission.app_d}
                    onChange={UserPermission}
                  />
                  <label htmlFor="app_d">Delete App</label>
                </div>
                <p className="permission-title">Project Permission</p>
                <div className="permission-box">
                  <input
                    type="checkbox"
                    id="project_r"
                    name="project_r"
                    checked={peojectPermission?.project_r}
                    onChange={UserPermissionpro}
                  />
                  <label htmlFor="project_r">Project List</label>
                  <input
                    type="checkbox"
                    id="project_c"
                    name="project_c"
                    checked={peojectPermission?.project_c}
                    onChange={UserPermissionpro}
                  />
                  <label htmlFor="project_c">Create Project</label>
                  <input
                    type="checkbox"
                    id="project_e"
                    name="project_e"
                    checked={peojectPermission?.project_e}
                    onChange={UserPermissionpro}
                  />
                  <label htmlFor="project_e">Edit Project</label>
                  <input
                    type="checkbox"
                    id="project_d"
                    name="project_d"
                    checked={peojectPermission?.project_d}
                    onChange={UserPermissionpro}
                  />
                  <label htmlFor="project_d">Delete App</label>
                </div>
                <p className="permission-title">Dashboard Permission</p>
                <div className="permission-box mb-0">
                  <input
                    type="checkbox"
                    id="home"
                    name="home"
                    checked={permission.home}
                    onChange={UserPermission}
                  />
                  <label htmlFor="home">Dashboard</label>
                </div>
                {params.id && (
                  <div className="add-submit mt-3">
                    <button
                      type="submit"
                      className="theme-btn-primary"
                      onClick={() => allowPermission(params.id)}
                      disabled={IsPending ? true : false}
                    >
                      {IsPending ? "Loding..." : "Submit"}
                    </button>
                  </div>
                )}
              </section>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default MyEmployee;
