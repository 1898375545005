import React, { useEffect, useState } from 'react';
import { getApi, postApi } from '../../helpers/api';
import { toast } from 'react-toastify';

export default function EmployeeCheckout() {
    const [users, setUsers] = useState([]);
    const [userId, setUserId] = useState('');
    const [checkOutDateTime, setCheckOutDateTime] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const successNotify = (msg) =>
        toast.success(msg, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getApi('my-employee', true);
                if (res.status === 200) {
                    setUsers(res.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const checkout = async () => {
        if (userId === '') {
            setErrorMessage('Please select a user.');
            return;
        }

        if (checkOutDateTime === '') {
            setErrorMessage('Please enter the check-out date and time.');
            return;
        }

        setErrorMessage('');
        const formattedCheckOutDateTime = new Date(checkOutDateTime);
        let newData = {
            date:new Date(),
            check_out: formattedCheckOutDateTime,
            user: userId
        };     

        try {
            const res = await postApi('attendance', newData);
            if (res.status === 200) {
                successNotify('Checkout Successfully.')
            } else {                
                setErrorMessage('Check-out failed. Please try again.');
            }
        } catch (error) {            
            setErrorMessage('An error occurred. Please try again.');
        }
    };

    return (
        <div>
            <form>
                <div className="form-group">
                    <label htmlFor="user_id">User</label>
                    <select
                        name="user_id"
                        value={userId}
                        onChange={(e) => setUserId(e.target.value)}
                    >
                        <option value="">Select User</option>
                        {users.map((user) => (
                            <option key={user.id} value={user.id}>
                                {user.fullname}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="check_out">Check-Out Date and Time</label>
                    <input
                        type="datetime-local" // Use datetime-local input type to allow user to input date and time
                        name="check_out"
                        value={checkOutDateTime}
                        onChange={(e) => setCheckOutDateTime(e.target.value)}
                        required
                    />
                </div>
                {errorMessage && <div className='text-danger text-center'>{errorMessage}</div>}
                <button type='button' onClick={checkout} className='btn btn-dark'>
                    Check Out
                </button>
            </form>
        </div>
    );
}
