import { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import dot from "../../images/menu.svg";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import "./leave.css";
import DatePicker from "react-datepicker";
import cat from "../../images/cat-animal (2).svg";
import { getApi, postApi } from "../../helpers/api";
import { format } from "date-fns";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Pagination from "../Pagination";
const Leave = () => {
  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [smShow, setSmShow] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  /*************Pagination */
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [showLeaveView, setShowLeaveView] = useState(false);

  const [reasonView, setReasonView] = useState("");
  const [leaveTypeView, setLeaveTypeView] = useState("");
  const [type, setType] = useState("");
  const [count, setCount] = useState(0);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = async () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  /***************** */
  const successNotify = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

  const errorNotify = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
      theme: "colored",
    });
  const fetchData = async () => {
    let url =
      sDate && eDate
        ? `leave?sDate=${sDate}&eDate=${eDate}&page=${currentPage}`
        : `leave?page=${currentPage}`;
    if (type !== null && type !== undefined) url += `&type=${type}`;
    await getApi(url, true).then((res) => {
      if (res.status === 200) {
        setData(res.data.data);
        setTotalPages(res.data.totalPages);
        setCount(res.data.count);
      }
    });
  };
  useEffect(() => {
    fetchData();
  }, [currentPage, type]);
  const handleLeave = async (status) => {
    let newdata = {
      status: status,
    };
    await postApi(`leave-approval/${id}`, newdata, true).then((res) => {
      if (res.status === 200) {
        setSmShow(false);
        fetchData();
      }
    });
  };
  let sDate, eDate;
  const SerachDate = async () => {
    sDate = startDate;
    eDate = endDate;
    fetchData(currentPage);
  };
  return (
    <>
      <ToastContainer />
      <div className="d-flex justify-content-between">
        <div className="content-header">
          <p className="breadcrumb">
            <Link to={"/dashboard"} className="back-link">
              Dashboard
            </Link>
            /<span className="active">All Leaves</span>
          </p>
        </div>
        <div className="">
          <div className="search-date mt-3 mt-xl-0">
            <DatePicker
              showIcon
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              placeholderText="From Date"
              dateFormat="dd MMMM yyyy"
            />
            &nbsp;&nbsp;&nbsp;
            <DatePicker
              showIcon
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              placeholderText="To Date"
              dateFormat="dd MMMM yyyy"
            />
            &nbsp;&nbsp;&nbsp;
            <button
              className="theme-btn-primary py-0"
              onClick={SerachDate}
              disabled={
                startDate === undefined || endDate === undefined ? true : false
              }
            >
              Go
            </button>
          </div>
        </div>
        <div className="filterwrap content-header-right">
          <select
            name="type"
            onChange={(e) => {
              setType(e.target.value);
            }}
          >
            <option value="">Select Leave Type</option>
            <option value="0">Pending</option>
            <option value="1">Approved</option>
            <option value="2">Reject</option>
            <option value="3">Cancel</option>
          </select>
        </div>
      </div>
      <table className="table-list">
        <thead>
          <tr>
            <th style={{ width: 200 }}>Name</th>
            <th style={{ width: 150 }}>From</th>
            <th style={{ width: 150 }}>To</th>
            <th style={{ width: 100 }}>Duration</th>
            <th style={{ width: 200 }}>Leave Type</th>
            <th style={{ width: 400 }}>Reason</th>
            <th style={{ width: 120 }}>Status</th>
            <th style={{ width: 20 }}></th>
          </tr>
        </thead>
        <tbody>
          {data?.length ? (
            data?.map((val, key) => {
              return (
                <tr key={key}>
                  <td>{val?.userleave?.fullname}</td>
                  <td>{format(new Date(val.start_date), "dd MMM yyyy")}</td>
                  <td>{format(new Date(val.end_date), "dd MMM yyyy")}</td>
                  <td>
                    {val.duration === "Half Day"
                      ? "Half Leave"
                      : val.duration <= 1
                      ? `${val.duration} Day`
                      : val.duration.includes(":")
                      ? val.duration // If duration includes ":" assume it's formatted correctly
                      : `${Math.round(val.duration)} Days`}
                  </td>
                  <td>{val?.leave_type?.substr(0, 30)}</td>
                  <td>{val?.reason?.substr(0, 100)}</td>
                  <td>
                    <button
                      className={
                        "theme-btn-primary btnwdth " +
                        (val.status === 1
                          ? "btn-success"
                          : val.status === 2
                          ? "btn-danger"
                          : val.status === 3
                          ? "btn-dark"
                          : "btn-warning")
                      }
                      onClick={() => {
                        setId(val.id);
                        setSmShow(true);
                      }}
                    >
                      {val.status === 0
                        ? "Pending..."
                        : val.status === 1
                        ? "Approved"
                        : val.status === 2
                        ? "Rejected"
                        : val.status === 3
                        ? "Cancel"
                        : ""}
                    </button>
                  </td>
                  <td className="text-end">
                    <Dropdown>
                      <Dropdown.Toggle className="dotbtn">
                        <img src={dot} alt="" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                             setShowLeaveView(true);
                             setReasonView(val?.reason);
                             setLeaveTypeView(val?.leave_type);
                          }}
                        >
                          View Reason
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>                   
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={8} className="text-center theme-text-danager">
                Not Found.
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {totalPages > 1 ? (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onNext={handleNextPage}
          onPrev={handlePrevPage}
          handlePageChange={handlePageChange}
        />
      ) : null}
      <Modal
        size="sm"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-sm"
            className="text-center justify-content-center"
          >
            Leave Approvement
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center align-items-center gap-4">
            <div className="imageWrap">
              <img src={cat} alt="cat" />
            </div>
            {/* <h3>Leave Approval!</h3> */}
          </div>
        </Modal.Body>
        <Modal.Footer className="text-center justify-content-center">
          <button
            className="theme-btn-primary btn-success"
            onClick={() => handleLeave(1)}
          >
            Accept
          </button>
          <button
            className="theme-btn-primary btn-danger"
            onClick={() => handleLeave(2)}
          >
            Reject
          </button>
          <button
            className="theme-btn-primary btn-dark"
            onClick={() => handleLeave(3)}
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showLeaveView}
        size="lg"
        onHide={() => setShowLeaveView(false)}
        aria-labelledby="leave-added-model"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="leave-added-model">View Leave</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group mt-0">
                  <h6 className="mb-2">Leave Type</h6>
                  <input
                    type="text"
                    value={leaveTypeView}
                    rows={7}
                    className="border-0 p-0"
                    disabled
                  />
                </div>
                <div className="form-group mt-0">
                  <h6 className="mb-2">Reason</h6>
                  <textarea
                    value={reasonView}
                    rows={7}
                    className="border-0 p-0"
                    disabled
                  />
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Leave;
