import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { NavDropdown } from "react-bootstrap";
import { ReactComponent as ClockSvg } from "../images/clock.svg";
import { ReactComponent as EmpSvg } from "../images/profile-2user (1).svg";
import { getApi } from "../helpers/api";
import DatePicker from "react-datepicker";
import {  
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from "date-fns";
function AllEmp() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  let navigate = useNavigate();
  const userPermission = JSON.parse(user.permission);
  const [data, setData] = useState([]);
  const [serachRes, setserachRes] = useState("");
  const [showTask, setShowTask] = useState(false);
   const [startDate, setStartDate] = useState(new Date());       
    const [tasknotes, setTaskNotes] = useState({
      todo: "",
      checkIn: "",
      todomorning: "",
    });
    const [totalPages, setTotalPages] = useState(1);
    const [currentPages, setCurrentPages] = useState(1);
    const [count, setCount] = useState(0);

    const handleNextPage = () => {
      if (currentPages < totalPages) {
        setCurrentPages(currentPages + 1);
      }
    };

    const handlePrevPage = async () => {
      if (currentPages > 1) {
        setCurrentPages(currentPages - 1);
      }
    };
    const handlePageChanges = (pageNumber) => {
      setCurrentPages(pageNumber);
    };
  const todays = new Date(); // Get current date and time
  const year = todays.getFullYear();
  const month = String(todays.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const day = String(todays.getDate()).padStart(2, "0");
  const todayDateOnly = `${year}-${month}-${day}`;
  useEffect(() => {           
    if (user?.userRole?.role_name !== "admin" && !userPermission?.home) {
      dispatch({ type: "LOGOUT", payload: null });
      localStorage.removeItem(process.env.REACT_APP_SECRET);
      navigate("/");
    }
    fetchEmployeeData();
  }, [currentPages,serachRes,startDate]);
   const todaysd = (dates) => {
     const date = dates ? new Date(dates):new Date();
     const year = date.getFullYear();
     const month = String(date.getMonth() + 1).padStart(2, "0");
     const day = String(date.getDate()).padStart(2, "0");
     return `${year}-${month}-${day}`;
   };
  const fetchEmployeeData = async () => {   
    let AttendanceUrl = "all-attendance-dash?";
    let params = [];

    if (startDate) {
      params.push(`date=${todaysd(startDate)}`);
    }
    if (currentPages != null) {
      params.push(`pageCount=${currentPages}`);
    }
    if (serachRes) {
      params.push(`name=${serachRes}`);
    }
     AttendanceUrl= AttendanceUrl + params.join("&");     
     await getApi(AttendanceUrl, true).then(
      async (res) => {
        if (res?.status === 200) {
          setData(res?.data);          
        }
      }
    );
  }; 
   
  // Helper function to get today's date in the desired format
  const today = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  function formatDate(dateString) {
    const date = new Date(dateString);
    return date
      .toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      })
      .replace(/ /g, " ");
  }
  function formatDatey(dateString) {
    const date = new Date(dateString);
    return date
      .toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
      })
      .replace(/ /g, " ");
  }
  const getFirstName = (fullName) => {
    return fullName?.split(" ")[0];
  };  
    const handleCalculateDifference = (start, end) => {
      const diffInHours = differenceInHours(end, start);
      const diffInMinutes = differenceInMinutes(end, start) % 60;
      const diffInSeconds = differenceInSeconds(end, start) % 60;

      return {
        hours: diffInHours,
        minutes: diffInMinutes,
        seconds: diffInSeconds,
      };
    };
     const showMyTask = async (id) => {
       await getApi(`all-attendance?my_task=${id}`, true)
         .then((response) => {          
           setTaskNotes({ todo: response.data.attendance[0] });
           setShowTask(true);
         })
         .catch((err) => {
           console.log(err);
         });
     };          
  return (
    <>
      <div className="d-flex gap-4 flex-wrap">
        <div className="w-100">
         <div className="d-flex justify-content-between align-items-center mb-3">
            <h5 className="mb-2">
              <EmpSvg /> Employee Login Status
            </h5>
          <div className="d-flex align-items-center justify-content-end">
            <div className="content-header-search">
              <span className="search-icon">
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 19.5C15.4183 19.5 19 15.9183 19 11.5C19 7.08172 15.4183 3.5 11 3.5C6.58172 3.5 3 7.08172 3 11.5C3 15.9183 6.58172 19.5 11 19.5Z"
                    stroke="#575A74"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M20.9999 21.4999L16.6499 17.1499"
                    stroke="#575A74"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <input
                type="search"
                id=""
                className="marginright"
                value={serachRes}
                placeholder="Search"
                onChange={(search) => setserachRes(search.target.value)}
              />
            </div>
            <div className="search-date mt-xl-0">
              <DatePicker
                showIcon
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                placeholderText="Select Date"
                dateFormat="dd MMMM yyyy"
                className="marginright"
              />             
            </div>
          </div>  
          </div>      
          <div className={`table-wrap`}>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Employee</th>
                  <th scope="col">Check In</th>
                  <th scope="col">Lunch Time</th>
                  <th scope="col">Tea Time</th>
                  <th scope="col">WH Leave Time</th>
                  <th scope="col">Check Out</th>
                  <th scope="col">Hours</th>
                  <th scope="col" className="text-center">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.length > 0
                  ? data?.map((data) => {
                      var checkIn = new Date(data.check_in);
                      var checkOut = data.check_out && new Date(data.check_out);
                      var checkInLunch =
                        data.check_in_lunch && new Date(data.check_in_lunch);
                      var checkOutLunch =
                        data.check_out_lunch && new Date(data.check_out_lunch);
                      var lunchTime = handleCalculateDifference(
                        checkInLunch,
                        checkOutLunch
                      );
                      var checkInTea =
                        data.check_in_tea && new Date(data.check_in_tea);

                      var checkOutTea =
                        data.check_out_tea && new Date(data.check_out_tea);
                      var TeaTime = handleCalculateDifference(
                        checkInTea,
                        checkOutTea
                      );
                      var checkInwh =
                        data.check_in_wh && new Date(data.check_in_wh);

                      var checkOutwh =
                        data.check_out_wh && new Date(data.check_out_wh);
                      var whTime = handleCalculateDifference(
                        checkInwh,
                        checkOutwh
                      );
                      const formatDuration = (duration) => {
                        if (
                          duration.hours === 0 &&
                          duration.minutes === 0 &&
                          duration.seconds !== 0
                        ) {
                          return `${duration.seconds} Seconds`;
                        } else {
                          return `${
                            duration.hours ? `${duration.hours} Hour ` : ""
                          }${
                            duration.minutes
                              ? `${duration.minutes} Minutes `
                              : ""
                          }`;
                        }
                      };
                      return (
                        <tr key={data.id} className="align-middle">
                          <td>
                            <Link
                              to={`/employee-profile?employee=${data?.attendanceUser?.id}`}
                              className="text-dark d-flex gap-3 align-items-center"
                            >
                              <div className="imagewrapdsh">
                                {data?.attendanceUser?.employee_image !== "" &&
                                data?.attendanceUser?.employee_image !== null &&
                                data?.attendanceUser?.employee_image !==
                                  undefined ? (
                                  <img
                                    src={`${process.env.REACT_APP_BASE_URL_UPLOAD}employeeimages/${data?.attendanceUser?.employee_image}`}
                                    alt={data?.attendanceUser?.employee_image}
                                  />
                                ) : (
                                  <div className="employee-profile-text lh">
                                    {data?.attendanceUser?.fullname?.charAt(0)}
                                  </div>
                                )}
                              </div>
                              <p>{data?.attendanceUser?.fullname}</p>
                            </Link>
                          </td>
                          <td>
                            <ClockSvg className="marginrigth" />
                            {data.check_in
                              ? checkIn.toLocaleTimeString("en-US", {
                                  hour: "numeric",
                                  hour12: true,
                                  minute: "numeric",
                                })
                              : "--"}
                          </td>
                          <td>
                            {" "}
                            {formatDuration(lunchTime) === ""
                              ? "--"
                              : formatDuration(lunchTime)}
                          </td>
                          <td>
                            {" "}
                            {formatDuration(TeaTime) === ""
                              ? "--"
                              : formatDuration(TeaTime)}
                          </td>
                          <td>
                            {formatDuration(whTime) === ""
                              ? "--"
                              : formatDuration(whTime)}
                          </td>
                          <td>
                            <ClockSvg className="marginrigth" />
                            {data.check_out
                              ? checkOut.toLocaleTimeString("en-US", {
                                  hour: "numeric",
                                  hour12: true,
                                  minute: "numeric",
                                })
                              : "--"}
                          </td>
                          <td>{data.total_hours ? data.total_hours : "--"}</td>

                          <td className="text-center">
                            <NavDropdown title="Action">
                              <NavDropdown.Item>
                                <div
                                  className="action-btn"
                                  onClick={() => showMyTask(data.id)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="#575A74"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                      fill="none"
                                    ></path>
                                    <path d="M5 3m0 2a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2z"></path>
                                    <path d="M9 7l6 0"></path>
                                    <path d="M9 11l6 0"></path>
                                    <path d="M9 15l4 0"></path>
                                  </svg>
                                  <div className="action-text">Task</div>
                                </div>
                              </NavDropdown.Item>
                            </NavDropdown>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
            <Modal
              show={showTask}
              size="lg"
              onHide={() => setShowTask(false)}
              aria-labelledby="example-custom-modal-styling-title"
              centered
              className="taskmodal"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                  Task Notes
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {(tasknotes.todo?.todomorning === null ||
                  tasknotes.todo?.todomorning === undefined ||
                  tasknotes.todo?.todomorning === "") &&
                (tasknotes.todo?.todo === null ||
                  tasknotes.todo?.todo === undefined ||
                  tasknotes.todo?.todo === "") ? (
                  <h5 className="text-center mt-2 mb-2">No Task Found</h5>
                ) : (
                  <div className="form-group m-0">
                    <h5 className="mb-2">
                      {/* {filteredData[0]?.projects?.toString()} */}
                    </h5>
                    {tasknotes.todo?.todomorning === null ||
                    tasknotes.todo?.todomorning === undefined ||
                    tasknotes.todo?.todomorning === "" ? null : (
                      <p className="mb-4">
                        <b>Before Lunch Task :</b>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: tasknotes.todo?.todomorning.replace(
                              /\n/g,
                              "<br>"
                            ),
                          }}
                        ></p>
                        {/* <p>{tasknotes.todo?.todomorning}</p> */}
                      </p>
                    )}
                    {tasknotes.todo?.todo === null ||
                    tasknotes.todo?.todo === undefined ||
                    tasknotes.todo?.todo === "" ? null : (
                      <p className="mb-4">
                        <b>After Lunch Task :</b>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: tasknotes.todo?.todo.replace(/\n/g, "<br>"),
                          }}
                        ></p>
                      </p>
                    )}
                  </div>
                )}
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllEmp;
