import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import "./layout.css";
import { getApi, postApi } from "../helpers/api";
import { ToastContainer } from "react-bootstrap";
import { ReactComponent as EmployeeSvg } from "../images/user.svg";
import { ReactComponent as ClockSvg } from "../images/timezone-icon.svg";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from "react-toastify";
function Navbar() {
  let navigate = useNavigate();
  const user = useSelector((state) => state.user.user);  
  const dispatch = useDispatch();  
  const role = user?.userRole?.role_name;  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const[startTime,setStartTime]=useState('')
  const[endTime,setEndTime]=useState('');
  const handleCloses = () => {
    setAnchorEl(null);
  };
  const successNotify = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
const handleSubmit =async()=>{
  console.log("***************",startTime,endTime)
  const newData ={
    start_time:startTime,
    end_time:endTime
  }
  await postApi("add-time",newData,true).then(res=>{
    if(res.status===200){
      handleClose();
      handleClose();
      successNotify(res.message)
    }
  })
}
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getApi("get-time", true);
        if(res.status===200){
          setStartTime(res.data.start_time)
          setEndTime(res.data.end_time)
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const SignOut = () => {
    dispatch({ type: "LOGOUT", payload: null });
    localStorage.removeItem(process.env.REACT_APP_SECRET);
    navigate("/");
  };  

  return (
    <nav className="main-header">      
      <ul className="nav-bar">
        <p className="nav-head">Hello, {user.fullname}</p>
      </ul>
      <ul className="nav-bar">       
        <div
          className="user-panal"
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <div
            className="user-avatar"
            style={
              !user.user_image
                ? { backgroundColor: "#010226" }
                : { backgroundColor: "unset" }
            }
          >
            {user && user.user_image ? (
              <img
                src={`${process.env.REACT_APP_BASE_URL}uploads/userimages/${user.user_image}`}
                alt="user imag"
              />
            ) : (
              <p className="user-name-avatar">{user.fullname?.charAt(0)}</p>
            )}
          </div>
          <p className="user-info">{user && user.fullname}</p> &nbsp;&nbsp;
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_76_141)">
              <path
                d="M6 10L12 16L18 10H6Z"
                stroke="#575A74"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_76_141">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloses}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          className="uuu"
        >         
          <MenuItem onClick={handleCloses}>
            <button
              className="menuitem border-0 bg-transparent usercolorstoke"
              onClick={() => navigate("/profile")}
            >
              <EmployeeSvg/>
              <div className="menu-item">Profile</div>
            </button>
          </MenuItem>
          <MenuItem onClick={handleCloses}>
            <button
              className="menuitem border-0 bg-transparent"
              onClick={SignOut}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_238_20)">
                  <path
                    d="M14 8V6C14 5.46957 13.7893 4.96086 13.4142 4.58579C13.0391 4.21071 12.5304 4 12 4H5C4.46957 4 3.96086 4.21071 3.58579 4.58579C3.21071 4.96086 3 5.46957 3 6V18C3 18.5304 3.21071 19.0391 3.58579 19.4142C3.96086 19.7893 4.46957 20 5 20H12C12.5304 20 13.0391 19.7893 13.4142 19.4142C13.7893 19.0391 14 18.5304 14 18V16"
                    stroke="#575A74"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9 12H21L18 9"
                    stroke="#575A74"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M18 15L21 12"
                    stroke="#575A74"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_238_20">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <div className="menu-item">Logout</div>
            </button>
          </MenuItem>
          {
            role === "admin" ? <MenuItem onClick={() => {handleCloses();handleShow()}}>
              <button
                className="menuitem border-0 bg-transparent usercolorstoke"                
              >
                <ClockSvg width="24" height="24"/>
                <div className="menu-item">Timezone</div>
              </button>
            </MenuItem>:null
          }
        </Menu>
      </ul>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change Start and End Time</Modal.Title>
        </Modal.Header>
        <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
          <Modal.Body>
            <table className="table table-borderless mb-0">
              <tbody>
                <tr className="align-middle">
                  <td style={{width:100}}>Start Time</td>
                  <td>
                    <div className="form-group mt-0 mb-0">
                      <input
                        type="time"
                        name="start_time"
                        value={startTime}
                        onChange={(e) => setStartTime(e.target.value)}
                        placeholder="Enter Start Time"
                        required
                        pattern="^([0-1]?[0-9]|2[0-3]):([0-5][0-9])$"
                        className="form-control"
                      />
                    </div>
                  </td>
                </tr>
                <tr className="align-middle">
                  <td>End Time</td>
                  <td>
                    <div className="form-group mt-0 mb-0">
                      <input
                        type="time"
                        name="end_time"
                        value={endTime}
                        onChange={(e) => setEndTime(e.target.value)}
                        placeholder="Enter End Time"
                        required
                        pattern="^([0-1]?[0-9]|2[0-3]):([0-5][0-9])$"
                        className="form-control"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={()=>{handleClose();handleClose()}}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>   
    </nav>
  );
}

export default Navbar;
