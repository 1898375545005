import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { NavDropdown } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getApi, postApi } from "../../helpers/api";
import "./profile.css";
import Select from "react-select";
import { ReactComponent as LunchsSVG } from "../../images/lunch.svg";
import { ReactComponent as TeasSVG } from "../../images/tea.svg";
import { ReactComponent as WhSVG } from "../../images/leave (1).svg";
import { ReactComponent as COSVG } from "../../images/check in out.svg";
import {  
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from "date-fns";
import Pagination from "../Pagination";
import { useStopwatch } from 'react-timer-hook';
import MyStopwatch from "./clock";
const Attendancess = () => { 
  const today = new Date();
  const todayString = today?.toISOString()?.split("T")[0];
  const [IsPending, setPending] = useState(false);
  const [IsPendingBtn, setIsPendingBtn] = useState(false);
  const user = useSelector((state) => state.user.user);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [isLoding, setIsLoding] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataCount, setDataCount] = useState(0);
  const [attendanceData, setAttendanceData] = useState([]);
  const [title, setTitle] = useState("");
  const [actionMenu, setActionMenu] = useState({ id: null, status: "d-none" });
  const filteredData = attendanceData.filter(
    (item) => item.date === todayString
  );
  const [show, setShow] = useState(false);
  const [showTask, setShowTask] = useState(false);
  const [tasknotes, setTaskNotes] = useState({
    todo: "",
    checkIn: "",
    todomorning: "",
  });
  // Leave 
  const [project, setProject] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [errors, setErrors] = useState("");
  /*************Pagination */
  const [totalPages, setTotalPages] = useState(1);
  const [currentPages, setCurrentPages] = useState(1);
  const [count, setCount] = useState(0);

  const handleNextPage = () => {
    if (currentPages < totalPages) {
      setCurrentPages(currentPages + 1);
    }
  };

  const handlePrevPage = async () => {
    if (currentPages > 1) {
      setCurrentPages(currentPages - 1);
    }
  };
  const handlePageChanges = (pageNumber) => {
    setCurrentPages(pageNumber);
  };
  /***************** */
  // --- ---
  let CurrentDate = new Date();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let todayDate =
    CurrentDate.getDate() +
    " " +
    months[CurrentDate.getMonth()] +
    " " +
    CurrentDate.getFullYear();

  const successNotify = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

  const errorNotify = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
      theme: "colored",
    });

  useEffect(() => {
    getAllAttendance(currentPages);
     getLeave();
  }, [currentPages]);

  async function getAllAttendance(page) {
    !isLoding && setIsLoding(true);
    let AttendanceUrl =
      sDate && eDate
        ? `all-attendance?sDate=${sDate}&eDate=${eDate}&pageCount=${page}`
        : `all-attendance?pageCount=${page}`;
    await getApi(AttendanceUrl, true)
      .then((res) => {
        let newData = { ...res.data };
        if (res.status === 200) {
          setDataCount(newData.attendanceCount);
          setAttendanceData(newData.attendance);
          setIsLoding(false);
          setCurrentPages(newData.currentPage);
          setTotalPages(newData.totalPages);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    await getApi("projects", true).then((res) => {
      if (res.status === 200) {
        setProject(res.data);
      }
    });    
  }

  let sDate, eDate;
  const SerachDate = async () => {
    sDate = startDate;
    eDate = endDate;
    getAllAttendance(currentPage);
  };

  const CheckIn = async (title) => {
    setPending(true);
    let checkInJson = { date: new Date(), [title]: new Date(), user: user.id };
    await postApi("attendance", checkInJson)
      .then((response) => {
        let newData = { ...response.data };
        if (response.status === 200 && newData[1]) {
          successNotify(newData[1]);
          getAllAttendance(currentPage);          
        }
        if (response.status === 200 && newData[0]) {
          getAllAttendance(currentPage);          
        }
        setPending(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const checkOut = async (titles = null) => {
    // Get the project values
    let project = [];
    let ab = [];
    // Retrieve and split the existing project data if it is a string
    // selectedOption?.map((item) => item.value);
    if (
      filteredData[0]?.project === null ||
      filteredData[0]?.project === undefined ||
      filteredData[0]?.project === ""
    ) {
      ab = [];
    } else {
      ab = filteredData[0]?.project?.split(",")?.map(Number);
    }
    if (
      selectedOption === null ||
      selectedOption === undefined ||
      selectedOption === ""
    ) {
      setErrors("Select Projects");
    } else {
      project = selectedOption.map((item) => item.value);
    }
    // Determine the title
    let ss = titles === null ? title : titles;

    // Merge and sort projects if the title is "check_out"
    const finalProject =
      ss === "check_out"
        ? Array.from(new Set([...ab, ...project]))
            ?.sort((a, b) => a - b)
            ?.toString()
        : project?.toString();

    // Set button state to pending
    // Check conditions for checkOutJson creation
    if (
      tasknotes !== "" ||
      ss === "check_out_lunch" ||
      ss === "check_out_tea" ||
      ss === "check_in_wh" ||
      ss === "check_out_wh" ||
      ss === "check_in_tea"
    ) {
      let checkOutJson = {
        date: new Date(),
        [ss]: new Date(),
        user: user.id,
        todo: tasknotes,
        project: finalProject,
      };

      // Uncomment and handle API call if necessary
      try {
        if (
          selectedOption?.length !== 0 ||
          ss === "check_out_lunch" ||
          ss === "check_out_tea" ||
          ss === "check_in_wh" ||
          ss === "check_out_wh" ||
          ss === "check_in_tea"
        ) {
          setIsPendingBtn(true);
          await postApi("attendance", checkOutJson).then(res=>{
            if(res.status===200){             
              console.log("--------------------", ss)
              if (ss === "check_in_lunch" || ss ==="check_out_lunch"){
                // handleBreak('lunch')
              }
              if (ss === "check_in_wh" || ss === "check_out_wh") {
                // handleBreak('wh')
              }
              if (ss === "check_in_tea" || ss === "check_out_tea") {
                // handleBreak('tea')
              }
            }
          });
          setIsPendingBtn(false);
          setShow(false);
          getAllAttendance(currentPage);
          setSelectedOption([]);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      errorNotify("Notes should be written down.");
    }
  };

  const showTaskNotes = async (title) => {
    setPending(true);
    setTaskNotes("");
    await getApi(`all-attendance?checkindate=${new Date()}`, true)
      .then((result) => {
        setPending(false);
        let newData = { ...result.data };

        if (!newData.attendance) {
          errorNotify("You're not a check-in employee.");
        } else if (newData.attendance[0].check_out) {
          errorNotify("You're already checked out.");
        } else {
          setTitle(title);
          setShow(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const showMyTask = async (id) => {
    await getApi(`all-attendance?my_task=${id}`, true)
      .then((response) => {
        setActionMenu({ id: id, status: "d-none" });
        setTaskNotes({ todo: response.data.attendance[0] });
        setShowTask(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  function roundToHalf(value) {
    const converted = parseFloat(value); // Ensure the value is a number
    const decimal = converted - Math.floor(converted);
    let roundedValue =
      decimal >= 0.1 && decimal < 0.75
        ? Math.floor(converted) + 0.5
        : Math.round(converted);
    return roundedValue.toFixed(1);
  }
  // Helper function to add the time part to the current date  
  const calculateLeaveDuration = (
    start_date,
    end_date,
    start_time,
    end_time
  ) => {
    const startDateTime = new Date(`${start_date}T${start_time}`);
    const endDateTime = new Date(`${end_date}T${end_time}`);

    // Calculate the duration in hours
    const durationInHours = (endDateTime - startDateTime) / (1000 * 60 * 60);
    // Convert hours to fractions of a day
    const durationInDays = durationInHours / 24;
    if (durationInHours < 24) {
      if (durationInHours <= 4) {
        return 0.5;
      } else {
        return 1;
      }
    }

    // Round to the nearest 0.5
    const roundedDuration = (durationInDays * 2) / 2;

    const roundedNumber = roundToHalf(roundedDuration);
    return roundedNumber;
  };
  const options = project?.map((project) => ({
    value: project.id,
    label: project.project_name,
  }));
  function getTimeDifferenceInHours(startTime, endTime) {
    const [startHour, startMinute] = startTime.split(":").map(Number);
    const [endHour, endMinute] = endTime.split(":").map(Number);

    const startDate = new Date();
    startDate.setHours(startHour, startMinute, 0, 0);

    const endDate = new Date();
    endDate.setHours(endHour, endMinute, 0, 0);

    // If end time is before start time, assume it is the next day
    if (endDate < startDate) {
      endDate.setDate(endDate.getDate() + 1);
    }

    const diffInMilliseconds = endDate - startDate;
    const diffInHours = diffInMilliseconds / (1000 * 60 * 60);

    return diffInHours;
  }
 
  const parseDate = (dateStr) => {
    const [year, month, day] = dateStr?.split("-")?.map(Number);
    return new Date(year, month - 1, day); // month is 0-indexed in JavaScript Date
  };
  const handleCalculateDifference = (start, end) => {
    const diffInHours = differenceInHours(end, start);
    const diffInMinutes = differenceInMinutes(end, start) % 60;
    const diffInSeconds = differenceInSeconds(end, start) % 60;

    return {
      hours: diffInHours,
      minutes: diffInMinutes,
      seconds: diffInSeconds,
    };
  };
    function formatDate(dateString) {
      const date = new Date(dateString);
      return date
        .toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        })
        .replace(/ /g, " ");
    }
     const getLeave = async () => {
       await getApi(`upcomingholiday`, true)
         .then((res) => {
           if (res.status === 200) {
             const notify = () => {
               const holidayDateString = res.data.start_date;
               const holidayDate = new Date(holidayDateString);
               const formattedDate = formatDate(holidayDate);
               toast(
                 <div>
                   <h6 className="text-dark mb-2">Upcoming Holiday.</h6>
                   <p className="text-dark text-capitalize">
                     {formattedDate} , {res.data.reason}
                   </p>
                 </div>,
                 {
                   className: "dark-toast",
                   progressClassName: "transparent-progress",
                   autoClose: false,
                 }
               );
             };
             notify();
           }
         })
         .catch((error) => {
           console.log(error);
         });
     };
  return (
    <>
      <ToastContainer
        toastClassName="dark-toast"
        progressClassName="transparent-progress"
      />
      <section className="content user-profile">
        {/* <Tabs defaultActiveKey="profile_user_attendances"> */}
        {/* <Tab eventKey="profile_user_attendances" title="Attendances"> */}
        {/* <MyStopwatch/> */}
        <div className="profile-tab">
          <div className="attendance-head">
            <h4 className="current-time">Timesheet {todayDate}</h4>
            <div className="search-date mt-xl-0">
              <DatePicker
                showIcon
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                placeholderText="From Date"
                dateFormat="dd MMMM yyyy"
              />
              &nbsp;&nbsp;&nbsp;
              <DatePicker
                showIcon
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                placeholderText="To Date"
                dateFormat="dd MMMM yyyy"
              />
              &nbsp;&nbsp;&nbsp;
              <button
                className="theme-btn-primary py-0"
                onClick={SerachDate}
                disabled={
                  startDate === undefined || endDate === undefined
                    ? true
                    : false
                }
              >
                Go
              </button>
            </div>
            {attendanceData[0]?.is_employee_leave !== 1 ?
              <div className="check-in-out-btn mt-3 mt-xl-0">
                {attendanceData[0]?.check_in !== undefined &&
                attendanceData[0]?.date === todayString &&
                  attendanceData[0]?.is_employee_leave!==1? (
                  <>
                    <button
                      className="check-in-btn lunch"
                      // onClick={() => CheckIn("check_in_lunch")}
                      onClick={() => {
                        if (attendanceData[0]?.check_in_lunch) {
                          // setTitle("check_out_lunch");
                          checkOut("check_out_lunch");
                        } else {
                          showTaskNotes("check_in_lunch");
                        }
                      }}
                      disabled={
                        IsPending ||
                        attendanceData[0]?.check_out_lunch ||
                        attendanceData[0]?.check_out ||
                        (attendanceData[0]?.check_in_tea !== null &&
                          attendanceData[0]?.check_out_tea === null) ||
                        (attendanceData[0]?.check_in_wh !== null &&
                          attendanceData[0]?.check_out_wh === null)
                          ? true
                          : false
                      }
                    >
                      <LunchsSVG />
                      {/* <WatchSVG /> */}
                      &nbsp;{" "}
                      {IsPending
                        ? "Loding..."
                        : attendanceData[0]?.check_in_lunch
                        ? "End Lunch"
                        : "Lunch"}
                    </button>
                    <button
                      className="check-in-btn tea"
                      onClick={() => {
                        if (attendanceData[0]?.check_in_tea) {
                          // setTitle("check_out_tea");
                          checkOut("check_out_tea");
                        } else {
                          checkOut("check_in_tea");
                        }
                      }}
                      disabled={
                        IsPending ||
                        attendanceData[0]?.check_out_tea ||
                        (attendanceData[0]?.check_in_lunch !== null &&
                          attendanceData[0]?.check_out_lunch === null) ||
                        (attendanceData[0]?.check_in_wh !== null &&
                          attendanceData[0]?.check_out_wh === null) ||
                        attendanceData[0]?.check_out !== null
                          ? true
                          : false
                      }
                    >
                      <TeasSVG />
                      {/* <WatchSVG /> */}
                      &nbsp;{" "}
                      {IsPending
                        ? "Loding..."
                        : attendanceData[0]?.check_in_tea
                        ? "End Tea"
                        : "Tea"}
                    </button>
                    <button
                      className="check-in-btn wh"
                      onClick={() => {
                        if (attendanceData[0]?.check_in_wh) {
                          // setTitle("check_out_tea");
                          checkOut("check_out_wh");
                        } else {
                          checkOut("check_in_wh");
                        }
                      }}
                      disabled={
                        IsPending ||
                        attendanceData[0]?.check_out_wh ||
                        attendanceData[0]?.check_out ||
                        (attendanceData[0]?.check_in_lunch !== null &&
                          attendanceData[0]?.check_out_lunch === null) ||
                        (attendanceData[0]?.check_in_tea !== null &&
                          attendanceData[0]?.check_out_tea === null)
                          ? true
                          : false
                      }
                    >
                      <WhSVG />
                      {/* <WatchSVG /> */}
                      &nbsp;{" "}
                      {IsPending
                        ? "Loding..."
                        : attendanceData[0]?.check_in_wh
                        ? "End WH Leave"
                        : "WH Leave"}
                    </button>
                    <button
                      className="check-out-btn out"
                      onClick={() => showTaskNotes("check_out")}
                      disabled={
                        IsPending ||
                        (attendanceData[0]?.check_in_lunch !== null &&
                          attendanceData[0]?.check_out_lunch === null) ||
                        (attendanceData[0]?.check_in_tea !== null &&
                          attendanceData[0]?.check_out_tea === null) ||
                        (attendanceData[0]?.check_in_wh !== null &&
                          attendanceData[0]?.check_out_wh === null) ||
                        attendanceData[0]?.check_out !== null
                          ? true
                          : false
                      }
                    >
                      <COSVG />
                      &nbsp; {IsPending ? "Loding..." : "Check Out Time"}
                    </button>
                  </>
                ) : (
                  <button
                    className="check-in-btn"
                    onClick={() => CheckIn("check_in")}
                    disabled={IsPending ? true : false}
                  >
                    <COSVG />
                    &nbsp; {IsPending ? "Loding..." : "Check In Time"}
                  </button>
                )}
              </div>:null
            }
          </div>
          <div>
            <table className="table-list">
              <thead>
                <tr>
                  <th className="attend-filed">Date</th>
                  <th className="attend-filed">Check In</th>
                  <th className="attend-filed">Lunch Time</th>
                  <th className="attend-filed">Tea Time</th>
                  <th className="attend-filed">WH Leave Time</th>
                  <th className="attend-filed">Check Out</th>
                  <th className="attend-filed">Hours</th>
                  <th className="table-action">Action</th>
                </tr>
              </thead>
              <tbody>
                {!isLoding ? (
                  attendanceData.length ? (
                    attendanceData.map((attendval, attendkey) => {
                      var checkIn = new Date(attendval.check_in);
                      var checkOut =
                        attendval.check_out && new Date(attendval.check_out);
                      var checkInLunch =
                        attendval.check_in_lunch &&
                        new Date(attendval.check_in_lunch);
                      var checkOutLunch =
                        attendval.check_out_lunch &&
                        new Date(attendval.check_out_lunch);
                      var lunchTime = handleCalculateDifference(
                        checkInLunch,
                        checkOutLunch
                      );
                      var checkInTea =
                        attendval.check_in_tea &&
                        new Date(attendval.check_in_tea);

                      var checkOutTea =
                        attendval.check_out_tea &&
                        new Date(attendval.check_out_tea);
                      var TeaTime = handleCalculateDifference(
                        checkInTea,
                        checkOutTea
                      );
                      var checkInwh =
                        attendval.check_in_wh &&
                        new Date(attendval.check_in_wh);

                      var checkOutwh =
                        attendval.check_out_wh &&
                        new Date(attendval.check_out_wh);
                      var whTime = handleCalculateDifference(
                        checkInwh,
                        checkOutwh
                      );
                      var date = new Date(attendval.date);
                      const formatDuration = (duration) => {
                        if (
                          duration.hours === 0 &&
                          duration.minutes === 0 &&
                          duration.seconds !== 0
                        ) {
                          return `${duration.seconds} Seconds`;
                        } else {
                          return `${
                            duration.hours ? `${duration.hours} Hour ` : ""
                          }${
                            duration.minutes
                              ? `${duration.minutes} Minutes `
                              : ""
                          }`;
                        }
                      };
                      if (
                        attendval.is_sunday === 0 &&
                        attendval.is_employee_leave === 0 &&
                        attendval.is_holiday_company ===0
                      ) {
                        return (
                          <tr key={attendkey}>
                            <td>{date.toDateString()}</td>
                            <td>
                              {checkIn.toLocaleTimeString("en-US", {
                                hour: "numeric",
                                hour12: true,
                                minute: "numeric",
                              })}
                            </td>
                            <td>
                              {" "}
                              {formatDuration(lunchTime) === ""
                                ? "--"
                                : formatDuration(lunchTime)}
                            </td>
                            <td>
                              {" "}
                              {formatDuration(TeaTime) === ""
                                ? "--"
                                : formatDuration(TeaTime)}
                            </td>
                            <td>
                              {formatDuration(whTime) === ""
                                ? "--"
                                : formatDuration(whTime)}
                            </td>
                            <td>
                              {attendval.check_out
                                ? checkOut.toLocaleTimeString("en-US", {
                                    hour: "numeric",
                                    hour12: true,
                                    minute: "numeric",
                                  })
                                : "--"}
                            </td>
                            <td>
                              {attendval.total_hours
                                ? attendval.total_hours
                                : "--"}
                            </td>
                            <td className="text-center">
                              <NavDropdown title="Action">
                                <NavDropdown.Item>
                                  <div
                                    className="action-btn"
                                    onClick={() => showMyTask(attendval.id)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      strokeWidth="2"
                                      stroke="#575A74"
                                      fill="none"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    >
                                      <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                      ></path>
                                      <path d="M5 3m0 2a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2z"></path>
                                      <path d="M9 7l6 0"></path>
                                      <path d="M9 11l6 0"></path>
                                      <path d="M9 15l4 0"></path>
                                    </svg>
                                    <div className="action-text">Task</div>
                                  </div>
                                </NavDropdown.Item>
                              </NavDropdown>
                            </td>
                          </tr>
                        );
                      } else if (attendval.is_employee_leave === 1) {
                        return (
                          <tr>
                            <td>{date.toDateString()}</td>
                            <td colspan="8" className="text-center text-danger">
                              <h5>-- Employee Leave --</h5>
                            </td>
                          </tr>
                        );
                      } else if (attendval.is_holiday_company === 1) {
                        return (
                          <tr>
                            <td>{date.toDateString()}</td>
                            <td colspan="8" className="text-center text-success">
                              <h5>-- Company Holiday --</h5>
                            </td>
                          </tr>
                        );
                      } else {
                        return (
                          <tr>
                            <td>{date.toDateString()}</td>
                            <td
                              colspan="8"
                              className="text-center text-success"
                            >
                              <h5>-- Sunday Holiday --</h5>
                            </td>
                          </tr>
                        );
                      }
                    })
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center">
                        No Records Found
                      </td>
                    </tr>
                  )
                ) : (
                  <tr>
                    <td
                      colSpan={6}
                      className="text-center theme-text-danager position-relative"
                    >
                      <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {totalPages > 1 ? (
            <Pagination
              currentPage={currentPages}
              totalPages={totalPages}
              onNext={handleNextPage}
              onPrev={handlePrevPage}
              handlePageChange={handlePageChanges}
            />
          ) : null}
        </div>
      </section>

      <Modal
        show={show}
        size="lg"
        onHide={() => setShow(false)}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            What work did you do today?
            {title === "check_in_lunch" ? "( Before Lunch )" : null}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form method="post">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group m-0">
                  <Select
                    isMulti
                    defaultValue={selectedOption}
                    onChange={setSelectedOption}
                    options={options}
                    name="project"
                    required
                  />
                  {errors && (
                    <p className="mt-2" style={{ color: "red" }}>
                      {errors}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group mb-0">
                  <h5 className="mb-2">
                    {filteredData[0]?.projects?.toString()}
                  </h5>
                  {filteredData[0]?.todomorning === null ||
                  filteredData[0]?.todomorning === undefined ||
                  filteredData[0]?.todomorning === "" ? null : (
                    <p className="mb-4">
                      <b>Before Lunch Task :</b>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: filteredData[0]?.todomorning.replace(
                            /\n/g,
                            "<br>"
                          ),
                        }}
                      ></p>
                    </p>
                  )}

                  <textarea
                    placeholder="Task Note..."
                    name="app_note"
                    rows={10}
                    onChange={(task) => setTaskNotes(task.target.value)}
                    value={filteredData[0]?.todo}
                  />
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="theme-btn-primary"
            onClick={() => checkOut()}
            disabled={IsPendingBtn ? true : false}
          >
            {IsPendingBtn
              ? "Loding..."
              : title === "check_in_lunch"
              ? "Check In For Lunch"
              : "Check Out"}
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showTask}
        size="lg"
        onHide={() => setShowTask(false)}
        aria-labelledby="example-custom-modal-styling-title"
        centered
        className="taskmodal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Task Notes
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(tasknotes.todo?.todomorning === null ||
            tasknotes.todo?.todomorning === undefined ||
            tasknotes.todo?.todomorning === "") &&
          (tasknotes.todo?.todo === null ||
            tasknotes.todo?.todo === undefined ||
            tasknotes.todo?.todo === "") ? (
            <h5 className="text-center mt-2 mb-2">No Task Found</h5>
          ) : (
            <div className="form-group m-0">
              <h5 className="mb-2">{filteredData[0]?.projects?.toString()}</h5>
              {tasknotes.todo?.todomorning === null ||
              tasknotes.todo?.todomorning === undefined ||
              tasknotes.todo?.todomorning === "" ? null : (
                <p className="mb-4">
                  <b>Before Lunch Task :</b>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: tasknotes.todo?.todomorning.replace(
                        /\n/g,
                        "<br>"
                      ),
                    }}
                  ></p>
                  {/* <p>{tasknotes.todo?.todomorning}</p> */}
                </p>
              )}
              {tasknotes.todo?.todo === null ||
              tasknotes.todo?.todo === undefined ||
              tasknotes.todo?.todo === "" ? null : (
                <p className="mb-4">
                  <b>After Lunch Task :</b>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: tasknotes.todo?.todo.replace(/\n/g, "<br>"),
                    }}
                  ></p>
                  {/* <p>{tasknotes.todo?.todo}</p> */}
                </p>
              )}
              {/* <textarea rows={15} value={tasknotes.todo} disabled /> */}
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Attendancess;
