import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { postApi } from "../helpers/api";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
function SignIn() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [IsPending, setPending] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const notifySuccess = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  const notify = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

  const [initialValues, setInitialValues] = useState({
    email: "",
    password: "",
  });

  const validationSchema = yup.object({
    email: yup
      .string()
      .required("Email is required.")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Please enter valid email address"
      ),
    password: yup
      .string()
      .min(8, "should be 8 chars minimum.")
      .required("Password is required."),
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: async (values) => {
        setPending(true);
        // await postApi("login", values).then(({ data, status, message }) => {
        await postApi("login", values).then((res) => {
          if (res.status === 200) {
            notifySuccess(res.message);
            localStorage.setItem(
              process.env.REACT_APP_SECRET,
              res.data.authentication
            );
            setPending(false);
            if (res.data.userRole.role_name === "admin") {
              navigate("/dashboard");
            } else {
              navigate(`/attendances`);
            }
          } else {
            notify(res.data.message);
            setPending(false);
          }
        });
      },
    });

  return (
    <>
      <ToastContainer />
      <div className="authentication">
        <div className="left-box">
          <div className="form-box">
            <div className="auth-head">
              <h3>Hi, Welcome Back!</h3>
              <p>Platform where user will able to keep their money.</p>
            </div>
            <form
              onSubmit={handleSubmit}
              autoComplete="Off"
              className="auth-form"
              method="POST"
            >
              <div className="form-group">
                <label>Email</label>
                <input
                  type="text"
                  className=""
                  placeholder="Enter Email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                {errors.email && touched.email ? (
                  <span className="required-lable">{errors.email} </span>
                ) : null}
              </div>
              <div className="form-group">
                <label>Password</label>
                <div className="password-input-container passwordWrap">
                  <input
                    type={showPassword ? "text" : "password"}
                    className=""
                    placeholder="Password"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                  <span
                    className="password-toggle-icon"
                    onClick={togglePasswordVisibility}
                  >
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                  </span>
                </div>
                {errors.password && touched.password ? (
                  <span className="required-lable">{errors.password} </span>
                ) : null}
              </div>
              {/* <p className="forget-link"><Link to="/reset-password">Forgot Password?</Link></p> */}
              <button
                type="submit"
                className="form-submit"
                disabled={IsPending ? true : false}
              >
                {IsPending ? "Loding..." : "Sign In"}
              </button>
              <p className="form-link">
                Don't have an account?&nbsp;<Link to="/sign-up">Sign Up</Link>
              </p>
            </form>
          </div>
        </div>
        <div className="right-box">
          <img src="images/auth-signin.svg" alt="" />
        </div>
      </div>
    </>
  );
}

export default SignIn;
