import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getApi } from "../../helpers/api";
import "./profile.css";
import Pagination from "../Pagination";
import {
  format, 
} from "date-fns";
const Salarys = () => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [salary, setSalary] = useState([]);

  const getMonthName = (monthNumber) => {
    const date = new Date();
    date.setMonth(monthNumber - 1); // months are 0-based in JavaScript
    return format(date, "MMMM");
  };
  /*************Pagination */
  const [totalPages, setTotalPages] = useState(1);
  const [currentPages, setCurrentPages] = useState(1);
  const [count, setCount] = useState(0);

  const handleNextPage = () => {
    if (currentPages < totalPages) {
      setCurrentPages(currentPages + 1);
    }
  };

  const handlePrevPage = async () => {
    if (currentPages > 1) {
      setCurrentPages(currentPages - 1);
    }
  };
  const handlePageChanges = (pageNumber) => {
    setCurrentPages(pageNumber);
  };
  /***************** */
  useEffect(() => {
    getAllAttendance(currentPage);
  }, [currentPage, currentPages]);

  async function getAllAttendance(page) { 
    let salaryurl =
      sDate && eDate
        ? `salarys?sDate=${sDate}&eDate=${eDate}&page=${currentPage}`
        : `salarys?page=${currentPage}`;
    await getApi(salaryurl, true).then((res) => {
      if (res.status === 200) {
        setSalary(res.data.data);
        setTotalPages(res.data.totalPages);
        setCount(res.data.count);
      }
    });
  }

  let sDate, eDate;
  const SerachDate = async () => {
    sDate = startDate;
    eDate = endDate;
    getAllAttendance(currentPage);
  };
  return (
    <>
      <ToastContainer />
      <section className="content user-profile">      
            <div className="profile-tab">
              <div className="attendance-head">
                <div className="search-date mt-3 mt-xl-0">
                  <DatePicker
                    showIcon
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText="From Date"
                    dateFormat="dd MMMM yyyy"
                  />
                  &nbsp;&nbsp;&nbsp;
                  <DatePicker
                    showIcon
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    placeholderText="To Date"
                    dateFormat="dd MMMM yyyy"
                  />
                  &nbsp;&nbsp;&nbsp;
                  <button
                    className="theme-btn-primary py-0"
                    onClick={SerachDate}
                    disabled={
                      startDate === undefined || endDate === undefined
                        ? true
                        : false
                    }
                  >
                    Go
                  </button>
                </div>
              </div>
              <div>
                <table className="table-list">
                  <thead>
                    <tr>
                      {/* <td>id</td> */}
                      <td>Name</td>
                      <td>Date</td>
                      <td>Month</td>
                      <td>Year</td>
                      <td>Salary</td>
                      <td>Status</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    {salary?.length ? (
                      salary?.map((val, key) => {
                        return (
                          <tr key={key}>
                            {/* <td>{val.id}</td> */}
                            <td>{val?.usersalary?.fullname}</td>
                            <td>{format(new Date(val.date), "dd MMM yyyy")}</td>
                            <td>{getMonthName(val.month)}</td>
                            <td>{val.year}</td>
                            <td>{val.salary}</td>
                            <td>
                              <button
                                className={
                                  "theme-btn-primary btnwdth " +
                                  (val.status === 0
                                    ? "btn-success"
                                    : val.status === 2
                                    ? "btn-danger"
                                    : val.status === 3
                                    ? "btn-dark"
                                    : "")
                                }
                              >
                                {val.status === 0
                                  ? "Received"
                                  : val.status === 0
                                  ? "Received"
                                  : ""}
                              </button>
                            </td>
                            <td>Download</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td
                          colSpan={7}
                          className="text-center theme-text-danager"
                        >
                          Not Found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {totalPages > 1 ? (
                  <Pagination
                    currentPage={currentPages}
                    totalPages={totalPages}
                    onNext={handleNextPage}
                    onPrev={handlePrevPage}
                    handlePageChange={handlePageChanges}
                  />
                ) : null}
              </div>
            </div>         
      </section>
    </>
  );
};

export default Salarys;
