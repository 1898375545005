import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { tags as t } from "@lezer/highlight";
import CodeMirror from "@uiw/react-codemirror";
import { json } from "@codemirror/lang-json";
import { bbeditInit } from "@uiw/codemirror-theme-bbedit";
import { getApi } from "../../helpers/api";

function ViewSource() {
  const search = useLocation().search;
  const params = new URLSearchParams(search);
  const packagename = params.get("packagename");
  const [value, setValue] = useState("");
  useEffect(() => {
    if (packagename) {
      getViewSource();
    }
  }, []);

  const getViewSource = async () => {
    await getApi(`app-response?packagename=${packagename}`, true)
      .then((response) => {
        let newAppSource = { ...response?.data };
        setValue(JSON.stringify(newAppSource, null, 6));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      {
        <CodeMirror
          value={value}
          height="100vh"
          theme={bbeditInit({
            styles: [
              { tag: t.comment, color: "#fff" },
              { tag: t.bool, color: "green" },
              { tag: t.string, color: "#575A74" },
              { tag: t.name, color: "#010226" },
            ],
          })}
          readOnly={true}
          extensions={[json()]}
        />
      }
    </>
  );
}

export default ViewSource;
