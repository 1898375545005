import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./settings.css";
import "./setting.css";
import CustomCalendar from "./DateReason";
function Holiday() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  useEffect(() => {
    if (user.userRole.role_name === "admin") {
    } else {
      dispatch({ type: "LOGOUT", payload: null });
      localStorage.removeItem(process.env.REACT_APP_SECRET);
      navigate("/");
    }
  }, []);

  return (
    <>
      <div className="row">
        <div className="">
          <div className="settings">
            <form autoComplete="off">
              <div className="row">
                <div className="col-md-12">
                  <h3 className="text-center mb-24">Holiday</h3>                  
                </div>               
                <div className="col-md-12">
                  <CustomCalendar />
                </div>                
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Holiday;
