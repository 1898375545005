import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { getApi } from "../../helpers/api";
import { format, parseISO } from "date-fns";
export default function Holidays() {
  const [value, setValue] = useState([]);
  const [holidays, setHolidays] = useState({});
  const getLeave = async () => {
    try {
      const { data } = await getApi(`get-multi-leave`, true);
      setValue(data);
      const result = data.reduce((acc, { start_date, reason }) => {
        acc[start_date] = reason;
        return acc;
      }, {});

      setHolidays(result);
    } catch (error) {
      console.error(error);
    }
  };
  const formatDate = (dateString) => {
    const date = parseISO(dateString);
    return format(date, "d MMMM yyyy");
  };
  useEffect(() => {
    getLeave();
  }, []);
  return (
    <div>
      {value?.length > 0 ? (
        <div className="leave-list col-5">
          <h5 className="mb-3">Holidays List</h5>
          <div className="d-flex">
            <Table>
              <thead>
                <tr>
                  <th className="th-date">Date</th>
                  <th className="th-festival">Festival</th>
                </tr>
              </thead>
              <tbody>
                {value?.map((item, index) => (
                  <tr key={index}>
                    <td>{formatDate(item.start_date)}</td>
                    <td>{item.reason}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      ) : null}
    </div>
  );
}
