import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useFormik } from "formik";
import { getApi, postApi } from "../../helpers/api";

function MyTestForm() {
  let navigate = useNavigate();
  let params = useParams();
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const [IsPending, setPending] = useState(false);

  useEffect(() => {
    if (user.userRole.role_name === "admin" && params.id) {
      getTestData();
    } else {
      dispatch({ type: "LOGOUT", payload: null });
      localStorage.removeItem(process.env.REACT_APP_SECRET);
      navigate("/");
    }
  }, []);

  const getTestData = async () => {
    await getApi(`get-test?test=${params.id}`, true)
      .then(({ data }) => {
        if (data) {
          setInitialValues(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const notify = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

  const [initialValues, setInitialValues] = useState({
    AD_MOB_APP_ID_Banner: "",
    AD_MOB_APP_ID_Native: "",
    AD_MOB_APP_ID_Inter: "",
    AD_MOB_APP_ID_OpenAd: "",
    AD_MOB_APP_ID_RewardedVideo: "",
    AD_MOB_APP_ID_RewardedInterstitial: "",
    FB_Banner_ID: "",
    FB_Native_ID: "",
    FB_Inter_ID: "",
    FB_BannerNative_ID: "",
    app_ApplovinInterstitialId: "",
    app_ApplovinNative: "",
    app_ApplovinBanner: "",
    app_UnityBannerId: "",
    app_UnityInterstitialId: "",
  });

  const { values, errors, touched, handleChange, handleSubmit, handleBlur } =
    useFormik({
      initialValues,
      enableReinitialize: true,
      onSubmit: async (values) => {
        setPending(true);
        let postURL = params.id ? `add-test?test=${params.id}` : `/add-test`;
        await postApi(postURL, values)
          .then((response) => {
            navigate("/add-test-data/1");
            setPending(false);
            notify("Test data should be updated.");
          })
          .catch((error) => {
            console.log(error);
          });
      },
    });

  return (
    <>
      <ToastContainer />
      <section className="content">
        <form onSubmit={handleSubmit} autoComplete="off">
          <div className="row">
            <div className="col-md-12 col-12">
              <Tabs
                defaultActiveKey="admob"
                transition={false}
                id="noanim-tab-example"
              >
                <Tab eventKey="admob" title="Admob Ads">
                  <div className="tab-social-content mt-24">
                    <div className="row">
                      <div className="col-md-6 col-12">
                        <div className="form-group mt-0">
                          <label>Interstitial</label>
                          <input
                            type="text"
                            className=""
                            placeholder="Enter Interstitial ID"
                            name="AD_MOB_APP_ID_Inter"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.AD_MOB_APP_ID_Inter}
                          />
                          {errors.AD_MOB_APP_ID_Inter &&
                          touched.AD_MOB_APP_ID_Inter ? (
                            <span className="required-lable">
                              {errors.AD_MOB_APP_ID_Inter}{" "}
                            </span>
                          ) : null}
                        </div>
                        <div className="form-group">
                          <label>Rewarded Video</label>
                          <input
                            type="text"
                            placeholder="Rewarded Video"
                            name="AD_MOB_APP_ID_RewardedVideo"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.AD_MOB_APP_ID_RewardedVideo}
                          />
                          {errors.AD_MOB_APP_ID_RewardedVideo &&
                          touched.AD_MOB_APP_ID_RewardedVideo ? (
                            <span className="required-lable">
                              {errors.AD_MOB_APP_ID_RewardedVideo}{" "}
                            </span>
                          ) : null}
                        </div>
                        <div className="form-group">
                          <label>AppOpen</label>
                          <input
                            type="text"
                            placeholder="Enter AppOpen ID"
                            name="AD_MOB_APP_ID_OpenAd"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.AD_MOB_APP_ID_OpenAd}
                          />
                          {errors.AD_MOB_APP_ID_OpenAd &&
                          touched.AD_MOB_APP_ID_OpenAd ? (
                            <span className="required-lable">
                              {errors.AD_MOB_APP_ID_OpenAd}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="form-group mt-0">
                          <label>Banner</label>
                          <input
                            type="text"
                            placeholder="Enter Banner ID"
                            name="AD_MOB_APP_ID_Banner"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.AD_MOB_APP_ID_Banner}
                          />
                          {errors.AD_MOB_APP_ID_Banner &&
                          touched.AD_MOB_APP_ID_Banner ? (
                            <span className="required-lable">
                              {errors.AD_MOB_APP_ID_Banner}{" "}
                            </span>
                          ) : null}
                        </div>
                        <div className="form-group">
                          <label>Native</label>
                          <input
                            type="text"
                            placeholder="Enter Native ID"
                            name="AD_MOB_APP_ID_Native"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.AD_MOB_APP_ID_Native}
                          />
                          {errors.AD_MOB_APP_ID_Native &&
                          touched.AD_MOB_APP_ID_Native ? (
                            <span className="required-lable">
                              {errors.AD_MOB_APP_ID_Native}{" "}
                            </span>
                          ) : null}
                        </div>
                        <div className="form-group">
                          <label>Rewarded Interstitial</label>
                          <input
                            type="text"
                            placeholder="Enter Link"
                            name="AD_MOB_APP_ID_RewardedInterstitial"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.AD_MOB_APP_ID_RewardedInterstitial}
                          />
                          {errors.AD_MOB_APP_ID_RewardedInterstitial &&
                          touched.AD_MOB_APP_ID_RewardedInterstitial ? (
                            <span className="required-lable">
                              {errors.AD_MOB_APP_ID_RewardedInterstitial}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="facebook" title="Facebook Ads">
                  <div className="tab-social-content mt-24">
                    <div className="row">
                      <div className="col-md-6 col-12">
                        <div className="form-group mt-0">
                          <label>Banner</label>
                          <input
                            type="text"
                            placeholder="Enter Banner ID"
                            name="FB_Banner_ID"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.FB_Banner_ID}
                          />
                          {errors.FB_Banner_ID && touched.FB_Banner_ID ? (
                            <span className="required-lable">
                              {errors.FB_Banner_ID}{" "}
                            </span>
                          ) : null}
                        </div>
                        <div className="form-group">
                          <label>Native</label>
                          <input
                            type="text"
                            placeholder="Enter Native ID"
                            name="FB_Native_ID"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.FB_Native_ID}
                          />
                          {errors.FB_Native_ID && touched.FB_Native_ID ? (
                            <span className="required-lable">
                              {errors.FB_Native_ID}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="form-group mt-0">
                          <label>Interstitial</label>
                          <input
                            type="text"
                            placeholder="Entre Interstitial ID"
                            name="FB_Inter_ID"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.FB_Inter_ID}
                          />
                          {errors.FB_Inter_ID && touched.FB_Inter_ID ? (
                            <span className="required-lable">
                              {errors.FB_Inter_ID}{" "}
                            </span>
                          ) : null}
                        </div>
                        <div className="form-group">
                          <label>Banner Native</label>
                          <input
                            type="text"
                            placeholder="Entre Banner Native ID"
                            name="FB_BannerNative_ID"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.FB_BannerNative_ID}
                          />
                          {errors.FB_BannerNative_ID &&
                          touched.FB_BannerNative_ID ? (
                            <span className="required-lable">
                              {errors.FB_BannerNative_ID}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="applovin" title="Applovin Ads">
                  <div className="tab-social-content mt-24">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group mt-0">
                          <label>Banner</label>
                          <input
                            type="text"
                            placeholder="Enter Banner ID"
                            name="app_ApplovinBanner"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.app_ApplovinBanner}
                          />
                          {errors.app_ApplovinBanner &&
                          touched.app_ApplovinBanner ? (
                            <span className="required-lable">
                              {errors.app_ApplovinBanner}{" "}
                            </span>
                          ) : null}
                        </div>
                        <div className="form-group">
                          <label>Native</label>
                          <input
                            type="text"
                            placeholder="Enter Native ID"
                            name="app_ApplovinNative"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.app_ApplovinNative}
                          />
                          {errors.app_ApplovinNative &&
                          touched.app_ApplovinNative ? (
                            <span className="required-lable">
                              {errors.app_ApplovinNative}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="form-group mt-0">
                          <label>Interstitial</label>
                          <input
                            type="text"
                            placeholder="Entre Interstitial ID"
                            name="app_ApplovinInterstitialId"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.app_ApplovinInterstitialId}
                          />
                          {errors.app_ApplovinInterstitialId &&
                          touched.app_ApplovinInterstitialId ? (
                            <span className="required-lable">
                              {errors.app_ApplovinInterstitialId}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="unity" title="Unity Ads">
                  <div className="tab-social-content mt-24">
                    <div className="row">
                      <div className="col-md-6 col-12">
                        <div className="form-group mt-0">
                          <label>Banner</label>
                          <input
                            type="text"
                            placeholder="Enter Banner ID"
                            name="app_UnityBannerId"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.app_UnityBannerId}
                          />
                          {errors.app_UnityBannerId &&
                          touched.app_UnityBannerId ? (
                            <span className="required-lable">
                              {errors.app_UnityBannerId}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="form-group mt-0">
                          <label>Interstitial</label>
                          <input
                            type="text"
                            placeholder="Entre Interstitial ID"
                            name="app_UnityInterstitialId"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.app_UnityInterstitialId}
                          />
                          {errors.app_UnityInterstitialId &&
                          touched.app_UnityInterstitialId ? (
                            <span className="required-lable">
                              {errors.app_UnityInterstitialId}{" "}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
          <div className="add-submit mt-0">
            <button
              type="submit"
              className="theme-btn-primary"
              disabled={IsPending ? true : false}
            >
              {IsPending ? "Loding..." : "Submit"}
            </button>
          </div>
        </form>
      </section>
    </>
  );
}

export default MyTestForm;
