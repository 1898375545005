import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import { useLocation } from "react-router-dom";
import { getApi } from "../../helpers/api";
import Pagination from "../Pagination";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import dot from "../../images/menu.svg";
export default function LeaveEmployee() {
  const [leaveData, setLeaveData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const search = useLocation().search;
  const params = new URLSearchParams(search);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [showLeaveView, setShowLeaveView] = useState(false);
  const [reasonView, setReasonView] = useState("");
  const [leaveTypeView, setLeaveTypeView] = useState("");
  const [type, setType] = useState("");
  const [count, setCount] = useState(0);
  const fetchData = async () => {
    let url =
      startDate && endDate
        ? `employee-leave-get?employee=${params.get(
            "employee"
          )}&sDate=${startDate}&eDate=${endDate}&page=${currentPage}`
        : `employee-leave-get?employee=${params.get(
            "employee"
          )}&page=${currentPage}`;
    if (type !== null && type !== undefined) url += `&type=${type}`;
    await getApi(url, true).then((res) => {
      if (res.status === 200) {
        setLeaveData(res.data.data);
        setTotalPages(res.data.totalPages);
        setCount(res.data.count);
      }
    });
  };
  useEffect(() => {
    fetchData();
  }, [currentPage, type]);
  const SerachDate = async () => {
    fetchData(currentPage);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = async () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <div>
      <div className="content-header-right">
        <div className="search-date">
          <DatePicker
            showIcon
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            placeholderText="From Date"
            dateFormat="dd MMMM yyyy"
          />
          &nbsp;&nbsp;&nbsp;
          <DatePicker
            showIcon
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            placeholderText="To Date"
            dateFormat="dd MMMM yyyy"
          />
          &nbsp;&nbsp;&nbsp;
          <button
            className="theme-btn-primary py-0"
            onClick={SerachDate}
            disabled={
              startDate === undefined || endDate === undefined ? true : false
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M4 4h16v2.172a2 2 0 0 1 -.586 1.414l-4.414 4.414v7l-6 2v-8.5l-4.48 -4.928a2 2 0 0 1 -.52 -1.345v-2.227z"></path>
            </svg>
          </button>
        </div>
      </div>
      <table className="table-list">
        <thead>
          <tr>
            <th className="w-250">Name</th>
            <th className="w-150">From</th>
            <th className="w-150">To</th>
            <th className="w-150">Duration</th>
            <th className="w-150">Leave Type</th>
            <th className="">Reason</th>
            <th className="w-150">Status</th>
            <th style={{ width: 20 }}></th>
          </tr>
        </thead>
        <tbody>
          {leaveData?.length ? (
            leaveData?.map((val, key) => {
              return (
                <tr key={key}>
                  {/* <td>{val.id}</td> */}
                  <td>{val?.userleave?.fullname}</td>
                  <td>{format(new Date(val.start_date), "dd MMM yyyy")}</td>
                  <td>{format(new Date(val.end_date), "dd MMM yyyy")}</td>
                  <td>
                    {val.duration === "Half Day"
                      ? "Half Leave"
                      : val.duration <= 1
                      ? `${val.duration} Day`
                      : val.duration.includes(":")
                      ? val.duration // If duration includes ":" assume it's formatted correctly
                      : `${Math.round(val.duration)} Days`}
                  </td>
                  <td>{val?.leave_type?.substr(0, 60)}</td>
                  <td>{val?.reason?.substr(0, 100)}</td>
                  <td>
                    <button
                      className={
                        "theme-btn-primary btnwdth " +
                        (val.status === 1
                          ? "btn-success"
                          : val.status === 2
                          ? "btn-danger"
                          : val.status === 3
                          ? "btn-dark"
                          : "btn-warning")
                      }
                    >
                      {val.status === 0
                        ? "Pending..."
                        : val.status === 1
                        ? "Approved"
                        : val.status === 2
                        ? "Rejected"
                        : val.status === 3
                        ? "Cancel"
                        : ""}
                    </button>
                  </td>
                  <td className="text-end">
                    <Dropdown>
                      <Dropdown.Toggle className="dotbtn">
                        <img src={dot} alt="" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            setShowLeaveView(true);
                            setReasonView(val?.reason);
                            setLeaveTypeView(val?.leave_type);
                          }}
                        >
                          View Reason
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>                   
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={7} className="text-center theme-text-danager">
                Not Found.
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {totalPages > 1 ? (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onNext={handleNextPage}
          onPrev={handlePrevPage}
          handlePageChange={handlePageChange}
        />
      ) : null}
      <Modal
        show={showLeaveView}
        size="lg"
        onHide={() => setShowLeaveView(false)}
        aria-labelledby="leave-added-model"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="leave-added-model">View Leave</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group mt-0">
                  <h6 className="mb-2">Leave Type</h6>
                  <input
                    type="text"
                    value={leaveTypeView}
                    rows={7}
                    className="border-0 p-0"
                    disabled
                  />
                </div>
                <div className="form-group mt-0">
                  <h6 className="mb-2">Reason</h6>
                  <textarea
                    value={reasonView}
                    rows={7}
                    className="border-0 p-0"
                    disabled
                  />
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
